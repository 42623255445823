import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import {
  ArPackage,
  Content,
  ContentType,
  ContentWrapper,
  createDefaultContent,
  extractContentWrappersFromMessage,
  Scenario,
  setContentWrappersToMessage,
} from '../common/models/ArPackage';
import PageBody from '../common/PageBody';
import {
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
} from './arPackageSlice';
import AnimationStateComponent from './Contents/AnimationStateComponent';
import ChoiceComponent from './Contents/ChoiceComponent';
import ImageOnHeadComponent from './Contents/ImageOnHeadComponent';
import InputComponent from './Contents/InputComponent';
import JumpToMessageComponent from './Contents/JumpToMessageComponent';
import NoteComponent from './Contents/NoteComponent';
import UnknownComponent from './Contents/UnknownComponent';
import {
  FullScreenVideoComponent,
  VideoOnBodyComponent,
} from './Contents/VideoComponent';
import VoiceComponent from './Contents/VoiceComponent';

type PropTypes = {
  createContent: boolean;
};

// eslint-disable-next-line no-unused-vars
type ParamTypes = {
  itemId: string;
  packageId: string;
  scenarioId: string;
  messageId: string;
  orderId: string;
};

/** 選択肢に表示しないコンテンツ */
const unavailableContentTypes: ContentType[] = [
  ContentType.ImageOnHead,
  ContentType.VideoOnBody,
  ContentType.Note,
  ContentType.AnimationState,
];

export default function ContentEditPage({ createContent }: PropTypes) {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // parse path params
  const params = useParams<ParamTypes>();
  const itemId = Number.parseInt(params.itemId, 10);
  const packageId = Number.parseInt(params.packageId, 10);
  const scenarioId = Number.parseInt(params.scenarioId, 10);
  const messageId = Number.parseInt(params.messageId, 10);
  const orderId = Number.parseInt(params.orderId, 10);

  // state
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.Voice
  );
  const [content, setContent] = useState<ContentWrapper>();

  // refresh view
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(packageId));
  }, []);

  // store
  const arPackage = useSelector(selectArPackage);
  const scenarios = arPackage?.scenarios || [];
  const scenario = scenarios.find(
    (sc) => sc.scenario_id === scenarioId.toString()
  );
  const messages = scenario?.messages || [];
  const message = messages.find((m) => m.message_id === messageId.toString());
  const contents = message ? extractContentWrappersFromMessage(message) : [];

  // effects
  if (createContent) {
    useEffect(() => {
      setContent(createDefaultContent(contentType));
    }, [arPackage, contentType]);
  } else {
    useEffect(() => {
      if (!arPackage) return;

      const current = contents[orderId];
      setContentType(current.typ);
      setContent(current);
    }, [arPackage]);
  }

  // callbacks
  const onContentTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setContentType(e.target.value as ContentType);
    setContent(createDefaultContent(e.target.value as ContentType));
  };

  const onSubmitContent = async (c: Content) => {
    if (!arPackage || !scenario || !message || !contentType) {
      return;
    }

    const cw: ContentWrapper = {
      typ: contentType,
      content: c,
    };

    const newContents = [...contents];
    if (createContent) {
      newContents.push(cw);
    } else {
      newContents[orderId] = cw;
    }

    const newMessage = setContentWrappersToMessage(message, newContents);

    const newMessages = messages.map((m) =>
      m.message_id === newMessage.message_id ? newMessage : m
    );
    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };
    const newScenarios = scenarios.map((sc) =>
      sc.scenario_id === newScenario.scenario_id ? newScenario : sc
    );
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(
      updateArPackage(packageId, newArPackage, () => {
        history.push(
          `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${messageId}`
        );
      })
    );
  };

  const onCancel = () => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${messageId}`
    );
  };

  // edit components
  const EditComponent = useMemo(() => {
    switch (contentType) {
      case ContentType.Voice:
        return VoiceComponent;
      case ContentType.ImageOnHead:
        return ImageOnHeadComponent;
      case ContentType.Choice:
        return ChoiceComponent;
      case ContentType.JumpToMessage:
        return JumpToMessageComponent;
      case ContentType.VideoOnBody:
        return VideoOnBodyComponent;
      case ContentType.FullScreenVideo:
        return FullScreenVideoComponent;
      case ContentType.Note:
        return NoteComponent;
      case ContentType.Input:
        return InputComponent;
      case ContentType.AnimationState:
        return AnimationStateComponent;
      default:
        return UnknownComponent;
    }
  }, [contentType]);

  // TODO: (miyashita) 読み込み
  if (!contentType || !content) {
    return <div>読み込み中</div>;
  }

  return (
    <Page>
      <PageTitle value={createContent ? 'コンテンツ追加' : 'コンテンツ編集'} />
      <PageBody loading={!arPackage}>
        <Row className="mb-4">
          <Form>
            <Form.Group>
              <Row>
                <Form.Label column md={3} lg={2}>
                  コンテンツ種別
                </Form.Label>
                <Col md={5}>
                  <Form.Select
                    onChange={onContentTypeChanged}
                    value={contentType}
                  >
                    {Object.entries(ContentType)
                      .filter(
                        (e) =>
                          e[1] === contentType ||
                          !unavailableContentTypes.includes(e[1])
                      )
                      .map((e) => (
                        <option key={e[0]}>{e[1]}</option>
                      ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Row>

        <Row className="mb-4">
          <h3 className="mb-2">{contentType}</h3>
          <EditComponent
            packageId={packageId}
            scenarioId={scenarioId}
            messageId={messageId}
            content={content.content}
            onSubmitContent={onSubmitContent}
            onCancel={onCancel}
          />
        </Row>
      </PageBody>
    </Page>
  );
}
