import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import validationErrors from '../../util/validationErrors';
import PageBody from '../common/PageBody';
import {
  addPackageFile,
  clearPackage,
  createDataPackage,
  deleteDataPackage,
  deletePackageFile,
  PackageFile,
  refreshPackage,
  selectPackage,
  selectPackageFiles,
  selectTitle,
  updateDataPackage,
  updateTitle,
} from './dataPackageSlice';
import { formatJSONDate } from '../../util';
import { setCommonError } from '../common/commonSlice';
import DeleteModal from '../../components/DeleteModal';

const allowedMime = ['image/jpeg', 'image/png', 'video/mp4', 'video/quicktime'];
interface ParamTypes {
  itemId: string;
  packageId?: string;
}

export default function DataPackageEditPage(props: { newPackage: boolean }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { newPackage } = props;
  const { itemId, packageId } = useParams<ParamTypes>();

  const pkg = useSelector(selectPackage);
  const title = useSelector(selectTitle);
  const packageFiles = useSelector(selectPackageFiles);

  useEffect(() => {
    if (newPackage) {
      dispatch(clearPackage());
    } else if (packageId) {
      dispatch(refreshPackage({ packageId: Number.parseInt(packageId, 10) }));
    } else {
      throw new Error('unreachable code');
    }
  }, []);

  const onSubmit = async (data: any) => {
    dispatch(updateTitle(data.title));

    if (newPackage) {
      await dispatch(
        createDataPackage({
          itemId: Number.parseInt(itemId, 10),
        })
      );
    } else if (packageId) {
      await dispatch(updateDataPackage());
    } else {
      throw new Error('unreachable path');
    }
    history.push(`/items/${itemId}`);
  };

  const onCancelClicked = () => {
    history.push(`/items/${itemId}`);
  };

  // ファイル操作系
  const fileSelectRef = React.createRef<HTMLInputElement>();
  const onSubmitFile = () => {
    if (!fileSelectRef.current || !fileSelectRef.current.files) {
      return;
    }

    const { files } = fileSelectRef.current;

    for (let i = 0; i < files.length; i += 1) {
      const file = files.item(i);

      if (!file) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (!allowedMime.includes(file.type)) {
        dispatch(setCommonError('許可されていないファイル種別です'));
        fileSelectRef.current.value = '';
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const packageFile of packageFiles) {
        if (!packageFile.toDelete && packageFile.name === file.name) {
          dispatch(setCommonError('同じファイル名のファイルが存在します'));
          fileSelectRef.current.value = '';
          return;
        }
      }

      const packageFile: PackageFile = {
        uploadingInfo: {
          file,
        },
        name: file.name,
        toDelete: false,
      };

      dispatch(addPackageFile(packageFile));
    }

    fileSelectRef.current.value = '';
  };

  const onViewFileClicked = (packageFile: PackageFile) => {
    if (packageFile.uploadingInfo) {
      const url = window.URL.createObjectURL(packageFile.uploadingInfo.file);
      const w = window.open(url);
      if (w) {
        w.onclose = () => {
          URL.revokeObjectURL(url);
        };
      }
    } else {
      const url = `${process.env.REACT_APP_API_BASEURL}/v1/console-file/packages/${packageId}/files/${packageFile.uploadedInfo?.key}`;
      window.open(url);
    }
  };

  const onDeleteFileClicked = (file: PackageFile) => {
    dispatch(deletePackageFile(file));
  };

  let i = 0;
  const filesTable = packageFiles.map((packageFile) => {
    if (packageFile.toDelete) {
      return null;
    }

    i += 1;

    return (
      <tr key={i.toString()}>
        <td>{i}</td>
        <td>{packageFile.name}</td>
        <td>
          {packageFile.uploadedInfo
            ? formatJSONDate(packageFile.uploadedInfo.updatedAt)
            : '未アップロード'}
        </td>
        <td>
          {' '}
          <Button
            variant="primary"
            size="sm"
            type="button"
            className="me-2"
            onClick={() => {
              onViewFileClicked(packageFile);
            }}
          >
            参照
          </Button>{' '}
          <Button
            variant="danger"
            size="sm"
            type="button"
            onClick={() => {
              onDeleteFileClicked(packageFile);
            }}
          >
            削除
          </Button>
        </td>
      </tr>
    );
  });

  // 削除
  const [deleteModalShowing, setDeleteModalShowing] = useState<boolean>(false);

  const onDeleteClicked = () => {
    setDeleteModalShowing(true);
  };

  const onDeleteConfirmed = async () => {
    setDeleteModalShowing(false);
    if (pkg) {
      await dispatch(deleteDataPackage(pkg.id));
      history.push(`/items/${itemId}`);
    }
  };

  const onDeleteCancelled = () => {
    setDeleteModalShowing(false);
  };

  return (
    <Page>
      <DeleteModal
        objectName="データパッケージ"
        show={deleteModalShowing}
        onCloseClicked={onDeleteCancelled}
        onDeleteClicked={onDeleteConfirmed}
      />

      <PageTitle
        value={newPackage ? 'データパッケージ追加' : 'データパッケージ編集'}
      />
      <PageBody loading={!newPackage && !pkg}>
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .required(validationErrors.required())
              .max(255, validationErrors.max(255)),
          })}
          onSubmit={onSubmit}
          initialValues={{
            title,
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Row>
                  <Form.Label column md={3} lg={2}>
                    パッケージ名
                  </Form.Label>
                  <Col md={5}>
                    <Form.Control
                      type="text"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      isValid={touched.title && !errors.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-5">
                <Row>
                  <Col md={3} lg={2}>
                    パッケージ種別
                  </Col>
                  <Col>データ</Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row className="mb-2">
                  <h3>添付ファイル</h3>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <Form.Control type="file" name="file" ref={fileSelectRef} />
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      type="button"
                      className="me-2"
                      onClick={onSubmitFile}
                    >
                      アップロード
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Form.Text muted>
                    アップロードできるファイルの拡張子は .jpg, .jpeg, .png,
                    .mp4, .mov のいずれかです。
                    <br />
                    10MBまでのファイルを同時に10ファイルまでアップロード可能です。
                  </Form.Text>
                </Row>
                <Row>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={{ width: '30px' }}>#</th>
                        <th>ファイル名</th>
                        <th>追加日</th>
                        <th style={{ width: '150px' }}>操作</th>
                      </tr>
                    </thead>
                    <tbody>{filesTable}</tbody>
                  </Table>
                </Row>
              </Form.Group>
              {!newPackage && (
                <Row className="mb-3">
                  <Row className="mb-2">
                    <h3>削除</h3>
                  </Row>
                  <Row className="ms-3">
                    <div>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={onDeleteClicked}
                      >
                        パッケージを削除する
                      </Button>
                    </div>
                  </Row>
                </Row>
              )}
              <Button variant="primary" type="submit" className="me-2">
                {newPackage ? '追加' : '編集'}
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={onCancelClicked}
              >
                キャンセル
              </Button>
            </Form>
          )}
        </Formik>
      </PageBody>
    </Page>
  );
}
