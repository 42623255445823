/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Table } from 'react-bootstrap';
import { BsList } from 'react-icons/bs';

function Td(props: {
  isDragging: boolean;
  children: React.ReactNode;
  style: React.CSSProperties;
}) {
  const { isDragging, children, style } = props;

  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (isDragging && width === null) {
      if (ref.current && ref.current.getBoundingClientRect().width > 0) {
        setWidth(ref.current.getBoundingClientRect().width);
      }
    }

    if (!isDragging) {
      setWidth(null);
    }
  }, [isDragging]);

  return (
    <td ref={ref} style={width ? { ...style, width } : style}>
      {children}
    </td>
  );
}

type PropTypes = {
  id: string;
  titles: { [key: string]: string };
  values: { id: string; [key: string]: React.ReactNode | string }[];
  styles?: { [key: string]: React.CSSProperties };
  onChangeOrder: (_src: number, _dest: number) => any;
};

export default function SortableTable({
  id,
  titles,
  values,
  styles,
  onChangeOrder,
}: PropTypes) {
  const keys = Object.keys(titles);

  const [dragging, setDragging] = useState(false);

  const onBeforeDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = (res: DropResult) => {
    setDragging(false);
    if (res.destination) {
      onChangeOrder(res.source.index, res.destination.index);
    }
  };

  return (
    <div>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
      >
        <Table responsive>
          <thead>
            <tr>
              <th> </th>
              {keys.map((key) => (
                <th key={key}>{titles[key]}</th>
              ))}
            </tr>
          </thead>
          <Droppable droppableId={id}>
            {(provided, _snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {values.map((row, i) => (
                  <Draggable key={row.id} draggableId={row.id} index={i}>
                    {(innerProvided) => (
                      <tr
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                      >
                        <td
                          {...innerProvided.dragHandleProps}
                          style={{ width: '20px' }}
                        >
                          <BsList />
                        </td>
                        {keys.map((key) => (
                          <Td
                            key={key}
                            isDragging={dragging}
                            style={styles ? styles[key] : {}}
                          >
                            {row[key]}
                          </Td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </div>
  );
}

SortableTable.defaultProps = {
  styles: {},
};
