/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { store } from '../app/store';
import { logout } from '../features/common/commonSlice';

export const initializeAxios = () => {
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response && err.response.status === 401) {
        await store.dispatch(logout(true));
      }
      throw err;
    }
  );
};
