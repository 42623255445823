export default {
  required: () => '入力が必須です',
  number: () => '数値を入力してください',
  max: (length: number) => `${length}以内で入力してください`,
  email: () => '正しいEメールアドレスの形式で入力してください',
  id: () =>
    '４～２０文字の英数字、ハイフン、アンダースコアのいずれかを使用した値を入力してください',
  password: () =>
    '８～２０文字の英字大文字・小文字・数字を一種類ずつ組み合わせた値を入力してください',
};
