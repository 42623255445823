import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { setCommonError, withLoading } from '../common/commonSlice';
import { Item, ItemCode, ItemNotification, ItemStats, Package } from '../common/models';

export const ITEMS_PER_PAGE_COUNT = 8;

export interface ItemsState {
  items: Item[];
  itemsTotalCount: number;
  itemsCurrentPage: number;
  selectedItem?: Item;
  selectedItemStats?: ItemStats;
  selectedItemCode?: ItemCode;
  selectedItemNotifications: ItemNotification[];
  packages: Package[];
}

const initialState: ItemsState = {
  items: [],
  itemsTotalCount: 0,
  itemsCurrentPage: 1,
  selectedItemNotifications: [],
  packages: [],
};

export const selectSelectedItem = (state: RootState) => state.items.selectedItem;
export const selectSelectedItemCode = (state: RootState) => state.items.selectedItemCode;
export const selectSelectedItemStats = (state: RootState) => state.items.selectedItemStats;
export const selectSelectedItemNotifications = (state: RootState) => state.items.selectedItemNotifications;
export const selectPackages = (state: RootState) => state.items.packages;

export const getSelectedItem =
  (id: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(itemsSlice.actions.updateSelectedItem());
    const item = await axios.get<Item>(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${id}`);
    const itemCode = await axios.get<ItemCode>(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${id}/code`);
    const itemStats = await axios.get<ItemStats>(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${id}/stats`);
    const notifications = await axios.get<ItemNotification[]>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/items/${id}/notifications`
    );
    const packages = await axios.get<Package[]>(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${id}/packages`);
    dispatch(
      itemsSlice.actions.updateSelectedItem({
        item: item.data,
        itemCode: itemCode.data,
        itemStats: itemStats.data,
        notifications: notifications.data,
        packages: packages.data,
      })
    );
  };

export const updateSelectedItem = (params: {
  id: number;
  title: string;
  visible: boolean;
  allowNameSearch: boolean;
  expiresAt: Date;
}): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('itemsSlice.updateSelectedItem', async (dispatch) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${params.id}`, {
        title: params.title,
        visible: params.visible,
        allowNameSearch: params.allowNameSearch,
        expiresAt: params.expiresAt,
      });
    } catch (e) {
      dispatch(setCommonError('アイテムの更新に失敗しました'));
    }
  });

export const addItem = (
  title: string,
  visible: boolean,
  allowNameSearch: boolean,
  itemCodeLight: boolean,
  expiresAt: Date | null
): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('itemsSlice.addItem', async (_dispatch) => {
    await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/items`, {
      title,
      visible,
      allowNameSearch,
      itemCodeLight,
      expiresAt,
    });
  });

export const sendNotification = (itemId: number, text: string) =>
  withLoading('itemsSlice.sendNotification', async () => {
    await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${itemId}/notifications`, {
      contents: JSON.stringify({ text }),
    });
  });

export const deleteItem = (itemId: number) =>
  withLoading('itemSlice.deleteItem', async () => {
    await axios.delete(`${process.env.REACT_APP_API_BASEURL}/v1/console/items/${itemId}`);
  });

export const refreshItems =
  (params: { page?: number; withReset?: boolean }): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { page, withReset } = params;

    if (withReset) {
      dispatch(
        itemsSlice.actions.updateItems({
          items: initialState.items,
          totalCount: initialState.itemsTotalCount,
          page: initialState.itemsCurrentPage,
        })
      );
    }

    const queryPage = page ? page - 1 : 0;

    const res = await axios.get(`${process.env.REACT_APP_API_BASEURL}/v1/console/items`, {
      params: {
        page: queryPage,
        count: ITEMS_PER_PAGE_COUNT,
      },
    });

    dispatch(
      itemsSlice.actions.updateItems({
        items: res.data.items,
        totalCount: res.data.totalCount,
        page: queryPage + 1,
      })
    );
  };

export const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    updateSelectedItem: (
      state,
      payload: PayloadAction<
        | {
            item: Item;
            itemCode: ItemCode;
            itemStats: ItemStats;
            notifications: ItemNotification[];
            packages: Package[];
          }
        | undefined
      >
    ) => ({
      ...state,
      selectedItem: payload.payload?.item,
      selectedItemCode: payload.payload?.itemCode,
      selectedItemStats: payload.payload?.itemStats,
      selectedItemNotifications: payload.payload?.notifications || [],
      packages: payload.payload?.packages || [],
    }),
    updateItems: (
      state,
      items: PayloadAction<{
        items: Item[];
        totalCount: number;
        page: number;
      }>
    ) => ({
      ...state,
      items: items.payload.items,
      itemsTotalCount: items.payload.totalCount,
      itemsCurrentPage: items.payload.page,
    }),
  },
});

export default itemsSlice.reducer;
