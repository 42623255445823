import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { clearCommonError } from './commonSlice';

export default function CommonErrorModal() {
  const dispatch = useDispatch();
  const commonError = useSelector(
    (state: RootState) => state.common.commonError
  );

  const onClose = () => {
    dispatch(clearCommonError());
  };

  return (
    <Modal show={commonError} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>エラー</Modal.Title>
      </Modal.Header>
      <Modal.Body>{commonError}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
