/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import {
  createSendItemRequest,
  deleteSendItemRequest,
  initPage,
  putSendItemRequest,
  selectAddresses,
  selectItems,
  selectSendItemRequests,
  selectSubscribers,
} from './sendItemRequestSlice';
import SimpleTable, { SimpleTablePropTypes } from '../../components/SimpleTable';
import { Address, AppUser, Item, SendItemRequest, SendItemRequestWrapper, showSendMethod } from '../common/models';
import { formatJSONDate, getGroupType } from '../../util';
import validationErrors from '../../util/validationErrors';
import DeleteModal from '../../components/DeleteModal';
import { refreshSendItemRequests, selectUser } from '../common/commonSlice';

type ModalMode = 'CLOSE' | 'CREATE' | 'EDIT';

interface EditModalProps {
  mode: ModalMode;
  onCancel: () => any;
  onSave: (_sendItemRequest: SendItemRequest) => any;
  initialValue: SendItemRequest | null;
  items: Item[];
  addresses: Address[];
  subscribers: AppUser[];
}

function EditModal({ mode, onCancel, onSave, initialValue, items, addresses, subscribers }: EditModalProps) {
  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      itemId: Yup.number().min(1, validationErrors.required()),
      addressId: Yup.number().when('sendMethod', {
        is: (val: string) => ['EMAIL', 'POST'].includes(val),
        then: Yup.number().min(1, validationErrors.required()),
      }),
      appUserId: Yup.number().when('sendMethod', {
        is: (val: string) => ['PUSH'].includes(val),
        then: Yup.number().min(1, validationErrors.required()),
      }),
      sendMethod: Yup.string().required(validationErrors.required()),
      sendCondition: Yup.string().required(validationErrors.required()),
      sendDateYMD: Yup.string().when('sendCondition', {
        is: 'DATE',
        then: Yup.string()
          .required(validationErrors.required())
          .matches(/\d{4}\/\d{1,2}\/\d{1,2}/, '年/月/日の形式で入力してください'),
      }),
      sendTime: Yup.string().when('sendCondition', {
        is: 'DATE',
        then: Yup.string()
          .required(validationErrors.required())
          .matches(/\d{1,2}:\d{1,2}/, '時:分の形式で入力してください'),
      }),
      sendDaysAfterDeath: Yup.number().when('sendCondition', {
        is: 'AFTER_DEATH_DAY',
        then: Yup.number()
          .required(validationErrors.required())
          .min(1, '1以上の数値を入力してください')
          .integer('整数で入力してください'),
      }),
    }),
    initialValues: {
      itemId: initialValue?.item_id?.toString() || '0',
      addressId: initialValue?.address_id?.toString() || '0',
      appUserId: initialValue?.app_user_id?.toString() || '0',
      sendMethod: initialValue?.send_method || 'EMAIL',
      sendCondition: initialValue?.send_condition || 'DATE',
      sendDateYMD: initialValue?.send_date ? moment(initialValue.send_date).format('yyyy/MM/DD') : '',
      sendTime: initialValue?.send_date ? moment(initialValue.send_date).format('HH:mm') : '',
      sendDaysAfterDeath: initialValue?.send_days_after_death?.toString() || '0',
    },
    onSubmit: async (value) => {
      const sendItemRequest: SendItemRequest = {
        item_id: Number.parseInt(value.itemId!, 10),
        address_id: ['EMAIL', 'POST'].includes(value.sendMethod) ? Number.parseInt(value.addressId, 10) : undefined,
        app_user_id: ['PUSH'].includes(value.sendMethod) ? Number.parseInt(value.appUserId, 10) : undefined,
        send_method: value.sendMethod,
        send_condition: value.sendCondition,
        id: initialValue?.id || 0,
      };

      if (value.sendCondition === 'DATE') {
        sendItemRequest.send_date = moment(`${value.sendDateYMD} ${value.sendTime}`).toDate().toJSON();
      } else if (value.sendCondition === 'AFTER_DEATH_DAY') {
        sendItemRequest.send_days_after_death = Number.parseInt(value.sendDaysAfterDeath!, 10);
      }

      await onSave(sendItemRequest);
      formik.handleReset(null);
    },
    enableReinitialize: true,
  });

  // callback
  const onCancelClick = (e: any) => {
    formik.handleReset(e);
    onCancel();
  };

  return (
    <Modal show={mode !== 'CLOSE'}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={5} lg={3}>
                アイテム名
              </Form.Label>
              <Col md={7}>
                <Form.Select
                  name="itemId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.itemId!}
                  isValid={formik.touched.itemId && !formik.errors.itemId}
                  isInvalid={formik.touched.itemId && !!formik.errors.itemId}
                >
                  <option value="0">-</option>
                  {items.map((item) => (
                    <option key={item.id.toString()} value={item.id}>
                      {item.id}: {item.title}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.itemId}</Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={5} lg={3}>
                送信方法
              </Form.Label>
              <Col md={7}>
                <Form.Select
                  name="sendMethod"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.sendMethod}
                  isValid={formik.touched.sendMethod && !formik.errors.sendMethod}
                  isInvalid={formik.touched.sendMethod && !!formik.errors.sendMethod}
                >
                  <option value="EMAIL">メール</option>
                  <option value="POST">郵送</option>
                  <option value="PUSH">プッシュ通知</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.sendMethod}</Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={5} lg={3}>
                宛先
              </Form.Label>
              <Col md={7}>
                {['PUSH'].includes(formik.values.sendMethod) && (
                  <>
                    <Form.Select
                      name="appUserId"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.appUserId!}
                      isValid={formik.touched.appUserId && !formik.errors.appUserId}
                      isInvalid={formik.touched.appUserId && !!formik.errors.appUserId}
                    >
                      <option value="0">-</option>
                      {subscribers.map((subscriber) => (
                        <option key={`APL${subscriber.id.toString()}`} value={subscriber.id}>
                          {subscriber.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{formik.errors.appUserId}</Form.Control.Feedback>
                  </>
                )}
                {['EMAIL', 'POST'].includes(formik.values.sendMethod) && (
                  <>
                    <Form.Select
                      name="addressId"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.addressId!}
                      isValid={formik.touched.addressId && !formik.errors.addressId}
                      isInvalid={formik.touched.addressId && !!formik.errors.addressId}
                    >
                      <option value="0">-</option>
                      {addresses.map((address) => (
                        <option key={address.id.toString()} value={address.id}>
                          {address.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{formik.errors.addressId}</Form.Control.Feedback>
                  </>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={5} lg={3}>
                送信条件
              </Form.Label>
            </Row>
            <Row className="ps-5 mb-2">
              <Form.Check
                type="radio"
                name="sendCondition"
                label="送信日時を指定"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                id="DATE"
                checked={formik.values.sendCondition === 'DATE'}
                value="DATE"
                className="mb-2"
              />
              {formik.values.sendCondition === 'DATE' && (
                <div>
                  <Form.Group className="mb-2 ms-2">
                    <Row>
                      <Form.Label column md={3} lg={2}>
                        日付
                      </Form.Label>
                      <Col md={5}>
                        <Form.Control
                          type="text"
                          name="sendDateYMD"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.sendDateYMD}
                          isValid={formik.touched.sendDateYMD && !formik.errors.sendDateYMD}
                          isInvalid={formik.touched.sendDateYMD && !!formik.errors.sendDateYMD}
                          placeholder="2030/01/02"
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.sendDateYMD}</Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3 ms-2">
                    <Row>
                      <Form.Label column md={3} lg={2}>
                        時刻
                      </Form.Label>
                      <Col md={5}>
                        <Form.Control
                          type="text"
                          name="sendTime"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.sendTime}
                          isValid={formik.touched.sendTime && !formik.errors.sendTime}
                          isInvalid={formik.touched.sendTime && !!formik.errors.sendTime}
                          placeholder="02:12"
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.sendTime}</Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                </div>
              )}
            </Row>
            {getGroupType() === 'CELEMONY' && (
              <Row className="ps-5">
                <Form.Check
                  type="radio"
                  name="sendCondition"
                  label="送信条件を指定"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  id="AFTER_DEATH_DAY"
                  checked={formik.values.sendCondition === 'AFTER_DEATH_DAY'}
                  value="AFTER_DEATH_DAY"
                  className="mb-2"
                />
                {formik.values.sendCondition === 'AFTER_DEATH_DAY' && (
                  <div>
                    <Form.Group className="mb-3 ms-2">
                      <Row>
                        <Form.Label column xs={3}>
                          死亡日から
                        </Form.Label>
                        <Col xs={3}>
                          <Form.Control
                            type="text"
                            name="sendDaysAfterDeath"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.sendDaysAfterDeath!}
                            isValid={formik.touched.sendDaysAfterDeath && !formik.errors.sendDaysAfterDeath}
                            isInvalid={formik.touched.sendDaysAfterDeath && !!formik.errors.sendDaysAfterDeath}
                            placeholder="3"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.sendDaysAfterDeath}
                          </Form.Control.Feedback>
                        </Col>
                        <Form.Label column xs={2}>
                          日後
                        </Form.Label>
                      </Row>
                    </Form.Group>
                  </div>
                )}
              </Row>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancelClick}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function SendItemRequestPage() {
  // boilerplates
  const dispatch = useDispatch();

  // user
  const user = useSelector(selectUser);

  // states
  const sendItemRequests = useSelector(selectSendItemRequests);
  const items = useSelector(selectItems);
  const addresses = useSelector(selectAddresses);
  const subscribers = useSelector(selectSubscribers);

  // local states
  const [modalMode, setModalMode] = useState<ModalMode>('CLOSE');
  const [editingSendItemRequest, setEditingSendItemRequest] = useState<SendItemRequest | null>(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deletingSendItemRequest, setDeletingSendItemRequest] = useState<SendItemRequest | null>(null);

  // init view
  useEffect(() => {
    dispatch(initPage());
  }, []);

  // callbacks
  const onCreateClick = () => {
    setEditingSendItemRequest(null);
    setModalMode('CREATE');
  };

  const onModalCancel = () => {
    setModalMode('CLOSE');
  };

  const onModalSave = async (sendItemRequest: SendItemRequest) => {
    if (modalMode === 'CREATE') {
      await dispatch(createSendItemRequest(sendItemRequest));
    } else if (modalMode === 'EDIT') {
      await dispatch(putSendItemRequest(sendItemRequest));
    }
    setModalMode('CLOSE');
    dispatch(refreshSendItemRequests());
  };

  const onDeleteRequestClicked = (sendItemRequest: SendItemRequest) => {
    setDeletingSendItemRequest(sendItemRequest);
    setDeleteModalShow(true);
  };

  const onDeleteRequestConfirmed = async () => {
    if (deletingSendItemRequest) {
      await dispatch(deleteSendItemRequest(deletingSendItemRequest));
    }
    setDeleteModalShow(false);
    dispatch(refreshSendItemRequests());
  };

  const onDeleteRequestCancelled = () => {
    setDeleteModalShow(false);
  };

  // table
  const tableProps: SimpleTablePropTypes = {
    titles: {
      itemTitle: 'アイテム名',
      addressName: '宛先氏名',
      sendMethod: '送信方法',
      sendDate: '送信予定日時',
      sentDate: '送信完了日時',
      status: 'ステータス',
      operation: '操作',
    },
    values: (sendItemRequests || [])
      .filter((req) => req.entity.Item?.ConsoleUser.id === user?.id)
      .map((req) => ({
        id: req.entity.id!.toString(),
        itemTitle: req.entity.Item!.title,
        addressName: req.entity.Address ? req.entity.Address.name : req.entity.AppUser!.name,
        sendMethod: showSendMethod(req.entity),
        sendDate: ((r: SendItemRequestWrapper) => {
          if (r.entity.send_condition === 'DATE') {
            return formatJSONDate(r.entity.send_date || '');
          }
          return `死亡日の${r.entity.send_days_after_death}日後`;
        })(req),
        sentDate: formatJSONDate(req.entity.sent_date),
        status: ((r) => {
          if (r.current_status === 'BEFORE_DATE') {
            return '期日前';
          }
          if (r.current_status === 'PENDING') {
            return '未送信';
          }
          return '完了';
        })(req),
        operation: (
          <>
            <Button
              size="sm"
              variant="secondary"
              className="me-2"
              onClick={() => {
                setEditingSendItemRequest(req.entity);
                setModalMode('EDIT');
              }}
              disabled={req.current_status !== 'BEFORE_DATE'}
            >
              編集
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                onDeleteRequestClicked(req.entity);
              }}
            >
              削除
            </Button>
          </>
        ),
      })),
  };

  return (
    <Page>
      <DeleteModal
        objectName="アイテム送信"
        onCloseClicked={onDeleteRequestCancelled}
        onDeleteClicked={onDeleteRequestConfirmed}
        show={deleteModalShow}
      />

      <EditModal
        mode={modalMode}
        onCancel={onModalCancel}
        onSave={onModalSave}
        initialValue={editingSendItemRequest}
        items={items || []}
        addresses={addresses || []}
        subscribers={subscribers}
      />

      <PageTitle value="アイテム送信一覧" />
      <PageBody loading={!sendItemRequests || !items || !addresses}>
        <Button onClick={onCreateClick}>追加</Button>
        <SimpleTable {...tableProps} />
      </PageBody>
    </Page>
  );
}
