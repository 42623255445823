import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function DeleteModal(props: {
  show: boolean;
  onDeleteClicked: () => any;
  onCloseClicked: () => any;
  objectName: string;
}) {
  const { show, onDeleteClicked, onCloseClicked, objectName } = props;

  return (
    <Modal show={show} onHide={onCloseClicked}>
      <Modal.Header>
        <Modal.Title>削除確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        この {objectName} を本当に削除しますか？
        <br />※ この操作は戻せません
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onDeleteClicked}>
          削除する
        </Button>
        <Button variant="secondary" onClick={onCloseClicked}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
