import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectSendItemRequests } from '../features/common/commonSlice';
import { getIsMaster } from '../util';

export default function Title(props: { value: string }) {
  const { value } = props;
  const history = useHistory();
  const isMaster = useSelector(getIsMaster);

  const sendItemRequests = useSelector(selectSendItemRequests);
  const [pendingItemCount, setPendingItemCount] = useState(0);

  useEffect(() => {
    setPendingItemCount(sendItemRequests.filter((req) => req.current_status === 'PENDING').length);
  }, [sendItemRequests]);

  const onClickAlert = () => {
    history.push('/sendings');
  };

  return (
    <div>
      {isMaster && pendingItemCount > 0 && (
        <Alert key="danger" variant="danger" onClick={onClickAlert}>
          未送信のアイテムが {pendingItemCount}件 あります。送信対応をしてください。
        </Alert>
      )}
      <h1 className="mb-4">{value}</h1>
    </div>
  );
}
