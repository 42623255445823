import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CommonErrorModal from '../../../features/common/CommonErrorModal';
import { login, selectSessionStatus } from '../bocommon/bocommonSlice';

export default function BOLoginPage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // state
  const sessionStatus = useSelector(selectSessionStatus);

  // init
  useEffect(() => {
    if (sessionStatus === 'OK') {
      history.push('/bo/menu');
    }
  }, [sessionStatus]);

  // local state
  const [password, setPassword] = useState('');

  // callback
  const onPasswordChange = (
    e: React.ChangeEvent<typeof FormControl & HTMLInputElement>
  ) => {
    setPassword(e.target.value);
  };

  const onSubmit = async (
    e: React.FormEvent<typeof FormControl & HTMLFormElement>
  ) => {
    e.preventDefault();
    await dispatch(login(password));
  };

  return (
    <Container className="pt-3">
      <CommonErrorModal />
      <Card>
        <Card.Header>BlueOcean 管理画面ログイン</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>パスワード</Form.Label>
              <Form.Control type="password" onChange={onPasswordChange} />
            </Form.Group>
            <Col style={{ textAlign: 'center' }}>
              <Button variant="primary" type="submit">
                ログイン
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
