import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Col, Form, Row, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import validationErrors from '../../util/validationErrors';

export type PasswordChangeFormProps = {
  onSubmit: (_currentPassword: string, _newPassword: string) => unknown;
};

export default function PasswordChangeForm(props: PasswordChangeFormProps) {
  // Configuration for changing password
  const isValidPassword = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z!:-@[-`{-~\d]{8,100}$/;
  const requiredMessage = validationErrors.required();
  const invalidPasswordMessage = '８～２０文字の英字大文字・小文字・数字を一種類ずつ組み合わせた値を入力してください';
  const passwordConfirmationErrorMessage = '２つのパスワードが異なります';

  // Formik configuration
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      current_password: Yup.string().required(requiredMessage),
      password: Yup.string().required(requiredMessage).matches(isValidPassword, invalidPasswordMessage),
      password2: Yup.string()
        .required(requiredMessage)
        .oneOf([Yup.ref('password')], passwordConfirmationErrorMessage),
    }),
    initialValues: {
      current_password: '',
      password: '',
      password2: '',
    },
    onSubmit: (v) => {
      props.onSubmit(v.current_password, v.password);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={3}>
              現在のパスワード
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="password"
                name="current_password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.current_password}
                isValid={formik.touched.current_password && !formik.errors.current_password}
                isInvalid={formik.touched.current_password && !!formik.errors.current_password}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.current_password}</Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={3}>
              新しいパスワード
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                isValid={formik.touched.password && !formik.errors.password}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={3}>
              新しいパスワード（確認）
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="password"
                name="password2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password2}
                isValid={formik.touched.password2 && !formik.errors.password2}
                isInvalid={formik.touched.password2 && !!formik.errors.password2}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.password2}</Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Button variant="primary" type="submit">
            変更する
          </Button>
        </Form.Group>
      </Form>
    </FormikProvider>
  );
}
