/* eslint-disable import/prefer-default-export */
import { Avator } from '../models/ArPackage';

export const avators: Avator[] = [
  'empty',
  'avator_adult_male',
  'avator_adult_female',
  'avator_child_male',
  'avator_child_female',
  'avator_old_male',
  'avator_old_female',
  'ichii_001',
  'nerima_001',
  'giran_001',
  'giran_002',
  'sports_001',
  'afan_001',
  'afan_002',
  'afan_003',
  'mockup_001_santa',
  'mockup_002_woman01',
  'mockup_003_grandma',
  'mockup_004_woman02',
  'mockup_005_fish',
  'momorin_a',
  'momorin_b',
  'momorin_c',
  'momorin_d',
  'honeybee_red',
  'honeybee_green',
].map((id) => ({ name_id: id }));
