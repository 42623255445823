import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch, RootState } from '../../app/store';
import { getAppDownloadUrl } from '../../util';
import { setCommonError, withLoading } from '../common/commonSlice';
import * as M from '../common/models/ArPackage';

// const
type SendStatus = 'SUCCEEDED' | 'FAILED' | null;

// State
export interface ArPackageState {
  sendStatus: SendStatus;

  packageId?: number;
  arPackage?: M.ArPackage;
  arPackageAnswers?: M.ArPackageAnswer[];

  // Contents
  uploadedFileId?: string;
}

// Initial State
const initialState: ArPackageState = {
  sendStatus: null,
};

// Selectors
export const selectSendStatus = (state: RootState) =>
  state.arPackage.sendStatus;
export const selectPackageId = (state: RootState) => state.arPackage.packageId;
export const selectArPackage = (state: RootState) => state.arPackage.arPackage;
export const selectArPackageAnswers = (state: RootState) =>
  state.arPackage.arPackageAnswers;
export const selectUploadedFileId = (state: RootState) =>
  state.arPackage.uploadedFileId;

// Thunks
export const createArPackage = (
  itemId: number,
  title: string,
  onSuccess?: (_packageId: number) => any
) =>
  withLoading(
    'arPackageSlice.createArPackage',
    async (dispatch: AppDispatch) => {
      const arPackage: M.ArPackage = {
        title,
        force_start_scenario_id: null,
        scenarios: [],
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASEURL}/v1/console/items/${itemId}/packages`,
          { packageType: 'ar', title, data: JSON.stringify(arPackage) }
        );
        dispatch(setArPackage(arPackage));
        dispatch(setPackageId(res.data.id));
        if (onSuccess) {
          onSuccess(res.data.id);
        }
      } catch (err) {
        dispatch(setCommonError('ARパッケージの作成に失敗しました'));
      }
    }
  );

export const uploadMarker = (
  packageId: number,
  scenario: M.Scenario,
  file: File
) =>
  withLoading(
    'arPackageSlice.uploadMarker',
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const arPackage = selectArPackage(getState());
      if (!arPackage) {
        throw new Error('unexpected path');
      }

      const uploadFormData = new FormData();

      uploadFormData.append('files', file);

      const res = await axios.post<{ files: string[] }>(
        `${process.env.REACT_APP_API_BASEURL}/v1/console-file/packages/${packageId}/files`,
        uploadFormData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );

      if (res.data.files.length === 0) {
        throw new Error('unexpected response');
      }

      const newScenario = {
        ...scenario,
        marker: {
          image_key: res.data.files[0],
          image_url: getAppDownloadUrl(packageId, res.data.files[0]),
        },
      };

      const newArPackage: M.ArPackage = {
        ...arPackage,
        scenarios: arPackage.scenarios.map((s) =>
          s.scenario_id === newScenario.scenario_id ? newScenario : s
        ),
      };

      await dispatch(updateArPackage(packageId, newArPackage));
    }
  );

export const uploadFile = (packageId: number, file: File) =>
  withLoading('arPackageSlice.uploadFile', async (dispatch: AppDispatch) => {
    const uploadFormData = new FormData();

    uploadFormData.append('files', file);

    const res = await axios.post<{ files: string[] }>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console-file/packages/${packageId}/files`,
      uploadFormData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      }
    );

    if (res.data.files.length === 0) {
      throw new Error('unexpected response');
    }

    await dispatch(setUploadedFileId(res.data.files[0]));
  });

export const updateArPackage = (
  packageId: number,
  arPackage: M.ArPackage,
  onSuccess?: () => any
) =>
  withLoading(
    'arPackageSlice.updateArPackage',
    async (dispatch: AppDispatch, getState: () => RootState) => {
      try {
        const oldPackage = getState().arPackage.arPackage;
        if (!oldPackage) {
          throw new Error('unexpectedly old package not found');
        }

        await dispatch(
          updateArPackageInternal(packageId, arPackage, oldPackage)
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        if (err.response && err.response.data.code === 4000004) {
          dispatch(
            setCommonError(
              'ARパッケージが別の画面で修正されています。画面を再読み込みして操作をやり直してください'
            )
          );
        } else {
          dispatch(setCommonError('ARパッケージの更新に失敗しました'));
        }
      }
    }
  );

const updateArPackageInternal =
  (packageId: number, arPackage: M.ArPackage, oldPackage: M.ArPackage) =>
  async (dispatch: AppDispatch) => {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${packageId}`,
      {
        packageType: 'ar',
        title: arPackage.title,
        data: JSON.stringify(arPackage),
        oldData: JSON.stringify(oldPackage),
      }
    );
    dispatch(setArPackage(arPackage));
    dispatch(setPackageId(res.data.id));
  };

export const changeScenarioOrder = (
  packageId: number,
  src: number,
  dst: number
) =>
  withLoading(
    'arPackageSlice.changeScenarioOrder',
    async (dispatch: AppDispatch, state: () => RootState) => {
      const arPackage = selectArPackage(state());

      if (!arPackage) {
        return;
      }

      try {
        const newScenarios = arPackage.scenarios.filter((_, i) => i !== src);
        newScenarios.splice(dst, 0, arPackage.scenarios[src]);

        const newArPackage: M.ArPackage = {
          ...arPackage,
          scenarios: newScenarios,
        };
        dispatch(setArPackage(newArPackage));

        await dispatch(
          updateArPackageInternal(packageId, newArPackage, arPackage)
        );
      } catch (err) {
        dispatch(setArPackage(arPackage));

        if (err.response && err.response.data.code === 4000004) {
          dispatch(
            setCommonError(
              'ARパッケージが別の画面で修正されています。画面を再読み込みして操作をやり直してください'
            )
          );
        } else {
          dispatch(setCommonError('ARパッケージの更新に失敗しました'));
        }
      }
    }
  );

export const deletePackage = (packageId: number) =>
  withLoading('arPackageSlice.deletePackage', async (dispatch: AppDispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${packageId}`
      );
    } catch (err) {
      dispatch(setCommonError('ARパッケージの削除に失敗しました'));
    }
  });

export const loadArPackage =
  (packageId: number) => async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get<{ data: { data: M.ArPackage } }>(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${packageId}`
      );
      dispatch(setArPackage(res.data.data.data));
      dispatch(setPackageId(packageId));
    } catch (err) {
      dispatch(setCommonError('ARパッケージの読み込みに失敗しました'));
    }
  };

export const loadArPackageAnswers =
  (packageId: number) => async (dispatch: AppDispatch) => {
    try {
      const res = await axios.get<M.ArPackageAnswer[]>(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${packageId}/answers`
      );
      dispatch(setArPackageAnswers(res.data));
    } catch (err) {
      dispatch(setCommonError('ARパッケージ回答一覧の読み込みに失敗しました'));
    }
  };

// Slice
export const arPackageSlice = createSlice({
  name: 'arPackage',
  initialState,
  reducers: {
    setSendStatus: (state, payload: PayloadAction<SendStatus>) => ({
      ...state,
      sendStatus: payload.payload,
    }),
    setPackageId: (state, payload: PayloadAction<number | undefined>) => ({
      ...state,
      packageId: payload.payload,
    }),
    setArPackage: (state, payload: PayloadAction<M.ArPackage | undefined>) => ({
      ...state,
      arPackage: payload.payload,
    }),
    setArPackageAnswers: (
      state,
      payload: PayloadAction<M.ArPackageAnswer[] | undefined>
    ) => ({
      ...state,
      arPackageAnswers: payload.payload,
    }),
    setUploadedFileId: (state, payload: PayloadAction<string | undefined>) => ({
      ...state,
      uploadedFileId: payload.payload,
    }),
  },
});

// Exports
export const {
  setSendStatus,
  setPackageId,
  setArPackage,
  setArPackageAnswers,
  setUploadedFileId,
} = arPackageSlice.actions;
export default arPackageSlice.reducer;
