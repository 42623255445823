import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AnimationStateContent } from '../../common/models/ArPackage';
import { ContentsPropTypes } from './ContentPropTypes';
import validationErrors from '../../../util/validationErrors';

export default function AnimationStateComponent({
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as AnimationStateContent;

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      state: Yup.string().required(validationErrors.required()),
    }),
    initialValues: {
      state: c.state,
    },
    onSubmit: (val) => {
      const newContent: AnimationStateContent = {
        ...c,
        state: val.state,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={2}>
              state
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="text"
                name="state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.state}
                isValid={formik.touched.state && !formik.errors.state}
                isInvalid={formik.touched.state && !!formik.errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.state}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <div className="mt-5">
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
