import React from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import validationErrors from '../../util/validationErrors';
import { sendNotification } from './itemsSlice';

interface ItemNotificationPageParams {
  id: string;
}

export default function ItemNotificationPage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // params
  const params = useParams<ItemNotificationPageParams>();
  const itemId = Number.parseInt(params.id, 10);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      text: Yup.string().required(validationErrors.required()),
    }),
    initialValues: {
      text: '',
    },
    onSubmit: async (value) => {
      await dispatch(sendNotification(itemId, value.text));
      history.push(`/items/${itemId}`);
    },
  });

  // callbacks
  const onCancelClick = () => {
    history.push(`/items/${itemId}`);
  };

  return (
    <Page>
      <PageTitle value="お知らせ一括送信" />
      <PageBody>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={2} lg={2}>
                本文
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.text}
                  isValid={formik.touched.text && !formik.errors.text}
                  isInvalid={formik.touched.text && !!formik.errors.text}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.text}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Button type="submit" variant="primary" className="me-2">
            送信
          </Button>
          <Button variant="secondary" onClick={onCancelClick}>
            キャンセル
          </Button>
        </Form>
      </PageBody>
    </Page>
  );
}
