import React, { useEffect } from 'react';

import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, setIsCreateUserFinished } from '../common/commonSlice';
import { RootState } from '../../app/store';
import validationErrors from '../../util/validationErrors';
import { isValidPassword, isValidStringId } from '../../util';

/**
 * コンソールユーザー作成ページ
 */
export default function UserCreatePage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const isCreateAccountFinished = useSelector(
    (state: RootState) => state.common.isCreateUserFinished
  );

  useEffect(() => {
    if (isCreateAccountFinished) {
      dispatch(setIsCreateUserFinished(false));
      history.push('/login');
    }
  }, [isCreateAccountFinished]);

  const createAccount = async (data: any) => {
    await dispatch(
      createUser(
        data.groupId,
        data.loginId,
        data.userName,
        data.email,
        data.password
      )
    );
  };

  const requiredMessage = validationErrors.required();
  const invalidStringIdMessage = validationErrors.id();
  const invalidPasswordMessage = validationErrors.password();
  const invalidEmailMessage = validationErrors.email();
  const passwordConfirmationErrorMessage = '２つのパスワードが異なります';

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="mt-3">
            <Card.Header>アカウント作成</Card.Header>
            <Card.Body>
              <Formik
                validationSchema={Yup.object().shape({
                  groupId: Yup.string()
                    .required(requiredMessage)
                    .matches(isValidStringId, invalidStringIdMessage),
                  loginId: Yup.string()
                    .required(requiredMessage)
                    .matches(isValidStringId, invalidStringIdMessage),
                  userName: Yup.string().required(requiredMessage),
                  email: Yup.string()
                    .required(requiredMessage)
                    .email(invalidEmailMessage),
                  password: Yup.string()
                    .required(requiredMessage)
                    .matches(isValidPassword, invalidPasswordMessage),
                  password2: Yup.string()
                    .required(requiredMessage)
                    .matches(isValidPassword, invalidPasswordMessage)
                    .oneOf(
                      [Yup.ref('password')],
                      passwordConfirmationErrorMessage
                    ),
                })}
                onSubmit={(data) => {
                  createAccount(data);
                }}
                initialValues={{
                  groupId: '',
                  loginId: '',
                  userName: '',
                  email: '',
                  password: '',
                  password2: '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          企業ID
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="企業ID"
                            name="groupId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.groupId}
                            isValid={touched.groupId && !errors.groupId}
                            isInvalid={touched.groupId && !!errors.groupId}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.groupId}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          ユーザID
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="text"
                            placeholder="ユーザID"
                            name="loginId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.loginId}
                            isValid={touched.loginId && !errors.loginId}
                            isInvalid={touched.loginId && !!errors.loginId}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.loginId}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          氏名
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="text"
                            placeholder="氏名"
                            name="userName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.userName}
                            isValid={touched.userName && !errors.userName}
                            isInvalid={touched.userName && !!errors.userName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.userName}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          Eメール
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Eメール"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          パスワード
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="password"
                            placeholder="パスワード"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            isValid={touched.password && !errors.password}
                            isInvalid={touched.password && !!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Form.Label column lg={3}>
                          パスワード（確認）
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="password"
                            placeholder="パスワード（確認）"
                            name="password2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password2}
                            isValid={touched.password2 && !errors.password2}
                            isInvalid={touched.password2 && !!errors.password2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password2}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>

                    <div className="text-center">
                      <Button variant="primary" type="submit">
                        作成
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card.Body>

            <Container>
              <p>
                <Link to="/login">ログイン</Link>
              </p>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
