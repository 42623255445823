import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch, RootState } from '../../app/store';
import { withLoading } from '../common/commonSlice';
import { Address } from '../common/models';

// Constants
const consts = {
  // AddressListPage
  ITEMS_PER_PAGE: 10,
};

// State
export interface AddressState {
  // AddressListPage
  currentAddresses: Address[] | null;
  totalPageCount: number;
  currentPage: number;
}

// Initial State
const initialState: AddressState = {
  // AddressListPage
  currentAddresses: null,
  totalPageCount: 1,
  currentPage: 1,
};

// Selectors
export const selectCurrentAddresses = (state: RootState) =>
  state.address.currentAddresses;
export const selectTotalPageCount = (state: RootState) =>
  state.address.totalPageCount;
export const selectCurrentPage = (state: RootState) =>
  state.address.currentPage;

// Thunks
export const getAddresses =
  (newCurrentPage: number, resetList: boolean = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(addressSlice.actions.setCurrentPage(newCurrentPage));
    if (resetList) {
      dispatch(addressSlice.actions.setCurrentAddresses(null));
    }

    const res = await axios.get<{ addresses: Address[]; totalCount: number }>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/addresses`,
      {
        params: {
          page: newCurrentPage,
          count: consts.ITEMS_PER_PAGE,
        },
      }
    );

    dispatch(addressSlice.actions.setCurrentAddresses(res.data.addresses));
    dispatch(
      addressSlice.actions.setTotalPageCount(
        Math.ceil(res.data.totalCount / consts.ITEMS_PER_PAGE)
      )
    );
  };

const addressToRequestBody = (address: Address) => {
  const req: { [key: string]: any } = {
    name: address.name,
  };

  if (address.email.length > 0) {
    req.email = address.email;
  }

  if (address.address.length > 0) {
    req.address = address.address;
  }

  return req;
};

export const createAddress = (address: Address) =>
  withLoading('addressSlice.createAddress', async (dispatch, getState) => {
    const url = `${process.env.REACT_APP_API_BASEURL}/v1/console/addresses`;
    const body = addressToRequestBody(address);
    await axios.post(url, body);

    const currentPage = selectCurrentPage(getState());
    await dispatch(getAddresses(currentPage));
  });

export const editAddress = (address: Address) =>
  withLoading('addressSlice.editAddress', async (dispatch, getState) => {
    const url = `${process.env.REACT_APP_API_BASEURL}/v1/console/addresses/${address.id}`;
    const body = addressToRequestBody(address);
    await axios.patch(url, body);

    const currentPage = selectCurrentPage(getState());
    await dispatch(getAddresses(currentPage));
  });

export const deleteAddress = (address: Address) =>
  withLoading('addressSlice.deleteAddress', async (dispatch, getState) => {
    const url = `${process.env.REACT_APP_API_BASEURL}/v1/console/addresses/${address.id}`;
    await axios.delete(url);

    const currentPage = selectCurrentPage(getState());
    await dispatch(getAddresses(currentPage));
  });

// Slice
export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCurrentAddresses: (state, payload: PayloadAction<Address[] | null>) => ({
      ...state,
      currentAddresses: payload.payload,
    }),
    setTotalPageCount: (state, payload: PayloadAction<number>) => ({
      ...state,
      totalPageCount: payload.payload,
    }),
    setCurrentPage: (state, payload: PayloadAction<number>) => ({
      ...state,
      currentPage: payload.payload,
    }),
  },
});

// Exports
export default addressSlice.reducer;
