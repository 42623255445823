import { useFormik } from 'formik';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import SingleFileUpload from '../../../components/SingleFileUpload';
import { isValidStringId } from '../../../util';
import validationErrors from '../../../util/validationErrors';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import boApiClient from '../bocommon/boApiClient';
import { Project } from '../bocommon/models';
import {
  loadProject,
  saveProject,
  selectProject,
  setProject,
  updateProject,
} from './boprojectsSlice';

export interface BOProjectEditPageProps {
  mode: 'CREATE' | 'EDIT';
}

interface ParamTypes {
  projectId?: string;
}

export default function BOProjectEditPage({ mode }: BOProjectEditPageProps) {
  // boilerplates
  const dispatch = useDispatch();
  const history = useHistory();

  // params
  const { projectId } = useParams<ParamTypes>();

  // localstate
  const [headerImgKey, setHeaderImgKey] = useState<string | undefined>();

  // state
  const project = useSelector(selectProject);

  // initialize
  useLayoutEffect(() => {
    if (mode === 'CREATE') {
      dispatch(setProject(undefined));
    }
    if (mode === 'EDIT' && projectId) {
      dispatch(loadProject(projectId));
    }
  }, []);
  //

  useEffect(() => {
    if (project) {
      setHeaderImgKey(project.header_img_key);
    }
  }, [project]);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      id: Yup.string()
        .required(validationErrors.required())
        .matches(isValidStringId, validationErrors.id()),
      name: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
      catchphrase: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
    }),
    initialValues: {
      id: project?.id || '',
      name: project?.name || '',
      catchphrase: project?.catchphrase || '',
    },
    onSubmit: async (value) => {
      const projectToCreate: Project = {
        ...value,
        header_img_key: headerImgKey,
      };
      if (mode === 'CREATE') {
        await dispatch(saveProject(projectToCreate));
      } else {
        await dispatch(updateProject(projectToCreate));
      }
      history.push('/bo/projects');
    },
    enableReinitialize: true,
  });

  // callbacks
  const onCancel = () => {
    history.push('/bo/projects');
  };

  const onUploadFile = async (file: File) => {
    const res = await boApiClient.uploadProjectFile(dispatch, { file });
    setHeaderImgKey(res.keys[0]);
  };

  return (
    <BOPage>
      <BOPageTitle>
        {mode === 'CREATE' ? 'プロジェクト作成' : 'プロジェクト編集'}
      </BOPageTitle>
      <BOPageBody>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                プロジェクトID
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="id"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.id}
                  isValid={formik.touched.id && !formik.errors.id}
                  isInvalid={formik.touched.id && !!formik.errors.id}
                  disabled={mode !== 'CREATE'}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.id}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                プロジェクト名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isValid={formik.touched.name && !formik.errors.name}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                キャッチフレーズ
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="catchphrase"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.catchphrase}
                  isValid={
                    formik.touched.catchphrase && !formik.errors.catchphrase
                  }
                  isInvalid={
                    formik.touched.catchphrase && !!formik.errors.catchphrase
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.catchphrase}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <SingleFileUpload
              title="ヘッダ画像"
              uploadedText="アップロード済み"
              noUploadText="未アップロード"
              onUpload={onUploadFile}
              showIcon
              isUploaded={!!headerImgKey}
            />
          </Form.Group>

          <Form.Group>
            <Button type="submit" className="me-2">
              保存
            </Button>
            <Button
              type="button"
              variant="secondary"
              className="me-2"
              onClick={onCancel}
            >
              キャンセル
            </Button>
          </Form.Group>
        </Form>
      </BOPageBody>
    </BOPage>
  );
}
