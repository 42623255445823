import React from 'react';
import { Row } from 'react-bootstrap';

export interface BOPageBodyProps {
  children: React.ReactNode;
}

export default function BOPageBody({ children }: BOPageBodyProps) {
  return <Row className="ms-2">{children}</Row>;
}
