import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { AppDispatch, RootState } from '../../app/store';
import { uniq } from '../../util';
import { Group, SendItemRequestWrapper, User } from './models';

export interface CommonState {
  loadingKeys: string[];
  commonError: string | null;
  token: string | null;
  user: User | null;
  group: Group | null;

  isCreateUserFinished: boolean;
  isChangeUserPasswordFinished: boolean;
  isLoginFinished: boolean;

  sendItemRequests: SendItemRequestWrapper[];
}

const initialState: CommonState = {
  loadingKeys: [],
  commonError: null,
  token: null,
  user: null,
  group: null,

  isCreateUserFinished: false,
  isChangeUserPasswordFinished: false,
  isLoginFinished: false,

  sendItemRequests: [],
};

export const isLoadingSelector = (state: RootState): boolean => state.common.loadingKeys.length > 0;
export const selectUser = (state: RootState) => state.common.user;
export const selectGroup = (state: RootState) => state.common.group;
export const selectSendItemRequests = (state: RootState) => state.common.sendItemRequests;

export const withLoading =
  (
    key: string,
    action: (_dispatch: any, _state: () => RootState) => Promise<any>
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(startLoading(key));

    try {
      await action(dispatch, getState);
    } finally {
      dispatch(endLoading(key));
    }
  };

export const createUser = (
  groupId: string,
  loginId: string,
  userName: string,
  email: string,
  password: string
): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('commonSlice.login', async (dispatch) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/users`, {
        groupId,
        loginId,
        userName,
        email,
        password,
      });

      dispatch(setIsCreateUserFinished(true));
    } catch (e: any) {
      if (e.response?.data?.code === 4000007) {
        dispatch(setCommonError('既に使用されているユーザIDです'));
      } else {
        dispatch(setCommonError('アカウント作成に失敗しました'));
      }
    }
  });

export const changeUserPassword = (
  currentPassword: string,
  password: string
): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('commonSlice.changePassword', async (dispatch) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASEURL}/v1/console/users/password`, {
        currentPassword,
        password,
      });

      dispatch(setIsChangePasswordFinished(true));
    } catch (e) {
      dispatch(setCommonError('パスワードの再設定に失敗しました'));
    }
  });

export const changeUser = (newUser: Partial<User>) =>
  withLoading('commonSlice.changeUser', async (dispatch) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASEURL}/v1/console/users/me`, newUser);
    } catch (e) {
      dispatch(setCommonError('ユーザの更新に失敗しました'));
    }
  });

export const loadUser = () => async (dispatch: AppDispatch) => {
  const meRes = await axios.get<{ user: User; group: Group }>(
    `${process.env.REACT_APP_API_BASEURL}/v1/console/users/me`
  );

  dispatch(setUser(meRes.data.user));
  dispatch(setGroup(meRes.data.group));
};

export const login = (
  groupId: string,
  loginId: string,
  password: string
): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('commonSlice.login', async (dispatch) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/users/login`, {
        groupId,
        loginId,
        password,
      });

      dispatch(setIsLoginFinished(true));
    } catch (e) {
      dispatch(setCommonError('ログインに失敗しました'));
    }
  });

export const logout = (timeout: boolean = false): ThunkAction<void, RootState, unknown, AnyAction> =>
  withLoading('commonSlice.logout', async (dispatch) => {
    try {
      const cookies = new Cookies();
      cookies.remove('auth-token');
      cookies.remove('auth-is-master');
      cookies.remove('auth-group-type');

      if (timeout) {
        dispatch(setCommonError('ログインがタイムアウトしたか、権限がありません。再度ログインをしてください'));
      }

      return true;
    } catch (e) {
      dispatch(setCommonError('ログアウトに失敗しました'));
      return false;
    }
  });

export const refreshSendItemRequests = () => async (dispatch: AppDispatch) => {
  const res = await axios.get<{ senditemrequests: SendItemRequestWrapper[] }>(
    `${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests`
  );
  await dispatch(setSendItemRequests(res.data.senditemrequests));
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsCreateUserFinished: (state, payload: PayloadAction<boolean>) => {
      const newState = {
        ...state,
        isCreateUserFinished: payload.payload,
      };
      return newState;
    },

    setIsLoginFinished: (state, payload: PayloadAction<boolean>) => {
      const newState = {
        ...state,
        isLoginFinished: payload.payload,
      };
      return newState;
    },

    setIsChangePasswordFinished: (state, payload: PayloadAction<boolean>) => {
      const newState = {
        ...state,
        isChangeUserPasswordFinished: payload.payload,
      };
      return newState;
    },

    startLoading: (state, payload: PayloadAction<string>) => {
      const newLoadingKeys = uniq([...state.loadingKeys, payload.payload]);

      const newState = {
        ...state,
        loadingKeys: newLoadingKeys,
      };

      return newState;
    },
    endLoading: (state, payload: PayloadAction<string>) => {
      const newLoadingKeys = state.loadingKeys.filter((key) => key !== payload.payload);

      const newState = {
        ...state,
        loadingKeys: newLoadingKeys,
      };

      return newState;
    },
    setCommonError: (state, payload: PayloadAction<string>) => {
      const newState = {
        ...state,
        commonError: payload.payload,
      };
      return newState;
    },
    clearCommonError: (state) => {
      const newState = {
        ...state,
        commonError: null,
      };
      return newState;
    },
    setUser: (state, payload: PayloadAction<User>) => ({
      ...state,
      user: payload.payload,
    }),
    setGroup: (state, payload: PayloadAction<Group>) => ({
      ...state,
      group: payload.payload,
    }),
    setSendItemRequests: (state, payload: PayloadAction<SendItemRequestWrapper[]>) => ({
      ...state,
      sendItemRequests: payload.payload,
    }),
  },
});

export const {
  startLoading,
  endLoading,
  setCommonError,
  clearCommonError,
  setIsCreateUserFinished,
  setIsChangePasswordFinished,
  setIsLoginFinished,
  setUser,
  setGroup,
  setSendItemRequests,
} = commonSlice.actions;
export default commonSlice.reducer;
