import { useFormik } from 'formik';
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import {
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
} from './arPackageSlice';
import validationErrors from '../../util/validationErrors';
import {
  ArPackage,
  createDefaultMessage,
  Scenario,
} from '../common/models/ArPackage';

type ParamTypes = {
  itemId: string;
  packageId: string;
  scenarioId: string;
};

export default function MessageAddPage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // parse params
  const params = useParams<ParamTypes>();
  const itemId = Number.parseInt(params.itemId, 10);
  const packageId = Number.parseInt(params.packageId, 10);
  const scenarioId = Number.parseInt(params.scenarioId, 10);

  // initialize page
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(packageId));
  }, []);

  // store
  const arPackage = useSelector(selectArPackage);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
    }),
    initialValues: { title: '' },
    onSubmit: async (values) => {
      if (!arPackage) return;

      const scenario = arPackage.scenarios.find(
        (sc) => Number.parseInt(sc.scenario_id, 10) === scenarioId
      );

      if (!scenario) return;

      const newMessage = createDefaultMessage(values.title, scenario.messages);
      const newScenario: Scenario = {
        ...scenario,
        messages: [...scenario.messages, newMessage],
      };

      const newArPackage: ArPackage = {
        ...arPackage,
        scenarios: arPackage.scenarios.map((sc) =>
          sc.scenario_id === newScenario.scenario_id ? newScenario : sc
        ),
      };
      await dispatch(
        updateArPackage(packageId, newArPackage, () => {
          history.push(
            `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${newMessage.message_id}`
          );
        })
      );
    },
  });

  // callbacks
  const onCancel = () => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}`
    );
  };

  return (
    <Page>
      <PageTitle value="メッセージ追加" />
      <PageBody loading={!arPackage}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                メッセージ名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.touched.title && !formik.errors.title}
                  isInvalid={formik.touched.title && !!formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Button type="submit" variant="primary" className="me-2">
            追加
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </Form>
      </PageBody>
    </Page>
  );
}
