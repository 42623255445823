import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch, RootState } from '../../app/store';
import { setCommonError, withLoading } from '../common/commonSlice';
import { Package } from '../common/models';

export interface PackageFile {
  // Uploaded File
  uploadedInfo?: {
    key: string;
    updatedAt: string;
  };

  // Uploading File
  uploadingInfo?: {
    file: File;
  };

  name: string;
  toDelete: boolean;
}
export interface DataPackageState {
  package?: Package;

  // User Inputs
  title: string;
  packageFiles: PackageFile[];
}

const initialState: DataPackageState = {
  title: '',
  packageFiles: [],
};

export const selectPackage = (state: RootState) => state.dataPackage.package;
export const selectTitle = (state: RootState) => state.dataPackage.title;
export const selectPackageFiles = (state: RootState) =>
  state.dataPackage.packageFiles;

export const clearPackage = () => (dispatch: AppDispatch) => {
  dispatch(updatePackage());
  dispatch(updateTitle());
  dispatch(updatePackageFiles());
};

// データパッケージの編集開始時に初期データを取得する
export const refreshPackage =
  (params: { packageId: number }) => async (dispatch: AppDispatch) => {
    dispatch(updatePackage());
    dispatch(updateTitle());
    dispatch(updatePackageFiles());

    const packageRes = await axios.get<{ package: Package }>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${params.packageId}`
    );
    const packageFilesRes = await axios.get<any>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${params.packageId}/files`
    );

    const packageFiles: PackageFile[] = packageFilesRes.data.map(
      (f: any): PackageFile => ({
        uploadedInfo: {
          key: f.id,
          updatedAt: f.updatedAt,
        },
        name: f.original_file_name,
        toDelete: false,
      })
    );

    dispatch(updatePackage(packageRes.data.package));
    dispatch(updateTitle(packageRes.data.package.title));
    dispatch(updatePackageFiles(packageFiles));
  };

export const deleteDataPackage = (packageId: number) =>
  withLoading('dataPackageSlice.deleteDataPackage', async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${packageId}`
    );
  });

export const createDataPackage = (params: { itemId: number }) =>
  withLoading(
    'dataPackageSlice.createDataPackage',
    async (dispatch, getState) => {
      const state = getState().dataPackage;

      let res: any;

      try {
        res = await axios.post(
          `${process.env.REACT_APP_API_BASEURL}/v1/console/items/${params.itemId}/packages`,
          {
            title: state.title,
            packageType: 'data',
          }
        );
      } catch (err) {
        dispatch(setCommonError('パッケージの作成に失敗しました'));
      }

      try {
        await updateFiles(res.data.id, state.packageFiles);
      } catch (e) {
        dispatch(
          setCommonError(
            'パッケージの作成には成功しましたが、ファイルのアップロードに失敗しました'
          )
        );
      }
    }
  );

export const updateDataPackage = () =>
  withLoading(
    'dataPackageSlice.updateDataPackage',
    async (dispatch, getState) => {
      const state = getState().dataPackage;

      if (!state.package) {
        throw new Error('unexpected path: package is undefined or null');
      }

      try {
        await axios.patch(
          `${process.env.REACT_APP_API_BASEURL}/v1/console/packages/${state.package.id}`,
          {
            title: state.title,
            packageType: 'data',
          }
        );
      } catch (err) {
        dispatch(setCommonError('パッケージの更新に失敗しました'));
        return;
      }

      try {
        await updateFiles(state.package.id, state.packageFiles);
      } catch (e) {
        dispatch(
          setCommonError(
            'パッケージの更新には成功しましたが、ファイルのアップロードに失敗しました'
          )
        );
      }
    }
  );

const updateFiles = async (packageId: number, packageFiles: PackageFile[]) => {
  // ファイルの追加
  {
    const uploadFormData = new FormData();

    packageFiles.forEach((file) => {
      if (file.uploadingInfo) {
        uploadFormData.append('files', file.uploadingInfo.file);
      }
    });
    await axios.post(
      `${process.env.REACT_APP_API_BASEURL}/v1/console-file/packages/${packageId}/files`,
      uploadFormData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      }
    );
  }

  // ファイルの削除
  {
    const files = packageFiles.filter((f) => f.uploadedInfo && f.toDelete);

    // eslint-disable-next-line no-restricted-syntax
    const promises: Promise<any>[] = files.map((f) =>
      axios.delete(
        `${process.env.REACT_APP_API_BASEURL}/v1/console-file/packages/${packageId}/files/${f.uploadedInfo?.key}`
      )
    );

    await Promise.all(promises);
  }
};

export const dataPackageSlice = createSlice({
  name: 'dataPacakge',
  initialState,
  reducers: {
    updatePackage: (state, payload: PayloadAction<Package | undefined>) => ({
      ...state,
      package: payload.payload,
    }),
    updateTitle: (state, payload: PayloadAction<string | undefined>) => ({
      ...state,
      title: payload.payload || '',
    }),
    updatePackageFiles: (
      state,
      payload: PayloadAction<PackageFile[] | undefined>
    ) => ({
      ...state,
      packageFiles: payload.payload || [],
    }),
    addPackageFile: (state, payload: PayloadAction<PackageFile>) => ({
      ...state,
      packageFiles: [...state.packageFiles, payload.payload],
    }),
    deletePackageFile: (state, payload: PayloadAction<PackageFile>) => {
      let newPackageFiles: PackageFile[] = [];

      const file = payload.payload;

      if (file.uploadedInfo) {
        newPackageFiles = state.packageFiles.map((f) => {
          if (file.name === f.name) {
            return { ...f, toDelete: true };
          }
          return f;
        });
      } else {
        newPackageFiles = state.packageFiles.filter(
          (f) => f.name !== file.name
        );
      }

      return {
        ...state,
        packageFiles: newPackageFiles,
      };
    },
  },
});

export const {
  updatePackage,
  updateTitle,
  updatePackageFiles,
  addPackageFile,
  deletePackageFile,
} = dataPackageSlice.actions;
export default dataPackageSlice.reducer;
