import React from 'react';
import { Table } from 'react-bootstrap';

export type SimpleTablePropTypes = {
  titles: { [key: string]: string };
  values: {
    id: string;
    style?: React.CSSProperties;
    [key: string]: React.ReactNode | string;
  }[];
  styles?: { [key: string]: React.CSSProperties };
};

export default function SimpleTable({
  titles,
  values,
  styles,
}: SimpleTablePropTypes) {
  const keys = Object.keys(titles);

  return (
    <Table responsive>
      <thead>
        <tr>
          {keys.map((key) => (
            <th key={key}>{titles[key]}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((row) => (
          <tr key={row.id} style={row.style}>
            {keys.map((key) => (
              <td key={key} style={styles ? styles[key] : {}}>
                {row[key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

SimpleTable.defaultProps = {
  styles: {},
};
