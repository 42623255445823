import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkSession, selectSessionStatus } from './bocommonSlice';

export default function BOAuth({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionStatus = useSelector(selectSessionStatus);

  useLayoutEffect(() => {
    dispatch(checkSession());
  }, []);

  if (sessionStatus === 'LOGOUT') {
    history.push('/bo/login');
  }

  if (sessionStatus === 'CHECKING') {
    return <></>;
  }

  return <>{children}</>;
}
