import moment from 'moment';
import Cookies from 'universal-cookie';

// eslint-disable-next-line import/prefer-default-export
export function formatJSONDate(jsonDate?: string): string {
  if (!jsonDate) {
    return '';
  }
  const date = moment(jsonDate);
  return date.format('YYYY-MM-DD HH:mm:ss');
}

export function parseJSONDate(jsonDate?: string): Date {
  return moment(jsonDate).toDate();
}

// eslint-disable-next-line import/prefer-default-export
export function formatJSONDateYMD(jsonDate: string): string {
  const date = moment(jsonDate);
  return date.format('YYYY-MM-DD');
}

export function uniq<T>(arr: T[]): T[] {
  const uniqued: T[] = [];
  arr.forEach((elem) => {
    if (uniqued.indexOf(elem) < 0) {
      uniqued.push(elem);
    }
  });
  return uniqued;
}

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function getAppDownloadUrl(packageId: number, fileId: string) {
  return `${process.env.REACT_APP_API_BASEURL}/v1/app-file/packages/${packageId}/files/${fileId}`;
}

export function getBODeathCertificateDownloadUrl(userId: number) {
  return `${process.env.REACT_APP_API_BASEURL}/v1/bo-file/users/${userId}/deathcertificate`;
}

export function getIsMaster(): boolean {
  const cookies = new Cookies();
  const ret = cookies.get('auth-is-master');
  return ret === 'true';
}

type GroupType = 'NORMAL' | 'CELEMONY';

export function getGroupType(): GroupType {
  const cookies = new Cookies();
  return cookies.get('auth-group-type');
}

export function abbreviateString(text: string, length: number): string {
  if (text.length < length) {
    return text;
  }

  return `${text.substring(0, length)}...`;
}

export const isValidStringId = /^[a-zA-Z0-9_-]{4,20}$/;
export const isValidPassword = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z!:-@[-`{-~\d]{8,20}$/;
