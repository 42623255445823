import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import { selectCreateCompanyResponse } from './bocompaniesSlice';

export default function BOCompanyAddCompletePage() {
  // boilerplate
  const history = useHistory();

  // state
  const createCompanyResponse = useSelector(selectCreateCompanyResponse);

  // callbacks
  const onBackClick = () => {
    history.push(`/bo/companies`);
  };

  if (!createCompanyResponse) {
    return (
      <BOPage>
        <BOPageTitle>企業グループ追加完了</BOPageTitle>
        <BOPageBody>不正な遷移です。</BOPageBody>
      </BOPage>
    );
  }

  return (
    <BOPage>
      <BOPageTitle>企業グループ追加完了</BOPageTitle>
      <BOPageBody>
        <div className="mb-3">
          企業グループの追加を完了しました。
          <br />
          以下の情報をメモしてください。
        </div>

        <div className="mb-3">
          <Container>
            <Row>
              <Col md={2} lg={2}>
                企業グループ名
              </Col>
              <Col md={5} lg={5}>
                {createCompanyResponse.group.name}
              </Col>
            </Row>

            <Row>
              <Col md={2} lg={2}>
                tp-admin初期パスワード
              </Col>
              <Col md={5} lg={5}>
                {createCompanyResponse.password}
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Button type="button" variant="secondary" onClick={onBackClick}>
            戻る
          </Button>
        </div>
      </BOPageBody>
    </BOPage>
  );
}
