/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { ApplicationError } from '../../../Errors';

export interface ArPackage {
  // 作成時に指定
  title: string;

  // 後で設定
  force_start_scenario_id: string | null; // 初期値はnull, なしの場合はnull
  scenarios: Scenario[];
}

export interface ArPackageAnswer {
  user: {
    name: string;
    email: string;
  };
  answer: {
    id: number;
    contents: {
      summary: string;
      detail: any;
    };
    createdAt: string;
  };
}

export enum MarkerType {
  MARKER_ENABLED = 0,
  MARKER_LESS = 1,
}
export function markerTypeToString(mt: MarkerType) {
  switch (mt) {
    case MarkerType.MARKER_ENABLED:
      return 'マーカー有り';
    case MarkerType.MARKER_LESS:
      return 'マーカーレス';
    default:
      throw new Error('invalid marker type');
  }
}

export interface Scenario {
  // 作成時に指定
  title: string;
  avator: Avator;

  // システムで採番
  scenario_id: string;

  // 後で設定
  local_position: Vector3; // 初期値は (0,0,0)
  local_rotation: Vector3; // 初期値は (0,0,0)
  marker: Marker | null; // 設定なしの場合はnull
  marker_scale: number; // 初期値は1 → UIに入れていなかったかも。。
  marker_type: MarkerType; // 初期値は MarkerType.MARKER_ENABLED
  messages: Message[];
}

export function createDefaultScenario(
  title: string,
  avator: Avator,
  scenario_id: string
): Scenario {
  return {
    title,
    avator,
    scenario_id,
    local_position: createDefaultVector3(),
    local_rotation: createDefaultVector3(),
    marker: null,
    marker_scale: 1,
    marker_type: MarkerType.MARKER_ENABLED,
    messages: [],
  };
}

export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export function createDefaultVector3() {
  return { x: 0, y: 0, z: 0 };
}

export interface Marker {
  image_url: string;
  image_key: string;
}

export interface Avator {
  name_id: string;
}

export interface Message {
  // 作成時に指定
  title: string;

  // システムで採番
  message_id: string;

  // 後で設定
  voices: VoiceContent[];
  choices: ChoiceContent[];
  image_on_heads: ImageOnHeadContent[];
  video_on_bodies: VideoOnBodyContent[];
  fullscreen_videos: FullScreenVideoContent[];
  notes: NoteContent[];
  jump_to_messages: JumpToMessageContent[];
  inputs: InputContent[];
  animation_states: AnimationStateContent[];
}

export function createDefaultMessage(
  title: string,
  messages: Message[]
): Message {
  const message_id = (
    Math.max(0, ...messages.map((m) => Number.parseInt(m.message_id, 10))) + 1
  ).toString();
  return {
    title,

    message_id,

    voices: [],
    choices: [],
    image_on_heads: [],
    video_on_bodies: [],
    fullscreen_videos: [],
    notes: [],
    jump_to_messages: [],
    inputs: [],
    animation_states: [],
  };
}

export interface Content {
  order: number;
}

export interface VoiceContent extends Content {
  audio_url: string;
  subtitles: Subtitle[];
}
export interface Subtitle {
  time_from: number;
  time_to: number;
  body: string;
}

export interface ChoiceContent extends Content {
  choices: Choice[];
}
export interface Choice {
  label: string;
  message_id?: string;
  link_url?: string;
}

export interface ImageOnHeadContent extends Content {
  image: Image;
}

export interface Image {
  image_url: string;
}

export interface VideoOnBodyContent extends Content {
  video_url: string;
}

export interface FullScreenVideoContent extends Content {
  video_url: string;
  message_id: string;
}

export interface NoteContent extends Content {
  image: Image | null;
  body: string;
}

export interface JumpToMessageContent extends Content {
  message_id: string;
}

export interface InputContent extends Content {
  title: string;
}

export interface AnimationStateContent extends Content {
  state: string;
}

/**
 * Content Wrapper
 */

export enum ContentType {
  Voice = '音声再生',
  Choice = '選択肢表示',
  ImageOnHead = '静止画再生',
  VideoOnBody = '動画再生',
  FullScreenVideo = '動画再生（フルスクリーン）',
  Note = 'ノート表示',
  JumpToMessage = 'メッセージに移動',
  Input = '文字列入力',
  AnimationState = 'ARアバターアニメーション',
}

export interface ContentWrapper {
  typ: ContentType;
  content: Content;
}

export function setContentWrappersToMessage(
  m: Message,
  cws: ContentWrapper[]
): Message {
  const newMessage: Message = {
    ...m,
    voices: [],
    choices: [],
    image_on_heads: [],
    video_on_bodies: [],
    fullscreen_videos: [],
    notes: [],
    jump_to_messages: [],
    inputs: [],
    animation_states: [],
  };

  let i = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const cw of cws) {
    const newContent: Content = {
      ...cw.content,
      order: i,
    };

    switch (cw.typ) {
      case ContentType.Voice:
        newMessage.voices.push(newContent as VoiceContent);
        break;
      case ContentType.Choice:
        newMessage.choices.push(newContent as ChoiceContent);
        break;
      case ContentType.ImageOnHead:
        newMessage.image_on_heads.push(newContent as ImageOnHeadContent);
        break;
      case ContentType.VideoOnBody:
        newMessage.video_on_bodies.push(newContent as VideoOnBodyContent);
        break;
      case ContentType.FullScreenVideo:
        newMessage.fullscreen_videos.push(newContent as FullScreenVideoContent);
        break;
      case ContentType.Note:
        newMessage.notes.push(newContent as NoteContent);
        break;
      case ContentType.JumpToMessage:
        newMessage.jump_to_messages.push(newContent as JumpToMessageContent);
        break;
      case ContentType.Input:
        newMessage.inputs.push(newContent as InputContent);
        break;
      case ContentType.AnimationState:
        newMessage.animation_states.push(newContent as AnimationStateContent);
        break;
      default:
        throw new Error('unknown type');
    }

    i += 1;
  }

  return newMessage;
}

export function extractContentWrappersFromMessage(
  m: Message
): ContentWrapper[] {
  const ret: ContentWrapper[] = [];

  m.voices.forEach((c) => {
    ret.push({
      typ: ContentType.Voice,
      content: c,
    });
  });

  m.choices.forEach((c) => {
    ret.push({
      typ: ContentType.Choice,
      content: c,
    });
  });

  m.image_on_heads.forEach((c) => {
    ret.push({
      typ: ContentType.ImageOnHead,
      content: c,
    });
  });

  m.video_on_bodies.forEach((c) => {
    ret.push({
      typ: ContentType.VideoOnBody,
      content: c,
    });
  });

  m.fullscreen_videos.forEach((c) => {
    ret.push({
      typ: ContentType.FullScreenVideo,
      content: c,
    });
  });

  m.notes.forEach((c) => {
    ret.push({
      typ: ContentType.Note,
      content: c,
    });
  });

  m.jump_to_messages.forEach((c) => {
    ret.push({
      typ: ContentType.JumpToMessage,
      content: c,
    });
  });

  (m.inputs || []).forEach((c) => {
    ret.push({
      typ: ContentType.Input,
      content: c,
    });
  });

  (m.animation_states || []).forEach((c) => {
    ret.push({
      typ: ContentType.AnimationState,
      content: c,
    });
  });

  ret.sort((a, b) => a.content.order - b.content.order);

  return ret;
}

export function createDefaultContent(typ: ContentType): ContentWrapper {
  switch (typ) {
    case ContentType.Voice: {
      const content: VoiceContent = {
        audio_url: '',
        subtitles: [],
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.Choice: {
      const content: ChoiceContent = {
        choices: [],
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.ImageOnHead: {
      const content: ImageOnHeadContent = {
        image: { image_url: '' },
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.VideoOnBody: {
      const content: VideoOnBodyContent = {
        video_url: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.FullScreenVideo: {
      const content: FullScreenVideoContent = {
        video_url: '',
        message_id: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.Note: {
      const content: NoteContent = {
        image: null,
        body: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.JumpToMessage: {
      const content: JumpToMessageContent = {
        message_id: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.Input: {
      const content: InputContent = {
        title: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    case ContentType.AnimationState: {
      const content: AnimationStateContent = {
        state: '',
        order: 0,
      };
      return {
        typ,
        content,
      };
    }
    default:
      throw new ApplicationError('コンテンツ種別が不正です');
  }
}
