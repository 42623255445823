/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import { getSelectedItem, selectSelectedItem, selectSelectedItemStats } from './itemsSlice';
import SimpleTable, { SimpleTablePropTypes } from '../../components/SimpleTable';

interface ParamTypes {
  id: string;
}

export default function ItemViewerListPage() {
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  const item = useSelector(selectSelectedItem);
  const itemStats = useSelector(selectSelectedItemStats);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedItem(Number.parseInt(id, 10)));
  }, []);

  if (!item) {
    return (
      <Page>
        <PageTitle value="読み込み中" />
      </Page>
    );
  }

  if (!itemStats) {
    throw new Error();
  }

  const tableProps: SimpleTablePropTypes = {
    titles: {
      id: '#',
      name: '氏名',
      email: 'メールアドレス',
    },
    values: itemStats.viewers.map((viewer, i) => ({
      id: (i + 1).toString(),
      name: viewer.name,
      email: viewer.email,
    })),
  };

  return (
    <Page>
      <PageTitle value="アイテム統計 - ユーザ一覧" />
      <PageBody loading={!item}>
        <SimpleTable {...tableProps} />
        <Button onClick={() => history.push(`/items/${id}`)}>戻る</Button>
      </PageBody>
    </Page>
  );
}
