import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { BsCheckCircle, BsExclamationTriangle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { setCommonError } from '../features/common/commonSlice';

export default function SingleFileUpload(props: {
  title?: string;
  noUploadText?: string;
  uploadedText?: string;
  isUploaded?: boolean;
  showIcon?: boolean;
  onUpload: (_file: File) => any;
  allowedMimeTypes?: string[];
}) {
  const dispatch = useDispatch();

  const {
    title,
    noUploadText,
    uploadedText,
    isUploaded,
    onUpload,
    showIcon,
    allowedMimeTypes,
  } = props;

  const fileRef = React.createRef<HTMLInputElement>();

  const onClick = () => {
    if (fileRef.current?.files) {
      const file = fileRef.current.files.item(0);

      if (!allowedMimeTypes?.includes(file?.type || '')) {
        dispatch(setCommonError('許可されていないファイルタイプです'));
        return;
      }

      if ((file?.size || Number.MAX_SAFE_INTEGER) > 200 * 1024 * 1024) {
        dispatch(setCommonError('許可されているファイルサイズは200MBまでです'));
        return;
      }

      if (file) {
        onUpload(file);
      }
      fileRef.current.value = '';
    }
  };

  return (
    <Form.Group className="mb-2">
      <Row>
        {title && (
          <Form.Label column md={3} lg={2}>
            {title}
          </Form.Label>
        )}
        <Col>
          {!isUploaded && (
            <div className="mb-2">
              {showIcon && (
                <>
                  <BsExclamationTriangle
                    style={{ verticalAlign: 'middle' }}
                    color="red"
                  />{' '}
                </>
              )}

              {noUploadText}
            </div>
          )}
          {isUploaded && (
            <div className="mb-2">
              {showIcon && (
                <>
                  <BsCheckCircle
                    style={{ verticalAlign: 'middle' }}
                    color="green"
                  />{' '}
                </>
              )}
              {uploadedText}
            </div>
          )}
          <Row>
            <Col>
              <Form.Control type="file" ref={fileRef} />
            </Col>
            <Col>
              <Button type="button" onClick={onClick}>
                アップロード
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

SingleFileUpload.defaultProps = {
  title: undefined,
  uploadedText: undefined,
  noUploadText: undefined,
  showIcon: false,
  isUploaded: false,
  allowedMimeTypes: [
    'image/jpeg',
    'image/png',
    'video/mp4',
    'audio/mpeg',
    'audio/m4a',
    'audio/wav',
    'audio/x-wav',
  ],
};
