import { configureStore } from '@reduxjs/toolkit';
import commonReducer from '../features/common/commonSlice';
import itemsReducer from '../features/items/itemsSlice';
import usersReducer from '../features/users/usersSlice';
import dataPackageReducer from '../features/data-package/dataPackageSlice';
import arPackageReducer from '../features/ar-package/arPackageSlice';
import addressReducer from '../features/address/addressSlice';
import sendItemRequestReducer from '../features/send-item-request/sendItemRequestSlice';
import appUsersReducer from '../features/app-users/appUsersSlice';
import bocommonReducer from '../bo/features/bocommon/bocommonSlice';
import bocompaniesReducer from '../bo/features/bocompanies/bocompaniesSlice';
import bodeathcertificatesReducer from '../bo/features/bodeathcertificates/bodeathcertificatesSlice';
import boprojectsReducer from '../bo/features/boprojects/boprojectsSlice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    users: usersReducer,
    items: itemsReducer,
    dataPackage: dataPackageReducer,
    arPackage: arPackageReducer,
    address: addressReducer,
    sendItemRequest: sendItemRequestReducer,
    appUsers: appUsersReducer,

    // BO
    bocommon: bocommonReducer,
    bocompanies: bocompaniesReducer,
    bodeathcertificates: bodeathcertificatesReducer,
    boprojects: boprojectsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
