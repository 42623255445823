import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { RootState } from '../../app/store';
import Page from '../../components/Page';
import PageBody from '../common/PageBody';
import PageTitle from '../../components/PageTitle';
import {
  changePassword,
  deleteUser,
  fetchUsers,
  loginAsUser,
  updateDeathDate,
  usersActions,
} from './usersSlice';
import { User } from '../common/models';
import { formatJSONDateYMD, getGroupType, isValidPassword } from '../../util';
import validationErrors from '../../util/validationErrors';
import DeleteModal from '../../components/DeleteModal';

interface ChangePasswordModalPropsType {
  user: User;
  onOk: () => void;
  onCancel: () => void;
}

function ChangePasswordModal({
  user,
  onOk,
  onCancel,
}: ChangePasswordModalPropsType) {
  const dispatch = useDispatch();

  const requiredMessage = validationErrors.required();
  const invalidPasswordMessage = validationErrors.password();

  const { handleSubmit, handleBlur, handleChange, values, touched, errors } =
    useFormik({
      validationSchema: Yup.object().shape({
        password: Yup.string()
          .required(requiredMessage)
          .matches(isValidPassword, invalidPasswordMessage),
      }),
      initialValues: {
        password: '',
      },
      onSubmit: (value) => {
        dispatch(changePassword(user.id, value.password));
        onOk();
      },
    });

  return (
    <Modal show size="lg">
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>パスワード変更</Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Form.Label column md={2}>
              ユーザーID
            </Form.Label>
            <Form.Label column>{user.login_id}</Form.Label>
          </Row>
          <Row className="mb-3">
            <Form.Label column md={2}>
              氏名
            </Form.Label>
            <Form.Label column>{user.user_name}</Form.Label>
          </Row>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                パスワード
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  required
                  type="password"
                  placeholder="パスワード"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
          <Button variant="primary" type="submit">
            保存
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

type EditDeathDateModelPropsType = {
  user: User;
  onCancel: () => void;
};

function EditDeathDateModal({ user, onCancel }: EditDeathDateModelPropsType) {
  const dispatch = useDispatch();
  const fileRef = React.createRef<HTMLInputElement>();

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      deathDate: Yup.date().required('死亡日が未設定です'),
    }),
    initialValues: {
      deathDate: new Date().toISOString().split('T')[0],
      certificate: undefined,
    },
    onSubmit: (value) => {
      let file;
      if (fileRef.current?.files) {
        file = fileRef.current.files.item(0);
      }

      const deathDate = new Date(Date.parse(value.deathDate));
      dispatch(updateDeathDate(user.id, deathDate, file));
    },
  });

  return (
    <Modal show size="lg">
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>死亡日登録</Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Form.Label column md={2}>
              ユーザー名
            </Form.Label>
            <Form.Label column>{user.login_id}</Form.Label>
          </Row>
          <Row className="mb-3">
            <Form.Label column md={2}>
              氏名
            </Form.Label>
            <Form.Label column>{user.user_name}</Form.Label>
          </Row>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                死亡日
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="date"
                  name="deathDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.deathDate}
                  isValid={formik.touched.deathDate && !formik.errors.deathDate}
                  isInvalid={
                    formik.touched.deathDate && !!formik.errors.deathDate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.deathDate}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="file"
              ref={fileRef}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.certificate}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
          <Button variant="primary" type="submit">
            保存
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function UserListPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editDeathDateUser, setEditDeathDateUser] = React.useState<User>();
  const [changePasswordUser, setChangePasswordUser] = React.useState<User>();

  const [deleteModalShowing, setDeleteModalShowing] =
    React.useState<boolean>(false);
  const [deletingUser, setDeletingUser] = React.useState<User | undefined>(
    undefined
  );

  const isLoginAsUserFinished = useSelector(
    (state: RootState) => state.users.isLoginAsUserFinished
  );
  const isDeathDateUpdated = useSelector(
    (state: RootState) => state.users.isDeathDateUpdated
  );

  useEffect(() => {
    // ユーザー一覧を取得する
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (isLoginAsUserFinished) {
      // ログイン通知フラグをもとに戻す
      dispatch(usersActions.setIsLoginAsUserFinished(false));
      // 代理ログインが完了したらアイテム一覧ぺーじに飛ばす
      history.push('/items');
    }
  }, [isLoginAsUserFinished]);

  useEffect(() => {
    if (isDeathDateUpdated) {
      dispatch(usersActions.setIsDeathDateUpdated(false));
      dispatch(fetchUsers());
      setEditDeathDateUser(undefined);
    }
  }, [isDeathDateUpdated]);

  function onDeleteClicked(user: User) {
    setDeletingUser(user);
    setDeleteModalShowing(true);
  }

  async function onDeleteConfirmed() {
    setDeleteModalShowing(false);

    if (deletingUser) {
      await dispatch(deleteUser(deletingUser.id));
      await dispatch(fetchUsers());
    }
  }

  function onDeleteCancelled() {
    setDeleteModalShowing(false);
  }

  function onEditDeathDateClicked(user: User) {
    setEditDeathDateUser(user);
  }

  function onChangePasswordClicked(user: User) {
    setChangePasswordUser(user);
  }

  function onLoginAsUserClicked(user: User) {
    dispatch(loginAsUser(user.id));
  }

  async function onDownloadDeathCertificate(user: User) {
    const res = await axios.get<ArrayBuffer>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console-file/users/${user.id}/deathcertificate`,
      {
        responseType: 'arraybuffer',
      }
    );
    const blob = new Blob([res.data]);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `死亡証明書_${user.login_id}.pdf`);

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);
  }

  function onCancelDeathDate() {
    setEditDeathDateUser(undefined);
  }

  function onOkChangePassword() {
    setChangePasswordUser(undefined);
  }

  function onCancelChangePassword() {
    setChangePasswordUser(undefined);
  }

  const users = useSelector((state: RootState) => state.users.users);
  const usersView = users.map((user) => (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>{user.login_id}</td>
      <td>{user.user_name}</td>
      {(() => {
        if (getGroupType() !== 'CELEMONY') {
          return <></>;
        }

        if (!user.death_date) {
          return (
            <td colSpan={2}>
              {user.is_master ? (
                <span>-</span>
              ) : (
                <Button size="sm" onClick={() => onEditDeathDateClicked(user)}>
                  設定
                </Button>
              )}
            </td>
          );
        }

        return (
          <>
            <td>
              <Row>
                <Col>
                  {user.death_date ? formatJSONDateYMD(user.death_date) : ''}
                </Col>
                <Col>
                  {user.is_master ? (
                    <span>-</span>
                  ) : (
                    <Button
                      size="sm"
                      onClick={() => onEditDeathDateClicked(user)}
                    >
                      設定
                    </Button>
                  )}
                </Col>
              </Row>
            </td>
            <td>
              <Button
                size="sm"
                onClick={() => onDownloadDeathCertificate(user)}
              >
                ダウンロード
              </Button>
            </td>
          </>
        );
      })()}
      <td>
        {user.is_master ? (
          <span>-</span>
        ) : (
          <Button size="sm" onClick={() => onLoginAsUserClicked(user)}>
            代理ログイン
          </Button>
        )}
      </td>
      <td>
        <Button size="sm" onClick={() => onChangePasswordClicked(user)}>
          パスワード変更
        </Button>
      </td>
      <td>
        {user.is_master ? (
          <span>-</span>
        ) : (
          <Button
            size="sm"
            variant="danger"
            onClick={() => onDeleteClicked(user)}
          >
            削除
          </Button>
        )}
      </td>
    </tr>
  ));

  return (
    <Page>
      <DeleteModal
        objectName="ユーザ（関連付けされたアイテム等全てのオブジェクトを含む）"
        show={deleteModalShowing}
        onDeleteClicked={onDeleteConfirmed}
        onCloseClicked={onDeleteCancelled}
      />
      {(() => {
        if (!editDeathDateUser) {
          return <></>;
        }
        return (
          <EditDeathDateModal
            user={editDeathDateUser}
            onCancel={onCancelDeathDate}
          />
        );
      })()}
      {(() => {
        if (!changePasswordUser) {
          return <></>;
        }
        return (
          <ChangePasswordModal
            user={changePasswordUser}
            onOk={onOkChangePassword}
            onCancel={onCancelChangePassword}
          />
        );
      })()}
      <PageTitle value="コンソールユーザー" />
      <PageBody>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>ユーザー名</th>
              <th>氏名</th>
              {(() => {
                if (getGroupType() !== 'CELEMONY') {
                  return <></>;
                }

                return (
                  <>
                    <th>死亡日</th>
                    <th>死亡証明書</th>
                  </>
                );
              })()}
              <th>代行ログイン</th>
              <th>パスワード変更</th>
              <th>削除</th>
            </tr>
          </thead>
          <tbody>{usersView}</tbody>
        </Table>
      </PageBody>
    </Page>
  );
}
