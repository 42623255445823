/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

/**
 * TimePocket コンソールユーザー
 */
export interface User {
  id: number;
  group_id: number;
  login_id: string;
  user_name: string;
  email: string;
  death_date: string;
  is_master: boolean;
  requires_pii: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Group {
  name: string;
}

export interface Item {
  id: number;
  title: string;
  visible: boolean;
  allow_name_search: boolean;
  expires_at: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface ItemCode {
  id: number;
  item_id: number;
  item_code: string;
  createdAt: string;
  updatedAt: string;
}

export interface ItemStats {
  id: number;
  item_id: number;
  viewed_count: number;
  subscribers: {
    name: string;
    email: string;
  }[];
  viewers: {
    name: string;
    email: string;
  }[];
  createdAt: string;
  updatedAt: string;
}

export interface ItemNotification {
  id: number;
  item_id: number;
  contents: { text: string };
  createdAt: string;
  updatedAt: string;
}

export interface Package {
  id: number;
  item_id: number;
  package_type: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

export interface Address {
  id: number;
  name: string;
  email: string;
  address: string;
  createdAt: string;
  updatedAt: string;
}

export interface SendItemRequest {
  id?: number;
  status?: string;
  send_method: string;
  send_condition: string;
  send_date?: string | undefined;
  send_days_after_death?: number | undefined;
  sent_date?: string | undefined;
  item_id: number;
  address_id?: number;
  app_user_id?: number;
  createdAt?: string;
  updatedAt?: string;
  Address?: Address;
  Item?: Item & {
    ConsoleUser: User;
    ItemCode: ItemCode;
  };
  AppUser?: AppUser;
}

export function showSendMethod(req: SendItemRequest): string {
  switch (req.send_method) {
    case 'EMAIL':
      return 'メール';
    case 'POST':
      return '郵送';
    case 'PUSH':
      return 'プッシュ通知';
    default:
      return '不明';
  }
}

type SendItemRequestStatus = 'BEFORE_DATE' | 'PENDING' | 'COMPLETED';

export interface SendItemRequestWrapper {
  current_status: SendItemRequestStatus;
  entity: SendItemRequest;
}

export interface AppUser {
  id: number;
  email: string;
  name: string;
  pii: null | {
    fullname: string;
    fullname_kana: string;
    address: string;
    phone_number: string;
    age: number;
    gender: string;
  };
  created_at: string;
}
