import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  setCommonError,
  withLoading,
} from '../../../features/common/commonSlice';
import boApiClient, {
  PatchProjectRequest,
  PostProjectRequest,
} from '../bocommon/boApiClient';
import { Project } from '../bocommon/models';

// State
export interface BOProjectsSlice {
  project?: Project;
}

// Initial State
const initialState: BOProjectsSlice = {};

// Selectors
export const selectProject = (state: RootState) => state.boprojects.project;

// Thunks
export const loadProject = (projectId: string) =>
  withLoading('boprojectsSlice.loadProject', async (dispatch) => {
    const res = await boApiClient.getProjects(dispatch);
    const project = res.projects.find((p) => p.id === projectId);
    if (project) {
      dispatch(setProject(project));
    } else {
      dispatch(setCommonError('プロジェクトが存在しません'));
    }
  });

export const saveProject = (project: Project) =>
  withLoading('boprojectsSlice.saveProject', async (dispatch) => {
    const req: PostProjectRequest = {
      id: project.id,
      name: project.name,
      catchphrase: project.catchphrase,
      headerImgKey: project.header_img_key,
    };
    await boApiClient.postProject(dispatch, req);
  });

export const updateProject = (project: Project) =>
  withLoading('boprojectsSlice.updateProject', async (dispatch) => {
    const req: PatchProjectRequest = {
      id: project.id,
      name: project.name,
      catchphrase: project.catchphrase,
      headerImgKey: project.header_img_key,
    };
    await boApiClient.patchProject(dispatch, req);
  });

// Slice
export const boprojectsSlice = createSlice({
  name: 'boprojects',
  initialState,
  reducers: {
    setProject: (state, payload: PayloadAction<Project | undefined>) => ({
      ...state,
      project: payload.payload,
    }),
  },
});

// Exports
export const { setProject } = boprojectsSlice.actions;
export default boprojectsSlice.reducer;
