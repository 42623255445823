/* eslint-disable react/jsx-props-no-spreading */
import moment from 'moment';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import SimpleTable, { SimpleTablePropTypes } from '../../components/SimpleTable';
import { formatJSONDate } from '../../util';
import { refreshSendItemRequests } from '../common/commonSlice';
import { SendItemRequest, SendItemRequestWrapper } from '../common/models';
import PageBody from '../common/PageBody';
import {
  completeSendItemRequest,
  selectSendItemRequests,
  updateSendItemRequests,
} from '../send-item-request/sendItemRequestSlice';

function isExpired(sendItemRequest: SendItemRequest) {
  let sendDate;
  if (sendItemRequest.send_condition === 'DATE') {
    sendDate = moment(sendItemRequest.send_date).toDate();
  }
  if (sendItemRequest.send_condition === 'AFTER_DEATH_DAY') {
    const deathDateValue = sendItemRequest.Item?.ConsoleUser.death_date;
    if (!deathDateValue) {
      return false;
    }

    const deathDate = moment(deathDateValue).toDate();
    sendDate = new Date(deathDate.getTime() + (sendItemRequest.send_days_after_death || 0) * 24 * 60 * 60 * 1000);
  }
  if (!sendDate) {
    throw new Error(`不正な送信条件: ${sendItemRequest.send_condition}`);
  }

  return sendDate.getTime() < new Date().getTime();
}

export default function SendingListPage() {
  // boilerplates
  const dispatch = useDispatch();

  // states
  const sendItemRequests = useSelector(selectSendItemRequests);

  // init view
  useEffect(() => {
    dispatch(updateSendItemRequests());
  }, []);

  async function onCompleteClicked(sendItemRequest: SendItemRequest) {
    dispatch(completeSendItemRequest(sendItemRequest));
    dispatch(refreshSendItemRequests());
  }

  // table
  const tableProps: SimpleTablePropTypes = {
    titles: {
      itemTitle: 'アイテム名',
      itemCode: 'アイテムコード',
      itemURL: 'アイテムURL',
      senderName: '送信者氏名',
      addressName: '宛先氏名',
      sendMethod: '送信方法',
      address: '宛先',
      sendDate: '送信予定日時',
      operation: '操作',
    },
    values: (sendItemRequests || [])
      .filter((req) => ['EMAIL', 'POST'].includes(req.entity.send_method))
      .sort((req) => {
        if (req.current_status === 'PENDING') return -1;
        return 0;
      })
      .map((req) => ({
        style: {
          backgroundColor: req.current_status !== 'COMPLETED' && isExpired(req.entity) ? '#ffe3e3' : '#ffffff',
        },
        id: req.entity.id!.toString(),
        itemTitle: req.entity.Item!.title,
        itemCode: req.entity.Item!.ItemCode.item_code,
        itemURL: (() => {
          const itemURL = `https://timepocket.com/item?code=${req.entity.Item!.ItemCode.item_code}`;
          return <a href={itemURL}>{itemURL}</a>;
        })(),
        senderName: req.entity.Item!.ConsoleUser.user_name,
        addressName: req.entity.Address!.name,
        sendMethod: req.entity.send_method === 'EMAIL' ? 'メール' : '郵送',
        address: req.entity.send_method === 'EMAIL' ? req.entity.Address?.email : req.entity.Address?.address,
        sendDate: ((r: SendItemRequestWrapper) => {
          if (r.entity.send_condition === 'DATE') {
            return formatJSONDate(r.entity.send_date);
          }
          return `死亡日の${r.entity.send_days_after_death}日後`;
        })(req),
        operation:
          req.current_status === 'COMPLETED' ? (
            <>完了済</>
          ) : (
            <Button size="sm" variant="primary" className="me-2" onClick={() => onCompleteClicked(req.entity)}>
              完了
            </Button>
          ),
      })),
  };

  return (
    <Page>
      <PageTitle value="メール・郵送管理" />
      <PageBody loading={!sendItemRequests}>
        <SimpleTable {...tableProps} />
      </PageBody>
    </Page>
  );
}
