import React, { useEffect } from 'react';
import { Button, Navbar, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
import { loadUser, logout, selectGroup, selectUser } from './commonSlice';
import './Header.scss';

export default function Header() {
  const dispatch = useDispatch();
  // const history = useHistory();

  const cookies = new Cookies();
  const authorized = !!cookies.get('auth-token');

  const execLogout = async () => {
    await dispatch(logout());

    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.href = '/login';
    }, 500);
  };

  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);
  useEffect(() => {
    if (authorized) {
      dispatch(loadUser());
    }
  }, [authorized]);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">Timepocket Console</Navbar.Brand>

        {authorized ? (
          <Navbar className="justify-content-end">
            {user && group && (
              <span className="me-2 userName">
                {group.name} / {user.user_name}
              </span>
            )}
            <Button variant="light" onClick={execLogout}>
              ログアウト
            </Button>
          </Navbar>
        ) : null}
      </Container>
    </Navbar>
  );
}
