import React from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line func-names
export const Auth: React.FC<Props> = function (props: Props) {
  const { children } = props;
  const history = useHistory();

  const cookies = new Cookies();
  const authToken = cookies.get('auth-token');
  if (!authToken) {
    history.push('/login');
    return <div />;
  }

  return <div>{children}</div>;
};

export default Auth;
