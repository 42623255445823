import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getIsMaster } from '../util';
import { refreshSendItemRequests } from '../features/common/commonSlice';

interface MenuItem {
  name: string;
  linkTo: string;
  onlyMaster?: boolean | undefined;
}

export default function Sidebar() {
  const isMaster = useSelector(getIsMaster);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMaster) {
      dispatch(refreshSendItemRequests());
    }
  }, [location]);

  const menuItems: MenuItem[] = [
    { name: 'アイテム一覧', linkTo: '/items' },
    { name: '送信宛先管理', linkTo: '/addresses' },
    { name: 'アイテム送信', linkTo: '/senditemrequest' },
    { name: 'アプリユーザー', linkTo: '/appusers' },
    { name: 'ユーザ設定', linkTo: '/users/userinfo' },
    { name: 'メール・郵送管理', linkTo: '/sendings', onlyMaster: true },
    { name: 'コンソールユーザー', linkTo: '/users', onlyMaster: true },
  ];

  const menuViews = menuItems.map((item) => {
    if (item.onlyMaster && !isMaster) {
      return <div key={item.name} />;
    }

    return (
      <Nav.Item key={item.name}>
        <Nav.Link as={NavLink} to={item.linkTo}>
          {item.name}
        </Nav.Link>
      </Nav.Item>
    );
  });

  return (
    <Nav className="flex-column">
      {menuViews}
      {process.env.REACT_APP_MODE !== 'dev' ? null : (
        <div key="" className="fs-6 fw-light">
          [debug info]
          <br />
          version: {process.env.REACT_APP_VERSION}
        </div>
      )}
    </Nav>
  );
}
