import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
// import _ from 'lodash';
import { ContentsPropTypes } from './ContentPropTypes';
// import validationErrors from '../../../util/validationErrors';
import { InputContent } from '../../common/models/ArPackage';

export default function InputComponent({
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as InputContent;

  // boilerplate
  // const dispatch = useDispatch();

  // state

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string().required('タイトルが未設定です'),
    }),
    initialValues: {
      title: c.title,
    },
    onSubmit: (val) => {
      const newContent: InputContent = {
        ...c,
        title: val.title,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={2}>
              タイトル
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="text"
                name="title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                isValid={formik.touched.title && !formik.errors.title}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />
            </Col>
          </Row>
        </Form.Group>
        <Alert className="mt-3" variant="danger" show={!!formik.errors.title}>
          {formik.errors.title}
        </Alert>

        <div className="mt-5">
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
