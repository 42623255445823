import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../app/store';
import { login, setIsLoginFinished } from '../common/commonSlice';

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoginFinished = useSelector(
    (state: RootState) => state.common.isLoginFinished
  );

  useEffect(() => {
    if (isLoginFinished) {
      dispatch(setIsLoginFinished(false));
      history.push('/items');
    }
  }, [isLoginFinished]);

  const [validated, setValidated] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity()) {
      await dispatch(login(groupId, loginId, password));
    }
  };

  const testLogin = (gid: number, uid: number) => {
    // eslint-disable-next-line no-shadow
    const groupId = `group${gid}`;
    const userId = `user${uid}`;
    // eslint-disable-next-line no-shadow
    const password = `Password${uid}`;

    (document.getElementsByName('groupId')[0] as HTMLInputElement).value =
      groupId;
    (document.getElementsByName('userName')[0] as HTMLInputElement).value =
      userId;
    (document.getElementsByName('password')[0] as HTMLInputElement).value =
      password;
    setGroupId(groupId);
    setLoginId(userId);
    setPassword(password);
    setTimeout(() => {
      document.getElementById('loginButton')?.click();
    }, 100);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="mt-3">
            <Card.Header>ログイン</Card.Header>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Form.Group className="mb-2">
                  <Row>
                    <Form.Label column lg={3}>
                      企業ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        required
                        type="text"
                        placeholder="企業ID"
                        name="groupId"
                        onChange={(e) => setGroupId(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        入力が必須です
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Row>
                    <Form.Label column lg={3}>
                      ユーザ名
                    </Form.Label>
                    <Col>
                      <Form.Control
                        required
                        type="text"
                        name="userName"
                        placeholder="ユーザ名"
                        onChange={(e) => setLoginId(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        入力が必須です
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label column lg={3}>
                      パスワード
                    </Form.Label>
                    <Col>
                      <Form.Control
                        required
                        type="password"
                        placeholder="パスワード"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        入力が必須です
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <div className="text-center">
                  <div className="mb-2">
                    <Button variant="primary" type="submit" id="loginButton">
                      ログイン
                    </Button>
                  </div>
                  {process.env.REACT_APP_MODE !== 'prod' &&
                    process.env.REACT_APP_MODE !== 'stg' && (
                      <>
                        <div className="mb-2">
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => testLogin(1, 1)}
                          >
                            テスト用アカウントログイン（Afan_Admin）
                          </Button>
                        </div>
                        <div className="mb-2">
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => testLogin(2, 2)}
                          >
                            テスト用アカウントログイン（Giravanz_Admin）
                          </Button>
                        </div>
                        <div className="mb-2">
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => testLogin(3, 5)}
                          >
                            テスト用アカウントログイン（Denshobato_Admin）
                          </Button>
                        </div>
                        <div className="mb-2">
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => testLogin(3, 6)}
                          >
                            テスト用アカウントログイン（Denshobato_Normal）
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              </Form>
            </Card.Body>
            <Container>
              <p>
                <Link to="/users/new">新規登録</Link>
              </p>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
