import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { setCommonError, withLoading } from '../common/commonSlice';
import { AppUser } from '../common/models';

// State
export interface AppUsersState {
  appUsers: AppUser[];
}

const initialState: AppUsersState = {
  appUsers: [],
};

// Selector
export const selectAppUsers = (state: RootState) => state.appUsers.appUsers;

// Thunks
type FetchAppUsersAction = ThunkAction<void, RootState, unknown, AnyAction>;
export const fetchAppUsers = (): FetchAppUsersAction =>
  withLoading('appUsersSlice.fetchAppUsers', async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASEURL}/v1/console/users/me/app_users_subscribing`;
      const res = await axios.get(url);

      dispatch(appUsersSlice.actions.updateAppUsers({ appUsers: res.data.appUsers }));
    } catch {
      dispatch(setCommonError('ユーザ一覧の取得に失敗しました'));
    }
  });

// Slice
const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {
    updateAppUsers: (state, payload: PayloadAction<{ appUsers: AppUser[] }>) => ({
      ...state,
      appUsers: payload.payload.appUsers,
    }),
  },
});

// Exports
export const appUsersActions = appUsersSlice.actions;
export default appUsersSlice.reducer;
