import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import { addItem } from './itemsSlice';
import { parseJSONDate } from '../../util';

export default function ItemAddPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [allowNameSearch, setAllowNameSearch] = useState(false);
  const [itemCodeLight, setItemCodeLight] = useState(false);
  const [expiresAt, setExpiresAt] = useState('');

  const onCancelClicked = () => {
    history.push('/items');
  };

  const onSubmit = async (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity()) {
      const expiresAtParsed = expiresAt === '' ? null : parseJSONDate(expiresAt);
      await dispatch(addItem(title, visible, allowNameSearch, itemCodeLight, expiresAtParsed));
      history.push('/items');
    }
  };

  const setVisibleHandler = (e: any) => {
    setVisible(e.target.checked);
  };

  const setAllowNameSearchHandler = (e: any) => {
    setAllowNameSearch(e.target.checked);
  };

  const setItemCodeLightHandler = (e: any) => {
    setItemCodeLight(e.target.checked);
  };

  return (
    <Page>
      <PageTitle value="アイテム追加" />
      <PageBody>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Form.Group className="mb-2" controlId="validationcustom">
            <Form.Label>アイテム名</Form.Label>
            <Form.Control required type="text" placeholder="アイテム名" onChange={(e) => setTitle(e.target.value)} />
            <Form.Control.Feedback type="invalid">入力が必須です</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2" controlId="validationcustom">
            <Form.Check type="checkbox" onChange={setVisibleHandler} label="全体に公開" />
          </Form.Group>
          <Form.Group className="mb-2" controlId="validationcustom">
            <Form.Check type="checkbox" onChange={setAllowNameSearchHandler} label="検索を許可" />
          </Form.Group>
          <Form.Group className="mb-2" controlId="validationcustom">
            <Form.Check
              type="checkbox"
              onChange={setItemCodeLightHandler}
              label="簡易アイテムコード（４桁）を使用する"
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="validationcustom">
            <Form.Label>有効期限</Form.Label>
            <Form.Control
              pattern="\d\d\d\d-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}"
              type="text"
              placeholder="例）2022-01-02 11:20:15 または 空欄"
              onChange={(e) => setExpiresAt(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              空欄か 2022-01-02 11:20:15 の形式で入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            追加
          </Button>{' '}
          <Button variant="secondary" type="button" onClick={onCancelClicked}>
            キャンセル
          </Button>
        </Form>
      </PageBody>
    </Page>
  );
}
