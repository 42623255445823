import React, { useEffect } from 'react';
import { FieldArray, FormikProvider, useFormik, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ContentsPropTypes } from './ContentPropTypes';
import validationErrors from '../../../util/validationErrors';
import { VoiceContent } from '../../common/models/ArPackage';
import SingleFileUpload from '../../../components/SingleFileUpload';
import SortableTable from '../../../components/SortableTable';
import { selectUploadedFileId, setUploadedFileId, uploadFile } from '../arPackageSlice';
import { getAppDownloadUrl } from '../../../util';

export default function VoiceComponent({ packageId, content, onSubmitContent, onCancel }: ContentsPropTypes) {
  const c = content as VoiceContent;

  // boilerplate
  const dispatch = useDispatch();

  // state
  const uploadedFileId = useSelector(selectUploadedFileId);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      audio_url: Yup.string(),
      subtitles: Yup.array().of(
        Yup.object().shape({
          time_from: Yup.number().required(validationErrors.required()).typeError(validationErrors.number()),
          time_to: Yup.number().required(validationErrors.required()).typeError(validationErrors.number()),
          body: Yup.string().required(validationErrors.required()),
        })
      ),
    }),
    initialValues: {
      audio_url: c.audio_url,
      subtitles: _.cloneDeep(c.subtitles),
    },
    onSubmit: (val) => {
      const newContent: VoiceContent = {
        ...c,
        audio_url: val.audio_url,
        subtitles: val.subtitles,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  // subutitles view
  const subtitlesView = (
    <FieldArray name="subtitles">
      {(arrayHelpers) => (
        <>
          <div>
            <Button
              variant="primary"
              type="button"
              className="me-2"
              onClick={() => {
                arrayHelpers.push({
                  time_from: 0,
                  time_to: 0,
                  body: '',
                });
              }}
            >
              字幕を追加
            </Button>
          </div>
          <SortableTable
            id="subtitles-table"
            titles={{
              time_from: '開始秒数',
              time_to: '終了秒数',
              body: '内容',
              operation: '操作',
            }}
            styles={{
              time_from: { width: '100px' },
              time_to: { width: '100px' },
            }}
            values={formik.values.subtitles.map((sub, i) => ({
              id: i.toString(),
              time_from: (() => {
                const name = `subtitles.${i}.time_from`;
                return (
                  <>
                    <Form.Control
                      type="number"
                      name={name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={sub.time_from}
                      isValid={getIn(formik.touched, name) && !getIn(formik.errors, name)}
                      isInvalid={getIn(formik.touched, name) && !!getIn(formik.errors, name)}
                    />
                    <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
                  </>
                );
              })(),
              time_to: (() => {
                const name = `subtitles.${i}.time_to`;
                return (
                  <>
                    <Form.Control
                      type="number"
                      name={name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={sub.time_to}
                      isValid={getIn(formik.touched, name) && !getIn(formik.errors, name)}
                      isInvalid={getIn(formik.touched, name) && !!getIn(formik.errors, name)}
                    />
                    <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
                  </>
                );
              })(),
              body: (() => {
                const name = `subtitles.${i}.body`;
                return (
                  <>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name={name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={sub.body}
                      isValid={getIn(formik.touched, name) && !getIn(formik.errors, name)}
                      isInvalid={getIn(formik.touched, name) && !!getIn(formik.errors, name)}
                    />
                    <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
                  </>
                );
              })(),
              operation: (
                <>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(i);
                    }}
                  >
                    削除
                  </Button>
                </>
              ),
            }))}
            onChangeOrder={(src, dst) => {
              arrayHelpers.move(src, dst);
            }}
          />
        </>
      )}
    </FieldArray>
  );

  // callbacks
  const onUploadClicked = async (file: File) => {
    await dispatch(uploadFile(packageId, file));
  };

  useEffect(() => {
    if (uploadedFileId) {
      formik.setFieldValue('audio_url', getAppDownloadUrl(packageId, uploadedFileId));
      dispatch(setUploadedFileId());
    }
  }, [uploadedFileId]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="ms-2 mb-2">
          拡張子 .mp3, .wav のファイルのみ許可されています。ファイルサイズは200MBまでです。
        </div>
        <SingleFileUpload
          title="音声"
          noUploadText="未設定"
          uploadedText="設定済み"
          showIcon
          isUploaded={!!formik.values.audio_url}
          onUpload={onUploadClicked}
          allowedMimeTypes={['audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/m4a']}
        />

        <Form.Group className="mb-3">
          <Row>
            <Form.Label column md={3} lg={2}>
              字幕
            </Form.Label>
          </Row>
          <Row>{subtitlesView}</Row>
        </Form.Group>

        <Button type="submit" variant="primary" className="me-2">
          保存
        </Button>
        <Button type="button" variant="secondary" onClick={onCancel}>
          キャンセル
        </Button>
      </Form>
    </FormikProvider>
  );
}
