import { useFormik } from 'formik';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import {
  ArPackage,
  MarkerType,
  markerTypeToString,
  Scenario,
} from '../common/models/ArPackage';
import PageBody from '../common/PageBody';
import {
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
  uploadMarker,
} from './arPackageSlice';
import validationErrors from '../../util/validationErrors';
import { avators } from '../common/codemasters/Avators';
import SingleFileUpload from '../../components/SingleFileUpload';
import SortableTable from '../../components/SortableTable';
import DeleteModal from '../../components/DeleteModal';

type ParamTypes = {
  itemId: string;
  packageId: string;
  scenarioId: string;
};

export function ScenarioMetadataView(props: {
  value: Scenario;
  onSubmitScenario: (_value: Scenario) => Promise<any>;
}) {
  // parse props
  const { value, onSubmitScenario } = props;

  // local state
  const [editing, setEditing] = useState(false);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
      avatorPositionX: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorPositionY: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorPositionZ: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorRotationX: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorRotationY: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorRotationZ: Yup.number()
        .typeError(validationErrors.number())
        .required(validationErrors.required()),
      avatorNameId: Yup.string().required(),
      markerType: Yup.string().required(),
      // TODO: バグってる。MarkerTypeをマーカーレスにしてもバリデーションが効いてしまう
      markerScale: Yup.number().when('markerType', {
        is: MarkerType.MARKER_ENABLED,
        then: Yup.number()
          .typeError(validationErrors.number())
          .required(validationErrors.required()),
      }),
    }),
    initialValues: {
      title: value.title,
      avatorPositionX: value.local_position.x,
      avatorPositionY: value.local_position.y,
      avatorPositionZ: value.local_position.z,
      avatorRotationX: value.local_rotation.x,
      avatorRotationY: value.local_rotation.y,
      avatorRotationZ: value.local_rotation.z,
      avatorNameId: value.avator.name_id,
      markerType: value.marker_type.toString(),
      markerScale: value.marker_scale,
    },
    onSubmit: async (newValue) => {
      const markerType = Number.parseInt(newValue.markerType, 10);
      const scenario: Scenario = {
        title: newValue.title,
        avator: { name_id: newValue.avatorNameId },
        scenario_id: value.scenario_id,
        local_position: {
          x: newValue.avatorPositionX,
          y: newValue.avatorPositionY,
          z: newValue.avatorPositionZ,
        },
        local_rotation: {
          x: newValue.avatorRotationX,
          y: newValue.avatorRotationY,
          z: newValue.avatorRotationZ,
        },
        marker: markerType === MarkerType.MARKER_ENABLED ? value.marker : null,
        marker_scale: newValue.markerScale,
        marker_type: markerType,
        messages: value.messages,
      };
      await onSubmitScenario(scenario);
      setEditing(false);
    },
    enableReinitialize: true,
  });

  // view components
  const avatorPullDown = (
    <Form.Select
      name="avatorNameId"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.avatorNameId}
      isValid={
        editing && formik.touched.avatorNameId && !formik.errors.avatorNameId
      }
      isInvalid={formik.touched.avatorNameId && !!formik.errors.avatorNameId}
      disabled={!editing}
    >
      {avators.map((avator) => (
        <option key={avator.name_id} value={avator.name_id}>
          {avator.name_id}
        </option>
      ))}
    </Form.Select>
  );

  const markerTypePullDown = (
    <Form.Select
      name="markerType"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.markerType}
      isValid={
        editing && formik.touched.markerType && !formik.errors.markerType
      }
      isInvalid={formik.touched.markerType && !!formik.errors.markerType}
      disabled={!editing}
    >
      <option key={MarkerType.MARKER_ENABLED} value={MarkerType.MARKER_ENABLED}>
        {markerTypeToString(MarkerType.MARKER_ENABLED)}
      </option>
      <option key={MarkerType.MARKER_LESS} value={MarkerType.MARKER_LESS}>
        {markerTypeToString(MarkerType.MARKER_LESS)}
      </option>
    </Form.Select>
  );

  // callbacks
  const onEditClicked = () => {
    setEditing(true);
  };

  const onCancelClicked = (e: any) => {
    formik.handleReset(e);
    setEditing(false);
  };

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-2">
        <Row>
          <Col md={3} lg={2}>
            シナリオID
          </Col>
          <Col md={5}>{value.scenario_id}</Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2}>
            シナリオ名
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="text"
              name="title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              isValid={editing && formik.touched.title && !formik.errors.title}
              isInvalid={formik.touched.title && !!formik.errors.title}
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-2">
        <Col md={3} lg={2}>
          アバター位置
        </Col>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            X
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorPositionX"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorPositionX}
              isValid={
                editing &&
                formik.touched.avatorPositionX &&
                !formik.errors.avatorPositionX
              }
              isInvalid={
                formik.touched.avatorPositionX &&
                !!formik.errors.avatorPositionX
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorPositionX}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            Y
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorPositionY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorPositionY}
              isValid={
                editing &&
                formik.touched.avatorPositionY &&
                !formik.errors.avatorPositionY
              }
              isInvalid={
                formik.touched.avatorPositionY &&
                !!formik.errors.avatorPositionY
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorPositionY}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            Z
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorPositionZ"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorPositionZ}
              isValid={
                editing &&
                formik.touched.avatorPositionZ &&
                !formik.errors.avatorPositionZ
              }
              isInvalid={
                formik.touched.avatorPositionZ &&
                !!formik.errors.avatorPositionZ
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorPositionZ}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-2">
        <Col md={3} lg={2}>
          アバター回転
        </Col>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            X
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorRotationX"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorRotationX}
              isValid={
                editing &&
                formik.touched.avatorRotationX &&
                !formik.errors.avatorRotationX
              }
              isInvalid={
                formik.touched.avatorRotationX &&
                !!formik.errors.avatorRotationX
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorRotationX}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            Y
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorRotationY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorRotationY}
              isValid={
                editing &&
                formik.touched.avatorRotationY &&
                !formik.errors.avatorRotationY
              }
              isInvalid={
                formik.touched.avatorRotationY &&
                !!formik.errors.avatorRotationY
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorRotationY}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2} className="ps-5">
            Z
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="number"
              name="avatorRotationZ"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.avatorRotationZ}
              isValid={
                editing &&
                formik.touched.avatorRotationZ &&
                !formik.errors.avatorRotationZ
              }
              isInvalid={
                formik.touched.avatorRotationZ &&
                !!formik.errors.avatorRotationZ
              }
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.avatorRotationZ}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2}>
            アバター
          </Form.Label>
          <Col md={5}>{avatorPullDown}</Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2}>
            マーカータイプ
          </Form.Label>
          <Col md={5}>{markerTypePullDown}</Col>
        </Row>
      </Form.Group>

      {formik.getFieldProps('markerType').value ===
        MarkerType.MARKER_ENABLED.toString() && (
        <Form.Group className="mb-2">
          <Row>
            <Form.Label column md={3} lg={2}>
              マーカースケール
            </Form.Label>
            <Col md={5}>
              <Form.Control
                type="number"
                name="markerScale"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.markerScale}
                isValid={
                  editing &&
                  formik.touched.markerScale &&
                  !formik.errors.markerScale
                }
                isInvalid={
                  formik.touched.markerScale && !!formik.errors.markerScale
                }
                disabled={!editing}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.markerScale}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
      )}
      {!editing && (
        <>
          <Button type="button" onClick={onEditClicked}>
            編集
          </Button>
        </>
      )}
      {editing && (
        <>
          <Button type="submit" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancelClicked}>
            キャンセル
          </Button>
        </>
      )}
    </Form>
  );
}

export default function ScenarioPage() {
  // boilerplate
  const history = useHistory();
  const dispatch = useDispatch();

  // parse path params
  const { itemId, packageId, scenarioId } = useParams<ParamTypes>();

  // local state
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  // state
  const arPackage = useSelector(selectArPackage);
  const scenario = arPackage?.scenarios.filter(
    (s) => s.scenario_id === scenarioId
  )[0];

  // refresh view
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(Number.parseInt(packageId, 10)));
  }, []);

  // callbacks
  const onBack = () => {
    history.push(`/items/${itemId}/arpackages/${packageId}`);
  };

  const onAddMessageClicked = () => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/add`
    );
  };

  const onUploadMarkerClicked = async (file: File) => {
    if (scenario) {
      await dispatch(
        uploadMarker(Number.parseInt(packageId, 10), scenario, file)
      );
    }
  };

  const onDeleteClicked = () => {
    setDeleteModalShow(true);
  };

  const onDeleteCancelled = () => {
    setDeleteModalShow(false);
  };

  const onDeleteConfirmed = async () => {
    if (!arPackage || !scenario) return;

    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: arPackage.scenarios.filter(
        (sc) => sc.scenario_id !== scenario.scenario_id
      ),
    };

    await dispatch(
      updateArPackage(Number.parseInt(packageId, 10), newArPackage, () => {
        history.push(`/items/${itemId}/arpackages/${packageId}`);
      })
    );
  };

  const onSubmitScenario = async (newScenario: Scenario) => {
    if (!arPackage) {
      return;
    }
    const newScenarios = arPackage.scenarios.map((sc) => {
      if (sc.scenario_id === newScenario.scenario_id) {
        return newScenario;
      }
      return sc;
    });
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(
      updateArPackage(Number.parseInt(packageId, 10), newArPackage)
    );
  };

  const onChangeMessageOrder = async (src: number, dst: number) => {
    if (!arPackage || !scenario) return;

    const newMessages = scenario.messages.filter((_, i) => i !== src);
    newMessages.splice(dst, 0, scenario.messages[src]);

    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };

    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: arPackage.scenarios.map((sc) =>
        sc.scenario_id === scenario.scenario_id ? newScenario : sc
      ),
    };

    await dispatch(
      updateArPackage(Number.parseInt(packageId, 10), newArPackage)
    );
  };

  const onOpenMessageClicked = (messageId: string) => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${messageId}`
    );
  };

  // message table data
  const tableData = {
    id: 'message-table',
    titles: {
      id: 'ID',
      title: 'メッセージ名',
      operation: '操作',
    },
    values: (scenario?.messages || []).map((msg) => ({
      id: msg.message_id,
      title: msg.title,
      operation: (
        <Button
          size="sm"
          type="button"
          onClick={() => {
            onOpenMessageClicked(msg.message_id);
          }}
        >
          開く
        </Button>
      ),
    })),
    styles: {
      id: { width: '45px' },
      operation: { width: '100px' },
    },
  };

  return (
    <Page>
      <DeleteModal
        objectName="シナリオ"
        onCloseClicked={onDeleteCancelled}
        onDeleteClicked={onDeleteConfirmed}
        show={deleteModalShow}
      />
      <PageTitle value="シナリオ" />
      <PageBody loading={!arPackage || !scenario}>
        <Row className="mb-4">
          <ScenarioMetadataView
            value={scenario!}
            onSubmitScenario={onSubmitScenario}
          />
        </Row>
        {scenario?.marker_type === MarkerType.MARKER_ENABLED && (
          <Row className="mb-4">
            <h3 className="mb-2">マーカー</h3>
            <div className="ms-2 mb-2">
              拡張子 .jpg, .png
              のファイルのみ許可されています。ファイルサイズは200MBまでです。
            </div>
            <SingleFileUpload
              showIcon
              uploadedText="設定済み"
              noUploadText="未設定"
              isUploaded={!!scenario?.marker}
              onUpload={onUploadMarkerClicked}
              allowedMimeTypes={['image/jpeg', 'image/png']}
            />
          </Row>
        )}
        <Row className="mb-4">
          <h3 className="mb-2">メッセージ</h3>
          <div>
            <Button type="button" onClick={onAddMessageClicked}>
              メッセージを追加
            </Button>
          </div>
          <SortableTable
            id={tableData.id}
            titles={tableData.titles}
            values={tableData.values}
            styles={tableData.styles}
            onChangeOrder={onChangeMessageOrder}
          />
        </Row>
        <Row className="mb-4">
          <h3 className="mb-2">削除</h3>
          <div className="ms-3">
            <Button variant="danger" onClick={onDeleteClicked}>
              シナリオを削除する
            </Button>
          </div>
        </Row>
        <Button type="button" variant="secondary" onClick={onBack}>
          戻る
        </Button>
      </PageBody>
    </Page>
  );
}
