import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import {
  changeAdminPassword,
  loadCompanies,
  selectCompanies,
} from './bocompaniesSlice';
import SimpleTable, {
  SimpleTablePropTypes,
} from '../../../components/SimpleTable';
import { Company, GroupType } from '../bocommon/models';
import validationErrors from '../../../util/validationErrors';
import { isValidPassword } from '../../../util';
import { loadProjects, selectProjects } from '../bocommon/bocommonSlice';

interface PasswordChangeModalPropType {
  company?: Company;
  onSubmit: (_newPassword: string) => unknown;
  onCancel: () => unknown;
}

PasswordChangeModal.defaultProps = {
  company: undefined,
};

function PasswordChangeModal({
  company,
  onSubmit,
  onCancel,
}: PasswordChangeModalPropType) {
  // fromik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required(validationErrors.required())
        .matches(isValidPassword, validationErrors.password()),
    }),
    initialValues: {
      newPassword: '',
    },
    onSubmit: (value, helper) => {
      onSubmit(value.newPassword);
      helper.resetForm();
    },
  });

  // callbacks
  const onCancelClick = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <Modal show={!!company}>
      <Modal.Header>
        <Modal.Title>tp-adminパスワード再設定</Modal.Title>
      </Modal.Header>

      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Row>
              <Col md={5} lg={4}>
                企業グループ名
              </Col>
              <Col md={5}>{company?.name}</Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={5} lg={4}>
                パスワード
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="password"
                  name="newPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  isValid={
                    formik.touched.newPassword && !formik.errors.newPassword
                  }
                  isInvalid={
                    formik.touched.newPassword && !!formik.errors.newPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={onCancelClick}>
            キャンセル
          </Button>
          <Button type="submit">設定</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function showGroupType(gt: GroupType): string {
  switch (gt) {
    case 'NORMAL':
      return '通常';
    case 'CELEMONY':
      return 'セレモニー';
    default:
      return '-';
  }
}

export default function BOCompanyListPage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // init
  useEffect(() => {
    dispatch(loadCompanies());
    dispatch(loadProjects());
  }, []);

  // local state
  const [companyToChangePassword, setCompanyToChangePassword] = useState<
    Company | undefined
  >(undefined);

  // state
  const companies = useSelector(selectCompanies);
  const projects = useSelector(selectProjects);

  // callbacks
  const onCreateCompanyClick = () => {
    history.push('/bo/companies/add');
  };

  const onBackClick = () => {
    history.push('/bo/menu');
  };

  const onModalCancel = () => {
    setCompanyToChangePassword(undefined);
  };

  const onModalSubmit = async (newPassword: string) => {
    if (!companyToChangePassword) {
      return;
    }

    const company = companyToChangePassword;
    setCompanyToChangePassword(undefined);

    await dispatch(changeAdminPassword(company.id, { password: newPassword }));
  };

  // table
  const tableProps: SimpleTablePropTypes = {
    titles: {
      id: 'ID',
      projectId: 'プロジェクトID',
      projectName: 'プロジェクト名',
      groupType: '企業グループ種別',
      groupId: '企業グループID',
      groupName: '企業グループ名',
      passwordChange: 'tp-adminパスワード変更',
    },
    values: companies.map((company) => ({
      id: company.id.toString(),
      projectId: company.project_id,
      projectName:
        projects.find((project) => project.id === company.project_id)?.name ||
        'プロジェクト未作成',
      groupType: showGroupType(company.group_type),
      groupId: company.login_id,
      groupName: company.name,
      passwordChange: (
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            setCompanyToChangePassword(company);
          }}
        >
          パスワード変更
        </Button>
      ),
    })),
    styles: {},
  };

  return (
    <BOPage>
      <PasswordChangeModal
        company={companyToChangePassword}
        onSubmit={onModalSubmit}
        onCancel={onModalCancel}
      />

      <BOPageTitle>企業グループ管理</BOPageTitle>
      <BOPageBody>
        <div>
          <Button onClick={onCreateCompanyClick}>企業グループを追加</Button>
        </div>
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <SimpleTable {...tableProps} />
        </div>
        <div>
          <Button onClick={onBackClick} variant="secondary">
            戻る
          </Button>
        </div>
      </BOPageBody>
    </BOPage>
  );
}
