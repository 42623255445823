import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ContentsPropTypes } from './ContentPropTypes';
import {
  Content,
  FullScreenVideoContent,
  VideoOnBodyContent,
} from '../../common/models/ArPackage';
import SingleFileUpload from '../../../components/SingleFileUpload';
import {
  selectArPackage,
  selectUploadedFileId,
  setUploadedFileId,
  uploadFile,
} from '../arPackageSlice';
import { getAppDownloadUrl } from '../../../util';

/* eslint-disable camelcase */
type VideoContent = { video_url: string };

function VideoComponent<TVideoContent extends Content & VideoContent>(
  title: string,
  { packageId, content, onSubmitContent, onCancel }: ContentsPropTypes
) {
  const c = content as TVideoContent;

  // boilerplate
  const dispatch = useDispatch();

  // state
  const uploadedFileId = useSelector(selectUploadedFileId);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      video_url: Yup.string().required('動画が未設定です'),
    }),
    initialValues: {
      video_url: c.video_url,
    },
    onSubmit: (val) => {
      const newContent: TVideoContent = {
        ...c,
        video_url: val.video_url,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  // callbacks
  const onUploadClicked = async (file: File) => {
    await dispatch(uploadFile(packageId, file));
  };

  useEffect(() => {
    if (uploadedFileId) {
      formik.setFieldValue(
        'video_url',
        getAppDownloadUrl(packageId, uploadedFileId)
      );
      dispatch(setUploadedFileId());
    }
  }, [uploadedFileId]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="ms-2 mb-2">
          拡張子 .mp4
          のファイルのみ許可されています。ファイルサイズは200MBまでです。
        </div>
        <SingleFileUpload
          title={title}
          noUploadText="未設定"
          uploadedText="設定済み"
          showIcon
          isUploaded={!!formik.values.video_url}
          onUpload={onUploadClicked}
          allowedMimeTypes={['video/mp4']}
        />

        <Alert variant="danger" show={!!formik.errors.video_url}>
          {formik.errors.video_url}
        </Alert>

        <Button type="submit" variant="primary" className="me-2">
          保存
        </Button>
        <Button type="button" variant="secondary" onClick={onCancel}>
          キャンセル
        </Button>
      </Form>
    </FormikProvider>
  );
}

export function VideoOnBodyComponent(props: ContentsPropTypes) {
  return VideoComponent<VideoOnBodyContent>('動画', props);
}

export function FullScreenVideoComponent({
  packageId,
  scenarioId,
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as FullScreenVideoContent;

  // boilerplate
  const dispatch = useDispatch();

  // state
  const uploadedFileId = useSelector(selectUploadedFileId);
  const arPackage = useSelector(selectArPackage);
  if (!arPackage) {
    throw new Error('invalid state');
  }

  const scenario = arPackage.scenarios.find(
    (s) => s.scenario_id === scenarioId.toString()
  );
  if (!scenario) {
    throw new Error('scenario not found');
  }

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      video_url: Yup.string().required('動画が未設定です'),
      message_id: Yup.string().required('メッセージが未設定です'),
    }),
    initialValues: {
      video_url: c.video_url,
      message_id: c.message_id,
    },
    onSubmit: (val) => {
      const newContent: FullScreenVideoContent = {
        ...c,
        video_url: val.video_url,
        message_id: val.message_id,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  // callbacks
  const onUploadClicked = async (file: File) => {
    await dispatch(uploadFile(packageId, file));
  };

  useEffect(() => {
    if (uploadedFileId) {
      formik.setFieldValue(
        'video_url',
        getAppDownloadUrl(packageId, uploadedFileId)
      );
      dispatch(setUploadedFileId());
    }
  }, [uploadedFileId]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="ms-2 mb-2">
          拡張子 .mp4
          のファイルのみ許可されています。ファイルサイズは200MBまでです。
        </div>
        <SingleFileUpload
          title="動画（全画面）"
          noUploadText="未設定"
          uploadedText="設定済み"
          showIcon
          isUploaded={!!formik.values.video_url}
          onUpload={onUploadClicked}
          allowedMimeTypes={['video/mp4']}
        />
        <Alert
          variant="danger"
          show={!!formik.touched.video_url && !!formik.errors.video_url}
        >
          {formik.errors.video_url}
        </Alert>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={2}>
              メッセージ
            </Form.Label>
            <Col lg={5}>
              <Form.Select
                name="message_id"
                value={formik.values.message_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.message_id && !formik.errors.message_id}
                isInvalid={
                  formik.touched.message_id && !!formik.errors.message_id
                }
              >
                <option value="">-</option>
                {scenario.messages.map((message) => (
                  <option
                    key={`messages.${message.message_id}`}
                    value={message.message_id}
                  >
                    {message.message_id}: {message.title}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Alert
          className="mt-3"
          variant="danger"
          show={!!formik.touched.message_id && !!formik.errors.message_id}
        >
          {formik.errors.message_id}
        </Alert>
        <Button type="submit" variant="primary" className="me-2">
          保存
        </Button>
        <Button type="button" variant="secondary" onClick={onCancel}>
          キャンセル
        </Button>
      </Form>
    </FormikProvider>
  );
}
