import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { User } from '../common/models';

export type UserInfoChangeFormProps = {
  user: User;
  onSubmit: (_newUser: Partial<User>) => unknown;
};

export default function UserInfoChangeForm(props: UserInfoChangeFormProps) {
  const { user, onSubmit } = props;

  // Formik configuration
  const formik = useFormik({
    validationSchema: Yup.object().shape({}),
    initialValues: {
      requires_pii: user.requires_pii,
    },
    onSubmit: (v) => {
      onSubmit({
        requires_pii: v.requires_pii,
      });
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>
            <Col lg={3}>個人情報を要求</Col>

            <Col lg={5}>
              <Form.Check
                name="requires_pii"
                type="checkbox"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.requires_pii}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Button variant="primary" type="submit">
            変更する
          </Button>
        </Form.Group>
      </Form>
    </FormikProvider>
  );
}
