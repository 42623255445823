import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import { fetchAppUsers, selectAppUsers } from './appUsersSlice';
import AppUsersTable from './components/AppUsersTable';

export default function AppUsersPage() {
  const dispatch = useDispatch();
  const appUsers = useSelector(selectAppUsers);

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, []);

  return (
    <Page>
      <PageTitle value="アプリユーザー" />
      <PageBody>
        <AppUsersTable appUsers={appUsers} />
      </PageBody>
    </Page>
  );
}
