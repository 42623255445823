/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleTable, {
  SimpleTablePropTypes,
} from '../../../components/SimpleTable';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import { loadProjects, selectProjects } from '../bocommon/bocommonSlice';

export default function BOProjectListPage() {
  // boilerplates
  const dispatch = useDispatch();
  const history = useHistory();

  // init
  useEffect(() => {
    dispatch(loadProjects());
  }, []);

  // states
  const projects = useSelector(selectProjects);

  // callbacks
  const onBackClick = () => {
    history.push('/bo/menu');
  };
  const onEditClick = (projectId: string) => {
    history.push(`/bo/projects/${projectId}`);
  };
  const onAddClick = () => {
    history.push(`/bo/projects/add`);
  };

  // table
  const tableProps: SimpleTablePropTypes = {
    titles: {
      id: 'プロジェクトID',
      name: 'プロジェクト名',
      catchphrase: 'キャッチフレーズ',
      image: '画像',
      operation: '操作',
    },
    values: projects.map((p) => ({
      id: p.id,
      name: p.name,
      catchphrase: p.catchphrase,
      image: p.header_img_key ? '設定済み' : '未設定',
      operation: (
        <Button variant="secondary" size="sm" onClick={() => onEditClick(p.id)}>
          編集
        </Button>
      ),
    })),
  };

  return (
    <BOPage>
      <BOPageTitle>プロジェクト一覧</BOPageTitle>
      <BOPageBody>
        <div className="mb-3">
          <Button type="button" onClick={onAddClick}>
            プロジェクトを追加
          </Button>
        </div>
        <div className="mb-3">
          <SimpleTable {...tableProps} />
        </div>
        <div>
          <Button onClick={onBackClick} variant="secondary">
            戻る
          </Button>
        </div>
      </BOPageBody>
    </BOPage>
  );
}
