import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { RootState } from '../../app/store';
import Page from '../../components/Page';
import PageBody from '../common/PageBody';
import PageTitle from '../../components/PageTitle';
import Paginator from '../../components/Paginator';
import { ITEMS_PER_PAGE_COUNT, refreshItems } from './itemsSlice';
import { formatJSONDate } from '../../util';
import './ItemListPage.scss';

export default function ItemListPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(refreshItems({ withReset: true }));
  }, []);

  const items = useSelector((state: RootState) => state.items.items);
  const itemsView = items.map((item) => {
    const linkTo = `/items/${item.id}`;
    return (
      <Card className="item-card mb-2" key={item.id}>
        <Link to={linkTo} className="item-card-link" key={item.id}>
          <Card.Body>
            <Card.Title>{item.title}</Card.Title>
            <Card.Text className="text-secondary">
              作成日時：{formatJSONDate(item.updatedAt)}
            </Card.Text>
          </Card.Body>
        </Link>
      </Card>
    );
  });

  const totalCount = useSelector(
    (state: RootState) => state.items.itemsTotalCount
  );
  const maxPage = Math.ceil(totalCount / ITEMS_PER_PAGE_COUNT);
  const onSelectPage = (arg: { selected: number }) => {
    dispatch(refreshItems({ page: arg.selected + 1 }));
  };

  const onItemAddClicked = () => {
    history.push('/items/add');
  };

  return (
    <Page>
      <PageTitle value="アイテム一覧" />
      <PageBody>
        <div className="mb-3">
          <Button onClick={onItemAddClicked} variant="primary">
            アイテム追加
          </Button>
        </div>
        <div>
          <Paginator
            pageCount={maxPage}
            marginPagesDisplayed={isMobile ? 1 : 2}
            pageRangeDisplayed={isMobile ? 2 : 3}
            onPageChange={onSelectPage}
          />
        </div>
        <div>{itemsView}</div>
      </PageBody>
    </Page>
  );
}
