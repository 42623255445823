import React from 'react';
import Loader from 'react-loader-spinner';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
}

export default function PageBody({ children, loading }: Props) {
  if (loading) {
    return (
      <div className="text-center">
        <Loader type="ThreeDots" color="#4682b4" />
      </div>
    );
  }

  return <div>{children}</div>;
}

PageBody.defaultProps = {
  loading: false,
};
