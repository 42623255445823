import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './ScreenLock.scss';

export default function ScreenLock(props: { enabled: Boolean }) {
  const { enabled } = props;

  return (
    <div className={enabled ? '' : 'd-none'}>
      <div className="screen-lock" />
      <div className="screen-lock-loader">
        <Loader type="Oval" height={100} width={100} color="#4682b4" />
      </div>
    </div>
  );
}
