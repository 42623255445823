import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import { formatJSONDate } from '../../util';
import { ArPackageAnswer } from '../common/models/ArPackage';
import PageBody from '../common/PageBody';
import { getSelectedItem, selectSelectedItem } from '../items/itemsSlice';
import { loadArPackage, loadArPackageAnswers, selectArPackage, selectArPackageAnswers } from './arPackageSlice';

interface AnswerDetailProps {
  answer?: ArPackageAnswer | null;
  onClose: () => any;
}

function AnswerDetail({ answer, onClose }: AnswerDetailProps) {
  if (!answer) {
    return <Modal show={false} />;
  }

  const answerDetailStr = JSON.stringify(answer.answer.contents.detail, null, 2);

  return (
    <Modal show>
      <Modal.Body>
        <Row className="mb-2">
          <Col md={3} lg={2}>
            氏名
          </Col>
          <Col md={5}>{answer.user.name}</Col>
        </Row>

        <Row className="mb-2">
          <Col md={3} lg={2}>
            メールアドレス
          </Col>
          <Col md={5}>{answer.user.email}</Col>
        </Row>

        <Row className="mb-2">
          <Col md={3} lg={2}>
            回答日時
          </Col>
          <Col md={5}>{formatJSONDate(answer.answer.createdAt)}</Col>
        </Row>

        <Row className="mb-2">
          <Col md={3} lg={2}>
            回答サマリ
          </Col>
          <Col md={5}>{answer.answer.contents.summary}</Col>
        </Row>

        <Row className="mb-1">
          <Col>回答データ</Col>
        </Row>
        <Row>
          <Col>
            <Form.Control as="textarea" rows={10} value={answerDetailStr} readOnly />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AnswerDetail.defaultProps = {
  answer: null,
};

type ParamTypes = {
  itemId: string;
  packageId: string;
};

export default function ArPackageAnswerListPage() {
  // boilerplate
  const history = useHistory();
  const dispatch = useDispatch();

  const item = useSelector(selectSelectedItem);
  const pack = useSelector(selectArPackage);
  const arAnswers = useSelector(selectArPackageAnswers);

  // parse ParamTypes
  const params = useParams<ParamTypes>();
  const itemId = Number.parseInt(params.itemId, 10);
  const packageId = Number.parseInt(params.packageId, 10);

  // local states
  const [showingAnswer, setShowingAnswer] = useState<ArPackageAnswer | null>(null);

  // refresh store
  useEffect(() => {
    dispatch(getSelectedItem(itemId));
    dispatch(loadArPackage(packageId));
    dispatch(loadArPackageAnswers(packageId));
  }, []);

  // callbacks
  const onBackClicked = () => {
    history.push(`/items/${params.itemId}`);
  };

  const onShowDetailClicked = (answer: ArPackageAnswer) => {
    setShowingAnswer(answer);
  };

  const onCloseDetailClicked = () => {
    setShowingAnswer(null);
  };

  // render
  return (
    <Page>
      <AnswerDetail answer={showingAnswer} onClose={onCloseDetailClicked} />

      <PageTitle value="ARパッケージ回答結果" />
      <PageBody>
        <Row>
          <Col md={3} lg={2}>
            アイテム名
          </Col>
          <Col md={5}>{item?.title}</Col>
        </Row>
        <Row className="mb-4">
          <Col md={3} lg={2}>
            パッケージ名
          </Col>
          <Col md={5}>{pack?.title}</Col>
        </Row>

        <Card className="mb-4">
          <Card.Header>回答一覧</Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>氏名</th>
                  <th>メールアドレス</th>
                  <th>回答サマリ</th>
                  <th>回答データ</th>
                  <th>回答時刻</th>
                </tr>
              </thead>
              <tbody>
                {arAnswers?.map((data) => {
                  const { answer, user } = data;
                  return (
                    <tr key={answer.id.toString()}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{answer.contents.summary}</td>
                      <td>
                        <Button onClick={() => onShowDetailClicked(data)}>回答データ</Button>
                      </td>
                      <td>{formatJSONDate(answer.createdAt)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Button variant="secondary" onClick={onBackClicked}>
          戻る
        </Button>
      </PageBody>
    </Page>
  );
}
