import React from 'react';
import { Row } from 'react-bootstrap';

export interface BOPageTitleProps {
  children: React.ReactNode;
}

export default function BOPageTitle({ children }: BOPageTitleProps) {
  return (
    <Row className="mb-2">
      <h2>{children}</h2>
    </Row>
  );
}
