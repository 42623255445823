import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../app/store';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import { changeUser, changeUserPassword, loadUser, setIsChangePasswordFinished } from '../common/commonSlice';
import { User } from '../common/models';
import PageBody from '../common/PageBody';
import PasswordChangeForm from './PasswordChangeForm';
import UserInfoChangeForm from './UserInfoChangeForm';

export default function UserInfo() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isChangePasswordFinished = useSelector((state: RootState) => state.common.isChangeUserPasswordFinished);
  const user = useSelector((state: RootState) => state.common.user);

  // After changing password, it is neccesary to set state.
  useEffect(() => {
    if (isChangePasswordFinished) {
      dispatch(setIsChangePasswordFinished(false));
      history.push('/');
    }
  }, [isChangePasswordFinished]);

  const onChangePasswordSubmit = (currentPassword: string, newPassword: string) => {
    dispatch(changeUserPassword(currentPassword, newPassword));
  };

  const onChangeUserInfo = async (newUser: Partial<User>) => {
    await dispatch(changeUser(newUser));
    await dispatch(loadUser());
  };

  return (
    <Page>
      <PageTitle value="ユーザ設定" />
      <PageBody>
        <Card className="mb-4">
          <Card.Header>ユーザ情報設定</Card.Header>
          <Card.Body>{user && <UserInfoChangeForm user={user} onSubmit={onChangeUserInfo} />}</Card.Body>
        </Card>

        <Card>
          <Card.Header>パスワード再設定</Card.Header>
          <Card.Body>
            <PasswordChangeForm onSubmit={onChangePasswordSubmit} />
          </Card.Body>
        </Card>
      </PageBody>
    </Page>
  );
}
