import React from 'react';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row } from 'react-bootstrap';
// import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ContentsPropTypes } from './ContentPropTypes';
// import validationErrors from '../../../util/validationErrors';
import { ChoiceContent } from '../../common/models/ArPackage';
// import { selectUploadedFileId } from '../arPackageSlice';
import SortableTable from '../../../components/SortableTable';
import { selectArPackage } from '../arPackageSlice';
import { setCommonError } from '../../common/commonSlice';

type ChoiceActionType = 1 | 2;
const ChoiceActionTypeMessage: ChoiceActionType = 1;
const ChoiceActionTypeUrl: ChoiceActionType = 2;

function toChoiceActionType(value: number) {
  if (ChoiceActionTypeMessage === value) {
    return ChoiceActionTypeMessage;
  }
  if (ChoiceActionTypeUrl === value) {
    return ChoiceActionTypeUrl;
  }

  throw new Error();
}

export default function ChoiceComponent({
  scenarioId,
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as ChoiceContent;

  // boilerplate
  const dispatch = useDispatch();

  // state
  const arPackage = useSelector(selectArPackage);
  if (!arPackage) {
    throw new Error('invalid state');
  }

  const scenario = arPackage.scenarios.find(
    (s) => s.scenario_id === scenarioId.toString()
  );
  if (!scenario) {
    throw new Error('scenario not found');
  }

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      choices: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required('テキストが未設定です'),
          actionType: Yup.number().required(),
          actionValue: Yup.string().required('アクションの値が未設定です'),
        })
      ),
    }),
    initialValues: {
      choices: c.choices.map((choice) => {
        let actionType;
        let actionValue;
        if (choice.message_id) {
          actionType = ChoiceActionTypeMessage;
          actionValue = choice.message_id;
        } else if (choice.link_url) {
          actionType = ChoiceActionTypeUrl;
          actionValue = choice.link_url;
        } else {
          throw new Error('Invalid choice value');
        }

        return {
          label: choice.label,
          actionType,
          actionValue,
        };
      }),
    },
    onSubmit: (val) => {
      const newContent: ChoiceContent = {
        ...c,
        choices: val.choices.map((value) => ({
          label: value.label,
          message_id:
            value.actionType === ChoiceActionTypeMessage
              ? value.actionValue
              : undefined,
          link_url:
            value.actionType === ChoiceActionTypeUrl
              ? value.actionValue
              : undefined,
        })),
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: false,
  });

  const choicesView = (
    <FieldArray name="choices">
      {(arrayHelpers) => (
        <>
          <div>
            <Button
              variant="primary"
              type="button"
              className="me-2"
              onClick={() => {
                if (formik.values.choices.length >= 4) {
                  dispatch(setCommonError('追加できる選択肢は４つまでです'));
                  return;
                }
                arrayHelpers.push({
                  label: '',
                  actionType: ChoiceActionTypeMessage,
                  actionValue: '',
                });
              }}
            >
              選択肢を追加
            </Button>
          </div>

          <SortableTable
            id="subtitles-table"
            titles={{
              label: 'テキスト',
              actionType: 'アクション',
              actionValue: '値',
              operation: '操作',
            }}
            styles={{}}
            values={formik.values.choices.map((choice, i) => ({
              id: i.toString(),
              label: (() => {
                const name = `choices.${i}.label`;
                return (
                  <>
                    <Form.Control
                      type="string"
                      name={name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={choice.label}
                      isValid={
                        getIn(formik.touched, name) &&
                        !getIn(formik.errors, name)
                      }
                      isInvalid={
                        getIn(formik.touched, name) &&
                        !!getIn(formik.errors, name)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {getIn(formik.errors, name)}
                    </Form.Control.Feedback>
                  </>
                );
              })(),
              actionType: (() => {
                const name = `choices.${i}.actionType`;
                return (
                  <>
                    <Form.Select
                      name={name}
                      onBlur={formik.handleBlur}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedActionType = parseInt(e.target.value, 10);
                        if (
                          selectedActionType ===
                          formik.values.choices[i].actionType
                        ) {
                          // 同じ選択肢の場合はリセットしない
                          return;
                        }

                        // 選択肢が変わる場合はアクションが持っている値をリセットする
                        const newValues = {
                          choices: [...formik.values.choices],
                        };
                        newValues.choices[i].actionType =
                          toChoiceActionType(selectedActionType);
                        newValues.choices[i].actionValue = '';
                        formik.setValues(newValues);
                      }}
                      value={choice.actionType}
                    >
                      <option value={ChoiceActionTypeMessage}>
                        メッセージへジャンプ
                      </option>
                      <option value={ChoiceActionTypeUrl}>URLへジャンプ</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {getIn(formik.errors, name)}
                    </Form.Control.Feedback>
                  </>
                );
              })(),
              actionValue: (() => {
                const name = `choices.${i}.actionValue`;
                if (choice.actionType === ChoiceActionTypeMessage) {
                  return (
                    <>
                      <Form.Select
                        name={name}
                        value={choice.actionValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={
                          getIn(formik.touched, name) &&
                          !getIn(formik.errors, name)
                        }
                        isInvalid={
                          getIn(formik.touched, name) &&
                          !!getIn(formik.errors, name)
                        }
                      >
                        <option value="">-</option>
                        {scenario.messages.map((message) => (
                          <option
                            key={`actionValue.${message.message_id}`}
                            value={message.message_id}
                          >
                            {message.title}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {getIn(formik.errors, name)}
                      </Form.Control.Feedback>
                    </>
                  );
                }
                return (
                  <>
                    <Form.Control
                      name={name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={choice.actionValue}
                      isValid={
                        getIn(formik.touched, name) &&
                        !getIn(formik.errors, name) &&
                        choice.actionValue !== ''
                      }
                      isInvalid={
                        getIn(formik.touched, name) &&
                        !!getIn(formik.errors, name) &&
                        choice.actionValue === ''
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {getIn(formik.errors, name)}
                    </Form.Control.Feedback>
                  </>
                );
              })(),
              operation: (
                <>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(i);
                    }}
                  >
                    削除
                  </Button>
                </>
              ),
            }))}
            onChangeOrder={(src, dst) => {
              arrayHelpers.move(src, dst);
            }}
          />
        </>
      )}
    </FieldArray>
  );

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>{choicesView}</Row>
        </Form.Group>

        <div className="mt-5">
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
