import React, { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';

export default function BOMenu() {
  const history = useHistory();

  const onBOProjectsClick = () => {
    history.push('/bo/projects');
  };

  const onBOCompaniesClick = () => {
    history.push('/bo/companies');
  };

  const onBODeathCertificatesClick = () => {
    history.push('/bo/deathcertificates');
  };

  return (
    <BOPage>
      <BOPageTitle>管理メニュー</BOPageTitle>
      <BOPageBody>
        <div className="mb-2">
          <Button onClick={onBOProjectsClick}>プロジェクト管理</Button>
        </div>
        <div className="mb-2">
          <Button onClick={onBOCompaniesClick}>企業グループ管理</Button>
        </div>
        <div>
          <Button onClick={onBODeathCertificatesClick}>死亡証明書一覧</Button>
        </div>
      </BOPageBody>
    </BOPage>
  );
}
