import { useFormik } from 'formik';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import DeleteModal from '../../components/DeleteModal';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import SortableTable from '../../components/SortableTable';
import validationErrors from '../../util/validationErrors';
import {
  ArPackage,
  ContentWrapper,
  extractContentWrappersFromMessage,
  Message,
  Scenario,
  setContentWrappersToMessage,
} from '../common/models/ArPackage';
import PageBody from '../common/PageBody';
import {
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
} from './arPackageSlice';

function MessageEditView(props: {
  message: Message;
  onSubmitMessage: (_message: Message) => any;
}) {
  // parse props
  const { message, onSubmitMessage } = props;

  // state
  const [editing, setEditing] = useState(false);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
    }),
    initialValues: { title: message.title || '' },
    onSubmit: async (values) => {
      const newMessage: Message = {
        ...message,
        title: values.title,
      };

      setEditing(false);
      onSubmitMessage(newMessage);
    },
    enableReinitialize: true,
  });

  // callbacks
  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = (e: any) => {
    formik.handleReset(e);
    setEditing(false);
  };

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3">
        <Row>
          <Form.Label column md={3} lg={2}>
            メッセージ名
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="text"
              name="title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              isValid={editing && formik.touched.title && !formik.errors.title}
              isInvalid={formik.touched.title && !!formik.errors.title}
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      {!editing && (
        <Button type="button" variant="primary" onClick={onEdit}>
          編集
        </Button>
      )}
      {editing && (
        <>
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </>
      )}
    </Form>
  );
}

type ParamTypes = {
  itemId: string;
  packageId: string;
  scenarioId: string;
  messageId: string;
};

export default function MessagePage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // parse path params
  const params = useParams<ParamTypes>();
  const itemId = Number.parseInt(params.itemId, 10);
  const packageId = Number.parseInt(params.packageId, 10);
  const scenarioId = Number.parseInt(params.scenarioId, 10);
  const messageId = Number.parseInt(params.messageId, 10);

  // refresh view
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(packageId));
  }, []);

  // store
  const arPackage = useSelector(selectArPackage);
  const scenarios = arPackage?.scenarios || [];
  const scenario = scenarios.find(
    (sc) => sc.scenario_id === scenarioId.toString()
  );
  const messages = scenario?.messages || [];
  const message = messages.find(
    (msg) => msg.message_id === messageId.toString()
  );

  const contents: ContentWrapper[] = useMemo(() => {
    if (!message) {
      return [];
    }

    return extractContentWrappersFromMessage(message);
  }, [message]);

  // internal state
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteContentModalShow, setDeleteContentModalShow] = useState(false);
  const [deletingContent, setDeletingContent] = useState<ContentWrapper | null>(
    null
  );
  const [deletingContentsName, setDeletingContentsName] = useState('');

  // callbacks
  const onEditMessage = async (newMessage: Message) => {
    if (!arPackage || !scenario) {
      return;
    }

    const newMessages = messages.map((m) =>
      m.message_id === newMessage.message_id ? newMessage : m
    );
    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };
    const newScenarios = scenarios.map((sc) =>
      sc.scenario_id === newScenario.scenario_id ? newScenario : sc
    );
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(updateArPackage(packageId, newArPackage));
  };

  const onBack = () => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}`
    );
  };

  const onDeleteClicked = () => {
    setDeleteModalShow(true);
  };

  const onDeleteConfirmed = async () => {
    if (!arPackage || !scenario || !message) {
      return;
    }

    const newMessages = messages.filter(
      (m) => m.message_id !== message.message_id
    );
    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };
    const newScenarios = scenarios.map((sc) =>
      sc.scenario_id === newScenario.scenario_id ? newScenario : sc
    );
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(
      updateArPackage(packageId, newArPackage, () => {
        history.push(
          `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}`
        );
      })
    );

    setDeleteModalShow(false);
  };

  const onDeleteCancelled = () => {
    setDeleteModalShow(false);
  };

  const onChangeContentsOrder = async (src: number, dst: number) => {
    if (!arPackage || !scenario || !message) {
      return;
    }

    const newCWs = contents.filter((_, i) => i !== src);
    newCWs.splice(dst, 0, contents[src]);

    const newMessage = setContentWrappersToMessage(message, newCWs);

    const newMessages = messages.map((m) =>
      m.message_id === newMessage.message_id ? newMessage : m
    );
    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };
    const newScenarios = scenarios.map((sc) =>
      sc.scenario_id === newScenario.scenario_id ? newScenario : sc
    );
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(updateArPackage(packageId, newArPackage));
  };

  const onDeleteContentClicked = (cw: ContentWrapper) => {
    setDeletingContentsName(
      `コンテンツ${cw.content.order}：${cw.typ.toString()}`
    );
    setDeleteContentModalShow(true);
    setDeletingContent(cw);
  };

  const onDeleteContentCancelled = () => {
    setDeletingContent(null);
    setDeleteContentModalShow(false);
    setDeletingContentsName('');
  };

  const onDeleteContentConfirmed = async () => {
    if (!arPackage || !scenario || !message || !deletingContent) {
      return;
    }

    const newCWs = contents.filter(
      (c) => c.content.order !== deletingContent.content.order
    );

    const newMessage = setContentWrappersToMessage(message, newCWs);

    const newMessages = messages.map((m) =>
      m.message_id === newMessage.message_id ? newMessage : m
    );
    const newScenario: Scenario = {
      ...scenario,
      messages: newMessages,
    };
    const newScenarios = scenarios.map((sc) =>
      sc.scenario_id === newScenario.scenario_id ? newScenario : sc
    );
    const newArPackage: ArPackage = {
      ...arPackage,
      scenarios: newScenarios,
    };

    await dispatch(updateArPackage(packageId, newArPackage));

    setDeletingContent(null);
    setDeleteContentModalShow(false);
    setDeletingContentsName('');
  };

  const onEditContentClicked = (cw: ContentWrapper) => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${messageId}/contents/${cw.content.order}`
    );
  };

  // table data
  const tableData = {
    id: 'contents-table',
    titles: {
      order: '順番',
      contentsType: 'コンテンツ種別',
      contentsPreview: 'コンテンツ内容',
      operation: '操作',
    },
    values: contents.map((c) => ({
      id: c.content.order.toString(),
      order: c.content.order,
      contentsType: c.typ.toString(),
      contentsPreview: (
        <FormControl as="textarea" value={JSON.stringify(c)} disabled />
      ),
      operation: (
        <>
          <Button
            variant="secondary"
            size="sm"
            className="me-2"
            onClick={() => onEditContentClicked(c)}
          >
            編集
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => onDeleteContentClicked(c)}
          >
            削除
          </Button>
        </>
      ),
    })),
    styles: {
      order: { width: '100px' },
      operation: { width: '200px' },
    },
  };

  const onAddContentClicked = () => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}/messages/${messageId}/contents/add`
    );
  };

  return (
    <Page>
      <DeleteModal
        objectName="メッセージ"
        onCloseClicked={onDeleteCancelled}
        onDeleteClicked={onDeleteConfirmed}
        show={deleteModalShow}
      />
      <DeleteModal
        objectName={deletingContentsName}
        onCloseClicked={onDeleteContentCancelled}
        onDeleteClicked={onDeleteContentConfirmed}
        show={deleteContentModalShow}
      />

      <PageTitle value="メッセージ" />

      <PageBody loading={!arPackage && !message}>
        <Row className="mb-4">
          {message && (
            <MessageEditView
              message={message}
              onSubmitMessage={onEditMessage}
            />
          )}
        </Row>

        <Row className="mb-4">
          <h3 className="mb-2">コンテンツ</h3>
          <div className="ms-3">
            <Button variant="primary" onClick={onAddContentClicked}>
              コンテンツ追加
            </Button>
            <SortableTable
              id={tableData.id}
              titles={tableData.titles}
              values={tableData.values}
              styles={tableData.styles}
              onChangeOrder={onChangeContentsOrder}
            />
          </div>
        </Row>

        <Row className="mb-4">
          <h3 className="mb-2">削除</h3>
          <div className="ms-3">
            <Button variant="danger" onClick={onDeleteClicked}>
              メッセージを削除する
            </Button>
          </div>
        </Row>

        <Button type="button" variant="secondary" onClick={onBack}>
          戻る
        </Button>
      </PageBody>
    </Page>
  );
}
