import React from 'react';
import ReactPaginate from 'react-paginate';

interface Props {
  pageCount: number;
  marginPagesDisplayed: number;
  pageRangeDisplayed: number;
  forcePage?: number;
  onPageChange(_selectedItem: { selected: number }): void;
}

export default function Pagenator(props: Props) {
  const {
    pageCount,
    marginPagesDisplayed,
    pageRangeDisplayed,
    forcePage,
    onPageChange,
  } = props;

  return (
    <ReactPaginate
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      forcePage={forcePage}
      onPageChange={onPageChange}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      previousClassName="page-item"
      nextClassName="page-item"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      disabledClassName="disabled"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      breakLabel="..."
      previousLabel="<"
      nextLabel=">"
    />
  );
}

Pagenator.defaultProps = {
  forcePage: undefined,
};
