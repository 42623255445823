import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { loadUser, setCommonError, withLoading } from '../common/commonSlice';
import { User } from '../common/models';

export interface UsersState {
  users: User[];
  isLoginAsUserFinished: boolean;
  isDeathDateUpdated: boolean;
}

const initialState: UsersState = {
  users: [],
  isLoginAsUserFinished: false,
  isDeathDateUpdated: false,
};

type FetchUsersAction = ThunkAction<void, RootState, unknown, AnyAction>;

export const fetchUsers = (): FetchUsersAction =>
  withLoading('usersSlice.fetchUsers', async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/users`
      );
      dispatch(
        usersSlice.actions.updateUsers({
          users: res.data,
        })
      );
    } catch {
      dispatch(setCommonError('ユーザー一覧の取得に失敗しました'));
    }
  });

type DeleteUserAction = ThunkAction<void, RootState, unknown, AnyAction>;
export const deleteUser = (id: number): DeleteUserAction =>
  withLoading('usersSlice.deleteUser', async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/users/${id}`
      );
    } catch {
      dispatch(setCommonError('ユーザーの削除に失敗しました'));
    }
  });

type ChangePasswordAction = ThunkAction<void, RootState, unknown, AnyAction>;
export const changePassword = (
  id: number,
  password: string
): ChangePasswordAction =>
  withLoading('usersSlice.changePassword', async (dispatch) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/users/${id}/password`,
        { password }
      );
    } catch {
      dispatch(setCommonError('パスワードの変更に失敗しました'));
    }
  });

type LoginAsUserAction = ThunkAction<void, RootState, unknown, AnyAction>;
export const loginAsUser = (id: number): LoginAsUserAction =>
  withLoading('userSlice.loginAsUser', async (dispatch) => {
    try {
      // 代理ログインAPI呼び出し
      await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/v1/console/users/loginasuser`,
        { id }
      );

      // ログイン完了通知
      dispatch(usersSlice.actions.setIsLoginAsUserFinished(true));
      dispatch(loadUser());
    } catch {
      dispatch(setCommonError('ユーザーの代理ログインに失敗しました'));
    }
  });

type UpdateDeathDateAction = ThunkAction<void, RootState, unknown, AnyAction>;

export const updateDeathDate = (
  id: number,
  deathDate: Date,
  deathCertificateFile?: File | null
): UpdateDeathDateAction =>
  withLoading('usersSlice.updateDeathDate', async (dispatch) => {
    try {
      const form = new FormData();
      form.append('deathDate', deathDate.getTime().toString());
      if (deathCertificateFile) {
        form.append('deathCertificateFile', deathCertificateFile);
      }

      await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/v1/console-file/users/${id}/deathdate`,
        form,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );

      // アップロード完了通知
      dispatch(usersSlice.actions.setIsDeathDateUpdated(true));
    } catch {
      dispatch(setCommonError('死亡証明書のアップロードに失敗しました'));
    }
  });

type UpdateUsersPayload = { users: User[] } | undefined;

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUsers: (state, payload: PayloadAction<UpdateUsersPayload>) => ({
      ...state,
      users: payload.payload?.users || [],
    }),
    setIsLoginAsUserFinished: (state, payload: PayloadAction<boolean>) => ({
      ...state,
      isLoginAsUserFinished: payload.payload,
    }),
    setIsDeathDateUpdated: (state, payload: PayloadAction<boolean>) => ({
      ...state,
      isDeathDateUpdated: payload.payload,
    }),
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
