import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { withLoading } from '../../../features/common/commonSlice';
import boApiClient from '../bocommon/boApiClient';
import { Company, ConsoleUser } from '../bocommon/models';

// State
export interface BODeathCertificatesState {
  companies: Company[];
  users: ConsoleUser[];
}

// Initial State
const initialState: BODeathCertificatesState = {
  companies: [],
  users: [],
};

// Selectors
export const selectCompanies = (state: RootState) =>
  state.bodeathcertificates.companies;
export const selectUsers = (state: RootState) =>
  state.bodeathcertificates.users;

// Thunks
export const loadCompanies = () =>
  withLoading('bodeathcertificates.loadCompanies', async (dispatch) => {
    const res = await boApiClient.getCompanies(dispatch);
    dispatch(bodeathcertificatesSlice.actions.setCompanies(res));
  });

export const loadUsers = (companyId: number) =>
  withLoading('bodeathcertificates.loadUsers', async (dispatch) => {
    const res = await boApiClient.getCompanyUsers(dispatch, companyId);
    dispatch(bodeathcertificatesSlice.actions.setUsers(res.users));
  });

// Slice
export const bodeathcertificatesSlice = createSlice({
  name: 'bodeathcertificates',
  initialState,
  reducers: {
    setCompanies: (state, payload: PayloadAction<Company[]>) => ({
      ...state,
      companies: payload.payload,
    }),
    setUsers: (state, payload: PayloadAction<ConsoleUser[]>) => ({
      ...state,
      users: payload.payload,
    }),
  },
});

// Exports
export const { setUsers } = bodeathcertificatesSlice.actions;
export default bodeathcertificatesSlice.reducer;
