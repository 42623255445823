import React from 'react';
import { Container } from 'react-bootstrap';

export interface BOPageProps {
  children: React.ReactNode;
}

export default function BOPage({ children }: BOPageProps) {
  return <Container className="pt-3">{children}</Container>;
}
