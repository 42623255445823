/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { BsList } from 'react-icons/bs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import * as M from '../common/models/ArPackage';
import {
  changeScenarioOrder,
  deletePackage,
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
} from './arPackageSlice';
import validationErrors from '../../util/validationErrors';
import DeleteModal from '../../components/DeleteModal';

type ParamTypes = {
  itemId: string;
  packageId: string;
};

function ArPackageMetadataView(props: {
  value: M.ArPackage;
  onSubmitPackage: (_p: M.ArPackage) => any;
}) {
  // props
  const { value, onSubmitPackage } = props;

  // local state
  const [editing, setEditing] = useState(false);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
    }),
    initialValues: {
      title: value.title,
      force_start_scenario_id: value.force_start_scenario_id || '',
    },
    onSubmit: (inputs) => {
      const newValue: M.ArPackage = {
        ...value,
        title: inputs.title,
        force_start_scenario_id:
          inputs.force_start_scenario_id === ''
            ? null
            : inputs.force_start_scenario_id,
      };

      setEditing(false);
      onSubmitPackage(newValue);
    },
    enableReinitialize: true,
  });

  // callbacks
  const onEditClick = () => {
    setEditing(true);
  };

  const onCancelClick = (e: any) => {
    formik.handleReset(e);
    setEditing(false);
  };

  // view components
  const forceStartScenarioIdPullDown = (
    <Form.Select
      name="force_start_scenario_id"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.force_start_scenario_id}
      disabled={!editing}
    >
      <option value="">なし</option>
      {value.scenarios.map((scenario) => (
        <option key={scenario.scenario_id} value={scenario.scenario_id}>
          {scenario.scenario_id}: {scenario.title}
        </option>
      ))}
    </Form.Select>
  );

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2}>
            パッケージ名
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type="text"
              name="title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              isValid={editing && formik.touched.title && !formik.errors.title}
              isInvalid={formik.touched.title && !!formik.errors.title}
              disabled={!editing}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Col md={3} lg={2}>
            パッケージ種別
          </Col>
          <Col>AR</Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-2">
        <Row>
          <Form.Label column md={3} lg={2}>
            強制起動シナリオ
          </Form.Label>
          <Col md={5}>{forceStartScenarioIdPullDown}</Col>
        </Row>
      </Form.Group>
      {editing ? (
        <>
          <Button type="submit" className="me-2">
            保存
          </Button>
          <Button type="submit" variant="secondary" onClick={onCancelClick}>
            キャンセル
          </Button>
        </>
      ) : null}
      {editing ? null : (
        <Button type="button" onClick={onEditClick}>
          編集
        </Button>
      )}
    </Form>
  );
}

function Td(props: { isDragging: boolean; children: React.ReactNode }) {
  // eslint-disable-next-line no-unused-vars
  const { isDragging, children } = props;
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (isDragging && width === null) {
      if (ref.current && ref.current.getBoundingClientRect().width > 0) {
        setWidth(ref.current.getBoundingClientRect().width);
      }
    }

    if (!isDragging) {
      setWidth(null);
    }
  }, [isDragging]);

  return (
    <td ref={ref} style={width ? { width } : {}}>
      {children}
    </td>
  );
}

function ScenarioListView(props: {
  value: M.Scenario[];
  onOpenScenario: (_scenario: M.Scenario) => any;
  onChangeScenarioOrder: (_src: number, _destination: number) => any;
}) {
  const { value, onOpenScenario, onChangeScenarioOrder } = props;

  const [dragging, setDragging] = useState(false);

  const onBeforeDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = (res: DropResult) => {
    setDragging(false);
    if (res.destination) {
      onChangeScenarioOrder(res.source.index, res.destination.index);
    }
  };

  return (
    <div>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
      >
        <Table responsive>
          <thead>
            <tr>
              <th> </th>
              <th>ID</th>
              <th>タイトル</th>
              <th>操作</th>
            </tr>
          </thead>
          <Droppable droppableId="scenarioListView">
            {(provided, _snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {value.map((scenario, i) => (
                  <Draggable
                    key={scenario.scenario_id}
                    draggableId={scenario.scenario_id}
                    index={i}
                  >
                    {(innerProvided, _innerSnapshot) => (
                      <tr
                        style={{ display: 'table' }}
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                      >
                        <td
                          {...innerProvided.dragHandleProps}
                          style={{ width: '20px' }}
                        >
                          <BsList />
                        </td>
                        <td style={{ width: '70px' }}>
                          {scenario.scenario_id}
                        </td>
                        <Td isDragging={dragging}>{scenario.title}</Td>
                        <td style={{ width: '100px' }}>
                          <Button
                            type="button"
                            size="sm"
                            onClick={() => onOpenScenario(scenario)}
                          >
                            開く
                          </Button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </div>
  );
}

export default function ArPackagePage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // parse ParamTypes
  const { itemId, packageId } = useParams<ParamTypes>();

  // initialize page
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(Number.parseInt(packageId, 10)));
  }, []);

  // store
  const arPackage = useSelector(selectArPackage);

  // memo
  const isInvalidMarkerless = useMemo(() => {
    if (!arPackage) {
      return false;
    }

    if (arPackage.scenarios.length > 0) {
      return (
        arPackage.scenarios.filter((scenario) => scenario.marker === null)
          .length > 0 && arPackage.scenarios.length > 1
      );
    }
    return false;
  }, [arPackage]);

  // internal states
  const [deleteModalShowing, setDeleteModalShowing] = useState(false);

  // callbacks
  const onSavePackage = async (p: M.ArPackage) => {
    await dispatch(updateArPackage(Number.parseInt(packageId, 10), p));
  };

  const onBackClicked = () => {
    history.push(`/items/${itemId}`);
  };

  const onChangeScenarioOrder = async (src: number, dst: number) => {
    await dispatch(
      changeScenarioOrder(Number.parseInt(packageId, 10), src, dst)
    );
  };

  const onAddScenarioClick = () => {
    history.push(`/items/${itemId}/arpackages/${packageId}/scenarios/add`);
  };

  const onOpenScenario = (scenario: M.Scenario) => {
    history.push(
      `/items/${itemId}/arpackages/${packageId}/scenarios/${scenario.scenario_id}`
    );
  };

  const onDeleteProceedClicked = async () => {
    await dispatch(deletePackage(Number.parseInt(packageId, 10)));
    history.push(`/items/${itemId}`);
  };

  const onDeleteClicked = () => {
    setDeleteModalShowing(true);
  };

  const onDeleteCancelled = () => {
    setDeleteModalShowing(false);
  };

  // render
  return (
    <Page>
      <DeleteModal
        objectName="パッケージ"
        show={deleteModalShowing}
        onCloseClicked={onDeleteCancelled}
        onDeleteClicked={onDeleteProceedClicked}
      />
      <PageTitle value="ARパッケージ" />
      <PageBody loading={!arPackage}>
        <Row className="mb-4">
          <ArPackageMetadataView
            value={arPackage!}
            onSubmitPackage={onSavePackage}
          />
        </Row>

        <Row className="mb-4">
          <h3 className="mb-2">シナリオ</h3>
          {isInvalidMarkerless && (
            <Alert variant="danger">
              警告：シナリオが複数あるにも関わらず、マーカーレスシナリオが存在するため、このパッケージは正しく動作しません。
              <br />
              シナリオを１つのみにするか、全てのシナリオにマーカーを設定してください。
            </Alert>
          )}
          <div>
            <Button variant="primary" onClick={onAddScenarioClick}>
              シナリオを追加する
            </Button>
          </div>
          <div>
            {arPackage && (
              <ScenarioListView
                value={arPackage.scenarios}
                onChangeScenarioOrder={onChangeScenarioOrder}
                onOpenScenario={onOpenScenario}
              />
            )}
          </div>
        </Row>

        <Row className="mb-4">
          <h3 className="mb-2">削除</h3>
          <div className="ms-3">
            <Button variant="danger" onClick={onDeleteClicked}>
              パッケージを削除する
            </Button>
          </div>
        </Row>

        <Button variant="secondary" onClick={onBackClicked}>
          戻る
        </Button>
      </PageBody>
    </Page>
  );
}
