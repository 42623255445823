import React, {
  FormEventHandler,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleTable, {
  SimpleTablePropTypes,
} from '../../../components/SimpleTable';
import {
  formatJSONDateYMD,
  getBODeathCertificateDownloadUrl,
} from '../../../util';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import {
  loadCompanies,
  loadUsers,
  selectCompanies,
  selectUsers,
  setUsers,
} from './bodeathcertificatesSlice';

export default function BODeathCertificateListPage() {
  // boilerplates
  const history = useHistory();
  const dispatch = useDispatch();

  // local state
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);

  // state
  const companies = useSelector(selectCompanies);
  const users = useSelector(selectUsers);

  // initialize
  useLayoutEffect(() => {
    dispatch(setUsers([]));
  }, []);

  useEffect(() => {
    dispatch(loadCompanies());
  }, []);

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(loadUsers(selectedCompanyId));
    }
  }, [selectedCompanyId]);

  // callbacks
  const onBackClick = () => {
    history.push('/bo/menu');
  };

  const onCompanySelect: FormEventHandler<HTMLSelectElement> = (e) => {
    const { value } = e.target as any;

    if (!value || value.length === 0) {
      return;
    }

    setSelectedCompanyId(Number.parseInt(value, 10));
  };

  const onDownloadClick = (userId: number) => () => {
    window.open(getBODeathCertificateDownloadUrl(userId));
  };

  // table
  const tableProps: SimpleTablePropTypes = {
    titles: {
      id: '#',
      loginId: 'ユーザ名',
      userName: '氏名',
      deathDate: '死亡日',
      deathCertificate: '死亡証明書',
    },
    values: users
      .filter((user) => !!user.death_date)
      .map((user) => ({
        id: user.id.toString(),
        loginId: user.login_id,
        userName: user.user_name,
        deathDate: formatJSONDateYMD(user.death_date!),
        deathCertificate: user.DeathCertificate ? (
          <Button
            size="sm"
            variant="secondary"
            onClick={onDownloadClick(user.id)}
          >
            ダウンロード
          </Button>
        ) : (
          '未アップロード'
        ),
      })),
  };

  return (
    <BOPage>
      <BOPageTitle>死亡証明書一覧</BOPageTitle>
      <BOPageBody>
        <div className="mb-3">
          <Row>
            <Form.Label column md={3}>
              企業グループ名
            </Form.Label>
            <Col md={5}>
              <Form.Select onChange={onCompanySelect}>
                <option value="">-</option>
                {companies.map((company) => (
                  <option key={company.id.toString()} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <SimpleTable {...tableProps} />
        </div>
        <div>
          <Button onClick={onBackClick} variant="secondary">
            戻る
          </Button>
        </div>
      </BOPageBody>
    </BOPage>
  );
}
