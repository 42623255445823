import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
// import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ContentsPropTypes } from './ContentPropTypes';
// import validationErrors from '../../../util/validationErrors';
import { JumpToMessageContent } from '../../common/models/ArPackage';
import { selectArPackage } from '../arPackageSlice';

export default function JumpToMessageComponent({
  scenarioId,
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as JumpToMessageContent;

  // boilerplate
  // const dispatch = useDispatch();

  // state
  const arPackage = useSelector(selectArPackage);
  if (!arPackage) {
    throw new Error('invalid state');
  }

  const scenario = arPackage.scenarios.find(s => s.scenario_id === scenarioId.toString());
  if (!scenario) {
    throw new Error('scenario not found');
  }

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      message_id: Yup.string().required('メッセージが未設定です'),
    }),
    initialValues: {
      message_id: c.message_id,
    },
    onSubmit: (val) => {
      const newContent: JumpToMessageContent = {
        ...c,
        message_id: val.message_id
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: false,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={2}>メッセージ</Form.Label>
            <Col lg={5}>
              <Form.Select
                name="message_id"
                value={formik.values.message_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.message_id && !formik.errors.message_id}
                isInvalid={formik.touched.message_id && !!formik.errors.message_id}
              >
                <option value="">-</option>
                {scenario.messages.map(message => (
                  <option
                    key={`messages.${message.message_id}`}
                    value={message.message_id}
                  >
                    {message.message_id}: {message.title}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Alert className="mt-3" variant="danger" show={!!formik.errors.message_id}>
          {formik.errors.message_id}
        </Alert>

        <div className="mt-5">
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
