import React, { useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import { getSelectedItem, selectSelectedItem, updateSelectedItem } from './itemsSlice';
import validationErrors from '../../util/validationErrors';
import { formatJSONDate, parseJSONDate } from '../../util';

interface ParamTypes {
  id: string;
}

export default function ItemEditPage() {
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  const item = useSelector(selectSelectedItem);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedItem(Number.parseInt(id, 10)));
  }, []);

  const onCancelClicked = () => {
    history.push(`/items/${id}`);
  };

  const onSubmit = async (data: any) => {
    await dispatch(
      updateSelectedItem({ id, ...data, expiresAt: data.expiresAt === '' ? null : parseJSONDate(data.expiresAt) })
    );
    history.push(`/items/${id}`);
  };

  return (
    <Page>
      <PageTitle value="アイテム情報編集" />
      <PageBody loading={!item}>
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required(validationErrors.required()).max(255, validationErrors.max(255)),
            expiresAt: Yup.string().matches(
              /\d\d\d\d-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}/,
              '空欄か 2022-01-02 11:20:15 の形式で入力してください'
            ),
          })}
          onSubmit={onSubmit}
          initialValues={{
            title: item ? item.title : '',
            visible: item ? item.visible : false,
            allowNameSearch: item ? item.allow_name_search : false,
            expiresAt: item && item.expires_at ? formatJSONDate(item.expires_at) : '',
          }}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Row className="mb-3">
                  <Form.Label column lg={3}>
                    アイテム名
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      isValid={touched.title && !errors.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={3}>全体に公開</Col>
                  <Col>
                    <Form.Check name="visible" onBlur={handleBlur} onChange={handleChange} checked={values.visible} />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={3}>検索を許可</Col>
                  <Col>
                    <Form.Check
                      name="allowNameSearch"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.allowNameSearch}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>有効期限</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="expiresAt"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.expiresAt}
                      isValid={touched.expiresAt && !errors.expiresAt}
                      isInvalid={touched.expiresAt && !!errors.expiresAt}
                      placeholder="例）2022-01-02 11:20:15 または 空欄"
                    />
                    <Form.Control.Feedback type="invalid">{errors.expiresAt}</Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Button variant="primary" type="submit" className="me-2">
                編集
              </Button>
              <Button variant="secondary" type="button" onClick={onCancelClicked}>
                キャンセル
              </Button>
            </Form>
          )}
        </Formik>
      </PageBody>
    </Page>
  );
}
