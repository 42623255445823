import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import validationErrors from '../../util/validationErrors';
import PageBody from '../common/PageBody';
import { createArPackage, setArPackage, setPackageId } from './arPackageSlice';

type ParamTypes = {
  itemId: string;
};

export default function ArPackageAddPage() {
  // boilerplate
  const history = useHistory();
  const dispatch = useDispatch();

  // parse ParamTypes
  const { itemId } = useParams<ParamTypes>();

  // refresh store
  useEffect(() => {
    dispatch(setPackageId());
    dispatch(setArPackage());
  }, []);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
    }),
    initialValues: { title: '' },
    onSubmit: async (values) => {
      await dispatch(
        createArPackage(
          Number.parseInt(itemId, 10),
          values.title,
          (packageId: number) => {
            history.push(`/items/${itemId}/arpackages/${packageId}`);
          }
        )
      );
    },
  });

  // callbacks
  const onCancel = () => {
    history.push(`/items/${itemId}`);
  };

  // render
  return (
    <Page>
      <PageTitle value="ARパッケージ追加" />
      <PageBody>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                パッケージ名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.touched.title && !formik.errors.title}
                  isInvalid={formik.touched.title && !!formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Button variant="primary" type="submit" className="me-2">
            追加
          </Button>
          <Button variant="secondary" type="button" onClick={onCancel}>
            キャンセル
          </Button>
        </Form>
      </PageBody>
    </Page>
  );
}
