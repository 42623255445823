import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  setCommonError,
  withLoading,
} from '../../../features/common/commonSlice';
import boApiClient, {
  ChangeAdminPasswordRequest,
  PostCompanyRequest,
  PostCompanyResponse,
} from '../bocommon/boApiClient';
import { Company } from '../bocommon/models';

// State
export interface BOCompaniesState {
  companies: Company[];

  createCompanyResponse?: PostCompanyResponse;
}

// InitialState
const initialState: BOCompaniesState = {
  companies: [],
};

// Selectors
export const selectCompanies = (state: RootState) =>
  state.bocompanies.companies;
export const selectCreateCompanyResponse = (state: RootState) =>
  state.bocompanies.createCompanyResponse;

// Thunks
export const loadCompanies = () =>
  withLoading('bocompaniesSlice.loadCompanies', async (dispatch) => {
    const companies = await boApiClient.getCompanies(dispatch);

    dispatch(bocompaniesSlice.actions.setCompanies(companies));
  });

export const createCompany = (req: PostCompanyRequest) =>
  withLoading('bocompaniesSlice.createCompany', async (dispatch) => {
    try {
      const res = await boApiClient.postCompany(dispatch, req);
      dispatch(bocompaniesSlice.actions.setCreateCompanyResponse(res));
    } catch (err) {
      dispatch(setCommonError('企業グループの作成に失敗しました'));
    }
  });

export const changeAdminPassword = (
  companyId: number,
  req: ChangeAdminPasswordRequest
) =>
  withLoading('bocompaniesSlice.changeAdminPassword', async (dispatch) => {
    try {
      await boApiClient.changeAdminPassword(dispatch, companyId, req);
    } catch (err) {
      dispatch(setCommonError('パスワードの変更に失敗しました'));
    }
  });

// Slice
export const bocompaniesSlice = createSlice({
  name: 'bocompanies',
  initialState,
  reducers: {
    setCompanies: (state, payload: PayloadAction<Company[]>) => ({
      ...state,
      companies: payload.payload,
    }),
    setCreateCompanyResponse: (
      state,
      payload: PayloadAction<PostCompanyResponse>
    ) => ({
      ...state,
      createCompanyResponse: payload.payload,
    }),
  },
});

// Exports
export default bocompaniesSlice.reducer;
