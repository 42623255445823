import { useFormik } from 'formik';
import React, { useLayoutEffect, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import {
  loadArPackage,
  selectArPackage,
  setArPackage,
  updateArPackage,
} from './arPackageSlice';
import validationErrors from '../../util/validationErrors';
import { avators } from '../common/codemasters/Avators';
import { ArPackage, createDefaultScenario } from '../common/models/ArPackage';

type ParamTypes = {
  itemId: string;
  packageId: string;
};

export default function ScenarioAddPage() {
  // boilerplate
  const dispatch = useDispatch();
  const history = useHistory();

  // parse path params
  const { itemId, packageId } = useParams<ParamTypes>();

  // initialize page
  useLayoutEffect(() => {
    dispatch(setArPackage());
  }, []);

  useEffect(() => {
    dispatch(loadArPackage(Number.parseInt(packageId, 10)));
  }, []);

  // store
  const arPackage = useSelector(selectArPackage);

  // Formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
      avator_name_id: Yup.string().required(),
    }),
    initialValues: { title: '', avator_name_id: 'empty' },
    onSubmit: async (values) => {
      if (!arPackage) {
        throw new Error('arPackage is null or undefined');
      }

      const avator = avators.filter(
        (a) => a.name_id === values.avator_name_id
      )[0];

      const scenarioId =
        Math.max(
          ...arPackage.scenarios.map((s) => Number.parseInt(s.scenario_id, 10)),
          0
        ) + 1;

      const newArPackage: ArPackage = {
        ...arPackage,
        scenarios: [
          ...arPackage.scenarios,
          createDefaultScenario(values.title, avator, scenarioId.toString()),
        ],
      };

      await dispatch(
        updateArPackage(Number.parseInt(packageId, 10), newArPackage, () => {
          history.push(
            `/items/${itemId}/arpackages/${packageId}/scenarios/${scenarioId}`
          );
        })
      );
    },
  });

  // callbacks
  const onCancel = () => {
    history.push(`/items/${itemId}/arpackages/${packageId}`);
  };

  // view components
  const avatorPullDown = (
    <Form.Select
      name="avator_name_id"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.avator_name_id}
      isValid={formik.touched.avator_name_id && !formik.errors.avator_name_id}
      isInvalid={
        formik.touched.avator_name_id && !!formik.errors.avator_name_id
      }
    >
      {avators.map((avator) => (
        <option key={avator.name_id} value={avator.name_id}>
          {avator.name_id}
        </option>
      ))}
    </Form.Select>
  );

  // render
  return (
    <Page>
      <PageTitle value="シナリオ追加" />
      <PageBody loading={!arPackage}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                シナリオ名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.touched.title && !formik.errors.title}
                  isInvalid={formik.touched.title && !!formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                アバター
              </Form.Label>
              <Col md={5}>
                {avatorPullDown}
                <Form.Control.Feedback type="invalid">
                  {formik.errors.avator_name_id}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Button type="submit" variant="primary" className="me-2">
            追加
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </Form>
      </PageBody>
    </Page>
  );
}
