import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import LoginPage from '../features/login/LoginPage';
import ItemListPage from '../features/items/ItemListPage';
import Header from '../features/common/Header';
import Sidebar from '../components/Sidebar';
import ItemAddPage from '../features/items/ItemAddPage';
import ScreenLock from '../components/ScreenLock';
import { isLoadingSelector } from '../features/common/commonSlice';
import CommonErrorModal from '../features/common/CommonErrorModal';
import { Auth } from '../features/common/Auth';
import UserCreatePage from '../features/login/UserCreatePage';
import ItemPage from '../features/items/ItemPage';
import ItemEditPage from '../features/items/ItemEditPage';
import DataPackageEditPage from '../features/data-package/DataPackageEditPage';
import ArPackageAddPage from '../features/ar-package/ArPackageAddPage';
import ArPackagePage from '../features/ar-package/ArPackagePage';
import ScenarioAddPage from '../features/ar-package/ScenarioAddPage';
import ScenarioPage from '../features/ar-package/ScenarioPage';
import ItemViewerListPage from '../features/items/ItemViewerListPage';
import MessageAddPage from '../features/ar-package/MessageAddPage';
import MessagePage from '../features/ar-package/MessagePage';
import ContentEditPage from '../features/ar-package/ContentEditPage';
import AddressListPage from '../features/address/AddressListPage';
import SendItemRequestPage from '../features/send-item-request/SendItemRequestPage';
import ItemNotificationPage from '../features/items/ItemNotificationPage';
import ArPackageAnswerListPage from '../features/ar-package/ArPackageAnswerListPage';
import BOLoginPage from '../bo/features/bologin/BOLoginPage';
import BOAuth from '../bo/features/bocommon/BOAuth';
import BOMenu from '../bo/features/bomenu/BOMenu';
import BOHeader from '../bo/features/bocommon/BOHeader';
import BOCompanyListPage from '../bo/features/bocompanies/BOCompanyListPage';
import BOCompanyAddPage from '../bo/features/bocompanies/BOCompanyAddPage';
import BOCompanyAddCompletePage from '../bo/features/bocompanies/BOCompanyAddCompletePage';
import BODeathCertificateListPage from '../bo/features/bodeathcertificates/BODeathCertificateListPage';
import UserListPage from '../features/users/UserListPage';
import SendingListPage from '../features/sendings/SendingListPage';
import BOProjectListPage from '../bo/features/boprojects/BOProjectListPage';
import BOProjectEditPage from '../bo/features/boprojects/BOProjectEditPage';
import AppUsersPage from '../features/app-users/AppUsersPage';
import UserInfoPage from '../features/userinfo/UserInfoPage';

function App() {
  const isLoading = useSelector(isLoadingSelector);

  return (
    <Container fluid className="no-padding">
      <ScreenLock enabled={isLoading} />
      <CommonErrorModal />

      <Router>
        <Switch>
          {/* BO管理 */}
          <Route path="/bo/*">
            <BOHeader />

            <Switch>
              <Route exact path="/bo/login">
                <BOLoginPage />
              </Route>

              <BOAuth>
                <Switch>
                  {/* メニュー */}
                  <Route exact path="/bo/menu">
                    <BOMenu />
                  </Route>

                  {/* 死亡証明書 */}
                  <Route exact path="/bo/deathcertificates">
                    <BODeathCertificateListPage />
                  </Route>

                  {/* プロジェクト管理 */}
                  <Route exact path="/bo/projects/add">
                    <BOProjectEditPage mode="CREATE" />
                  </Route>

                  <Route exact path="/bo/projects/:projectId">
                    <BOProjectEditPage mode="EDIT" />
                  </Route>

                  <Route exact path="/bo/projects">
                    <BOProjectListPage />
                  </Route>

                  {/* 企業グループ管理 */}
                  <Route exact path="/bo/companies/addcomplete">
                    <BOCompanyAddCompletePage />
                  </Route>

                  <Route exact path="/bo/companies/add">
                    <BOCompanyAddPage />
                  </Route>

                  <Route exact path="/bo/companies">
                    <BOCompanyListPage />
                  </Route>
                </Switch>
              </BOAuth>
            </Switch>
          </Route>

          <Route>
            <Header />

            <Switch>
              {/* ログインページ */}
              <Route path="/login">
                <LoginPage />
              </Route>

              {/* ユーザ新規作成ページ */}
              <Route path="/users/new">
                <UserCreatePage />
              </Route>

              <Auth>
                <Route>
                  <Container fluid>
                    <Row>
                      <Col xs="12" md="3" lg="2" className="menu">
                        <Sidebar />
                      </Col>
                      <Col>
                        <Container className="contents">
                          <Switch>
                            {/* アイテム送信関連機能 */}
                            <Route exact path="/senditemrequest">
                              <SendItemRequestPage />
                            </Route>

                            {/* 送信一覧機能 */}
                            <Route exact path="/sendings">
                              <SendingListPage />
                            </Route>

                            {/* 送信宛先管理関連機能 */}
                            <Route exact path="/addresses">
                              <AddressListPage />
                            </Route>

                            {/* コンソールユーザー機能 */}
                            <Route exact path="/users">
                              <UserListPage />
                            </Route>

                            {/* アプリユーザー機能 */}
                            <Route exact path="/appusers">
                              <AppUsersPage />
                            </Route>

                            {/* ARパッケージ関連機能 */}
                            <Route
                              exact
                              path="/items/:itemId/arpackages/:packageId/scenarios/:scenarioId/messages/:messageId/contents/add"
                            >
                              <ContentEditPage createContent />
                            </Route>
                            <Route
                              exact
                              path="/items/:itemId/arpackages/:packageId/scenarios/:scenarioId/messages/:messageId/contents/:orderId"
                            >
                              <ContentEditPage createContent={false} />
                            </Route>
                            <Route exact path="/items/:itemId/arpackages/:packageId/scenarios/:scenarioId/messages/add">
                              <MessageAddPage />
                            </Route>
                            <Route
                              exact
                              path="/items/:itemId/arpackages/:packageId/scenarios/:scenarioId/messages/:messageId"
                            >
                              <MessagePage />
                            </Route>
                            <Route exact path="/items/:itemId/arpackages/add">
                              <ArPackageAddPage />
                            </Route>
                            <Route exact path="/items/:itemId/arpackages/:packageId/answers">
                              <ArPackageAnswerListPage />
                            </Route>
                            <Route exact path="/items/:itemId/arpackages/:packageId/scenarios/add">
                              <ScenarioAddPage />
                            </Route>

                            <Route exact path="/items/:itemId/arpackages/:packageId/scenarios/:scenarioId">
                              <ScenarioPage />
                            </Route>

                            <Route exact path="/items/:itemId/arpackages/:packageId">
                              <ArPackagePage />
                            </Route>

                            {/* データパッケージ関連機能 */}
                            <Route exact path="/items/:itemId/datapackages/:packageId/edit">
                              <DataPackageEditPage newPackage={false} />
                            </Route>
                            <Route exact path="/items/:itemId/datapackages/add">
                              <DataPackageEditPage newPackage />
                            </Route>

                            {/* アイテム関連機能 */}
                            <Route exact path="/items/add">
                              <ItemAddPage />
                            </Route>
                            <Route exact path="/items/:id/notification">
                              <ItemNotificationPage />
                            </Route>
                            <Route exact path="/items/:id/edit">
                              <ItemEditPage />
                            </Route>
                            <Route exact path="/items/:id/viewers">
                              <ItemViewerListPage />
                            </Route>
                            <Route exact path="/items/:id">
                              <ItemPage />
                            </Route>
                            <Route exact path="/items">
                              <ItemListPage />
                            </Route>

                            {/* ユーザ情報設定 */}
                            <Route exact path="/users/userinfo">
                              <UserInfoPage />
                            </Route>

                            {/* 存在しないURLが指定された場合はアイテム一覧画面にリダイレクト */}
                            <Route>
                              <Redirect to="/items" />
                            </Route>
                          </Switch>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Route>
              </Auth>
            </Switch>
          </Route>
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
