import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  setCommonError,
  withLoading,
} from '../../../features/common/commonSlice';
import boApiClient from './boApiClient';
import { Project } from './models';

type SessionStatus = 'LOGOUT' | 'CHECKING' | 'OK';

// State
export interface BOCommonState {
  sessionStatus: SessionStatus;
  projects: Project[];
}

// Initial State
const initialState: BOCommonState = {
  sessionStatus: 'CHECKING',
  projects: [],
};

// Selector
export const selectSessionStatus = (state: RootState) =>
  state.bocommon.sessionStatus;
export const selectProjects = (state: RootState) => state.bocommon.projects;
export const selectProject = (id: string) => (state: RootState) =>
  state.bocommon.projects.find((project) => project.id === id);

// Thunks
export const login = (password: string) =>
  withLoading('bocommonSlice.login', async (dispatch) => {
    try {
      await boApiClient.login(dispatch, password);

      dispatch(setSessionStatus('OK'));
    } catch (err) {
      dispatch(setCommonError('パスワードが間違っています'));
    }
  });

export const loadProjects = () =>
  withLoading('bocommonSlice.loadProjects', async (dispatch) => {
    const res = await boApiClient.getProjects(dispatch);
    dispatch(setProjects(res.projects));
  });

export const checkSession = () =>
  withLoading('bocommonSlice.checkSession', async (dispatch) => {
    try {
      dispatch(setSessionStatus('CHECKING'));
      await boApiClient.checkSession(dispatch);
      dispatch(setSessionStatus('OK'));
    } catch (err) {
      dispatch(setSessionStatus('LOGOUT'));
    }
  });

// Slice
export const bocommonSlice = createSlice({
  name: 'bocommon',
  initialState,
  reducers: {
    setSessionStatus: (state, payload: PayloadAction<SessionStatus>) => ({
      ...state,
      sessionStatus: payload.payload,
    }),
    setProjects: (state, payload: PayloadAction<Project[]>) => ({
      ...state,
      projects: payload.payload,
    }),
  },
});

export const { setSessionStatus, setProjects } = bocommonSlice.actions;
export default bocommonSlice.reducer;
