import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
// import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ContentsPropTypes } from './ContentPropTypes';
// import validationErrors from '../../../util/validationErrors';
import { NoteContent } from '../../common/models/ArPackage';
import SingleFileUpload from '../../../components/SingleFileUpload';
import {
  selectUploadedFileId,
  setUploadedFileId,
  uploadFile,
} from '../arPackageSlice';
import { getAppDownloadUrl } from '../../../util';

export default function NoteComponent({
  packageId,
  content,
  onSubmitContent,
  onCancel,
}: ContentsPropTypes) {
  const c = content as NoteContent;

  // boilerplate
  const dispatch = useDispatch();

  // state
  const uploadedFileId = useSelector(selectUploadedFileId);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      image_url: Yup.string(),
      body: Yup.string().required('内容が未設定です'),
    }),
    initialValues: {
      image_url: c.image?.image_url,
      body: c.body,
    },
    onSubmit: (val) => {
      const newContent: NoteContent = {
        ...c,
        image: val.image_url ? { image_url: val.image_url } : null,
        body: val.body,
      };
      onSubmitContent(newContent);
    },
    enableReinitialize: true,
  });

  // callbacks
  const onUploadClicked = async (file: File) => {
    await dispatch(uploadFile(packageId, file));
  };

  useEffect(() => {
    if (uploadedFileId) {
      formik.setFieldValue(
        'image_url',
        getAppDownloadUrl(packageId, uploadedFileId)
      );
      dispatch(setUploadedFileId());
    }
  }, [uploadedFileId]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <SingleFileUpload
          title="画像"
          noUploadText="未設定"
          uploadedText="設定済み"
          showIcon
          isUploaded={!!formik.values.image_url}
          onUpload={onUploadClicked}
        />
        <Form.Group className="mb-3">
          <Row>
            <Form.Label column lg={2}>
              内容
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                as="textarea"
                rows={3}
                name="body"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.body}
                isValid={formik.touched.body && !formik.errors.body}
                isInvalid={formik.touched.body && !!formik.errors.body}
              />
            </Col>
          </Row>
        </Form.Group>
        <Alert className="mt-3" variant="danger" show={!!formik.errors.body}>
          {formik.errors.body}
        </Alert>

        <div className="mt-5">
          <Button type="submit" variant="primary" className="me-2">
            保存
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
