import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch, RootState } from '../../app/store';
import { withLoading } from '../common/commonSlice';
import { Address, AppUser, Item, SendItemRequest, SendItemRequestWrapper } from '../common/models';

// State
export interface SendItemRequestState {
  sendItemRequests: SendItemRequestWrapper[] | null;
  items: Item[] | null;
  addresses: Address[] | null;
  subscribers: AppUser[];
}

// Initial State
const initialState: SendItemRequestState = {
  sendItemRequests: null,
  items: null,
  addresses: null,
  subscribers: [],
};

// Selectors
export const selectSendItemRequests = (state: RootState) => state.sendItemRequest.sendItemRequests;
export const selectItems = (state: RootState) => state.sendItemRequest.items;
export const selectAddresses = (state: RootState) => state.sendItemRequest.addresses;
export const selectSubscribers = (state: RootState) => state.sendItemRequest.subscribers;

// Thunks
export const initPage = () => async (dispatch: AppDispatch) => {
  {
    const res = await axios.get<{ items: Item[] }>(`${process.env.REACT_APP_API_BASEURL}/v1/console/items`);
    dispatch(sendItemRequestSlice.actions.setItems(res.data.items));
  }
  {
    const res = await axios.get<{ addresses: Address[] }>(`${process.env.REACT_APP_API_BASEURL}/v1/console/addresses`);
    dispatch(sendItemRequestSlice.actions.setAddresses(res.data.addresses));
  }
  {
    const res = await axios.get<{ appUsers: AppUser[] }>(
      `${process.env.REACT_APP_API_BASEURL}/v1/console/users/me/app_users_subscribing`
    );
    dispatch(sendItemRequestSlice.actions.setSubscribers(res.data.appUsers));
  }
  updateSendItemRequestsInternal(dispatch);
};

const updateSendItemRequestsInternal = async (dispatch: AppDispatch) => {
  type Response = { senditemrequests: SendItemRequestWrapper[] };
  const url = `${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests`;
  const res = await axios.get<Response>(url);
  await dispatch(sendItemRequestSlice.actions.setSendItemRequests(res.data.senditemrequests));
};

export const updateSendItemRequests = () =>
  withLoading('sendItemRequestSlice.updateSendItemRequests', updateSendItemRequestsInternal);

export const completeSendItemRequest = (sendItemRequest: SendItemRequest) =>
  withLoading('sendItemRequestSlice.completeSendItemRequest', async (dispatch) => {
    await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests/${sendItemRequest.id}/complete`);
    dispatch(updateSendItemRequestsInternal);
  });

export const createSendItemRequest = (sendItemRequest: SendItemRequest) =>
  withLoading('sendItemRequestSlice.createSendItemRequest', async (dispatch: AppDispatch) => {
    const req = {
      itemId: sendItemRequest.item_id,
      addressId: sendItemRequest.address_id,
      appUserId: sendItemRequest.app_user_id,
      sendMethod: sendItemRequest.send_method,
      sendCondition: sendItemRequest.send_condition,
      sendDate: sendItemRequest.send_date,
      sendDaysAfterDeath: sendItemRequest.send_days_after_death,
    };

    await axios.post(`${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests`, req);

    await dispatch(initPage());
  });

export const putSendItemRequest = (sendItemRequest: SendItemRequest) =>
  withLoading('sendItemRequestSlice.putSendItemRequest', async (dispatch: AppDispatch) => {
    const req = {
      itemId: sendItemRequest.item_id,
      addressId: sendItemRequest.address_id,
      appUserId: sendItemRequest.app_user_id,
      sendMethod: sendItemRequest.send_method,
      sendCondition: sendItemRequest.send_condition,
      sendDate: sendItemRequest.send_date,
      sendDaysAfterDeath: sendItemRequest.send_days_after_death,
    };

    await axios.put(`${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests/${sendItemRequest.id}`, req);

    await dispatch(initPage());
  });

export const deleteSendItemRequest = (sendItemRequest: SendItemRequest) =>
  withLoading('sendItemRequestSlice.putSendItemRequest', async (dispatch: AppDispatch) => {
    await axios.delete(`${process.env.REACT_APP_API_BASEURL}/v1/console/senditemrequests/${sendItemRequest.id}`);

    await dispatch(initPage());
  });

// Slice
export const sendItemRequestSlice = createSlice({
  name: 'sendItemRequest',
  initialState,
  reducers: {
    setSendItemRequests: (state, payload: PayloadAction<SendItemRequestWrapper[]>) => ({
      ...state,
      sendItemRequests: payload.payload,
    }),
    setItems: (state, payload: PayloadAction<Item[]>) => ({
      ...state,
      items: payload.payload,
    }),
    setAddresses: (state, payload: PayloadAction<Address[]>) => ({
      ...state,
      addresses: payload.payload,
    }),
    setSubscribers: (state, payload: PayloadAction<AppUser[]>) => ({
      ...state,
      subscribers: payload.payload,
    }),
  },
});

// Exports
export default sendItemRequestSlice.reducer;
