import { useFormik } from 'formik';
import React, { useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import DeleteModal from '../../components/DeleteModal';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import Pagenator from '../../components/Paginator';
import SimpleTable, {
  SimpleTablePropTypes,
} from '../../components/SimpleTable';
import validationErrors from '../../util/validationErrors';
import { Address } from '../common/models';
import PageBody from '../common/PageBody';
import {
  createAddress,
  deleteAddress,
  editAddress,
  getAddresses,
  selectCurrentAddresses,
  selectCurrentPage,
  selectTotalPageCount,
} from './addressSlice';

type EditAddressModalPropTypes = {
  show: boolean;
  address: Address;
  onEdit: (_address: Address) => any;
  onCancel: () => any;
};

function EditAddressModal({
  show,
  address,
  onEdit,
  onCancel,
}: EditAddressModalPropTypes) {
  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(validationErrors.required())
        .max(255, validationErrors.max(255)),
      email: Yup.string()
        .email(validationErrors.email())
        // eslint-disable-next-line func-names
        .test('email', '住所かEメールのいずれかが必須です', function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          if (!this.parent.address && !value) {
            return false;
          }
          return true;
        }),
      address: Yup.string()
        .max(255, validationErrors.max(255)) // eslint-disable-next-line func-names
        .test('address', '住所かEメールのいずれかが必須です', function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          if (!this.parent.email && !value) {
            return false;
          }
          return true;
        }),
    }),
    initialValues: {
      name: address.name,
      email: address.email,
      address: address.address,
    },
    onSubmit: (value) => {
      const newAddress: Address = {
        ...address,
        name: value.name,
        email: value.email,
        address: value.address,
      };
      onEdit(newAddress);
    },
  });

  return (
    <Modal show={show}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                氏名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isValid={formik.touched.name && !formik.errors.name}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                Eメール
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  type="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  isValid={formik.touched.email && !formik.errors.email}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                住所
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  type="text"
                  name="address"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  isValid={formik.touched.address && !formik.errors.address}
                  isInvalid={formik.touched.address && !!formik.errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            キャンセル
          </Button>
          <Button type="submit" variant="primary">
            保存
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

type EditMode = 'CREATE' | 'EDIT' | null;

export default function AddressListPage() {
  // boilerplate
  const dispatch = useDispatch();

  // init
  useLayoutEffect(() => {
    dispatch(getAddresses(0, true));
  }, []);

  // redux state
  const currentAddresses = useSelector(selectCurrentAddresses);
  const totalPageCount = useSelector(selectTotalPageCount);
  const currentPage = useSelector(selectCurrentPage);

  // internal state
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const [deletingAddress, setDeletingAddress] = useState<Address | null>(null);
  const [editMode, setEditMode] = useState<EditMode>(null);
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);

  // callbacks
  const onPageChange = async (args: { selected: number }) => {
    await dispatch(getAddresses(args.selected));
  };

  const onCreateClicked = () => {
    setEditMode('CREATE');
    setEditingAddress({
      name: '',
      email: '',
      address: '',

      id: 0,
      createdAt: '',
      updatedAt: '',
    });
  };

  const onEditClicked = (address: Address) => {
    setEditingAddress(address);
    setEditMode('EDIT');
  };

  const onCancelEditClicked = () => {
    setEditMode(null);
    setEditingAddress(null);
  };

  const onEditConfirmed = async (address: Address) => {
    if (editMode === 'CREATE') {
      await dispatch(createAddress(address));
    } else {
      await dispatch(editAddress(address));
    }

    setEditMode(null);
    setEditingAddress(null);
  };

  const onDeleteClicked = async (address: Address) => {
    setDeletingAddress(address);
    setShowingDeleteModal(true);
  };

  const onDeleteCancelled = () => {
    setDeletingAddress(null);
    setShowingDeleteModal(false);
  };

  const onDeleteConfirmed = async () => {
    if (!deletingAddress) {
      return;
    }

    await dispatch(deleteAddress(deletingAddress));
    setDeletingAddress(null);
    setShowingDeleteModal(false);
  };

  // table props
  const tableProps: SimpleTablePropTypes = {
    titles: {
      name: '氏名',
      email: 'Eメール',
      address: '住所',
      operation: '操作',
    },
    values: (currentAddresses || []).map((address) => ({
      id: address.id.toString(),
      name: address.name,
      email: address.email,
      address: address.address,
      operation: (
        <>
          <Button
            size="sm"
            className="me-1"
            variant="secondary"
            onClick={() => onEditClicked(address)}
          >
            編集
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => onDeleteClicked(address)}
          >
            削除
          </Button>
        </>
      ),
    })),
    styles: {
      operation: { width: '120px' },
    },
  };

  return (
    <Page>
      <DeleteModal
        objectName="宛先"
        onCloseClicked={onDeleteCancelled}
        onDeleteClicked={onDeleteConfirmed}
        show={showingDeleteModal}
      />

      {editingAddress && (
        <EditAddressModal
          show={!!editMode}
          address={editingAddress}
          onEdit={onEditConfirmed}
          onCancel={onCancelEditClicked}
        />
      )}

      <PageTitle value="送信宛先管理" />
      <PageBody loading={!currentAddresses}>
        <div className="mb-4">
          <Button onClick={onCreateClicked}>送信宛先追加</Button>
        </div>
        <div>
          <Pagenator
            pageCount={totalPageCount}
            marginPagesDisplayed={isMobile ? 1 : 2}
            pageRangeDisplayed={isMobile ? 2 : 3}
            forcePage={currentPage}
            onPageChange={onPageChange}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <SimpleTable {...tableProps} />
        </div>
      </PageBody>
    </Page>
  );
}
