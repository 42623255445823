import axios from 'axios';
import { AppDispatch } from '../../../app/store';
import { Company, ConsoleUser, Project } from './models';

export interface PostCompanyRequest {
  projectId: string;
  groupType: string;
  loginId: string;
  groupName: string;
}

export interface PostCompanyResponse {
  group: Company;
  password: string;
}

export interface ChangeAdminPasswordRequest {
  password: string;
}

export interface GetCompanyUsersResponse {
  users: ConsoleUser[];
}

export interface GetProjectsResponse {
  projects: Project[];
}

export interface PostProjectRequest {
  id: string;
  name: string;
  catchphrase: string;
  headerImgKey?: string;
}

export interface PostProjectResponse {
  project: Project;
}

export interface PatchProjectRequest extends PostProjectRequest {}

export interface PatchProjectResponse {
  project: Project;
}

export interface UploadProjectRequest {
  file: File;
}

export interface UploadProjectResponse {
  keys: string[];
}

class BOAPIClient {
  protected baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async login(_dispatch: AppDispatch, password: string) {
    await axios.post(`${this.baseUrl}/v1/bo/login`, {
      password,
    });
  }

  async checkSession(_dispatch: AppDispatch) {
    await axios.get(`${this.baseUrl}/v1/bo/checksession`);
  }

  async getCompanies(_dispatch: AppDispatch): Promise<Company[]> {
    const res = await axios.get<{ groups: Company[] }>(
      `${this.baseUrl}/v1/bo/groups`
    );
    return res.data.groups;
  }

  async postCompany(
    _dispatch: AppDispatch,
    req: PostCompanyRequest
  ): Promise<PostCompanyResponse> {
    const res = await axios.post<PostCompanyResponse>(
      `${this.baseUrl}/v1/bo/groups`,
      req
    );
    return res.data;
  }

  async changeAdminPassword(
    _dispatch: AppDispatch,
    companyId: number,
    req: ChangeAdminPasswordRequest
  ): Promise<void> {
    await axios.post(
      `${this.baseUrl}/v1/bo/groups/${companyId}/adminpassword`,
      req
    );
  }

  async getCompanyUsers(
    _dispatch: AppDispatch,
    companyId: number
  ): Promise<GetCompanyUsersResponse> {
    const res = await axios.get<GetCompanyUsersResponse>(
      `${this.baseUrl}/v1/bo/groups/${companyId}/users`
    );
    return res.data;
  }

  async getProjects(_dispatch: AppDispatch): Promise<GetProjectsResponse> {
    const res = await axios.get<GetProjectsResponse>(
      `${this.baseUrl}/v1/bo/projects`
    );
    return res.data;
  }

  async postProject(
    _dispatch: AppDispatch,
    req: PostProjectRequest
  ): Promise<PostProjectResponse> {
    const res = await axios.post<PostProjectResponse>(
      `${this.baseUrl}/v1/bo/projects/${req.id}`,
      req
    );
    return res.data;
  }

  async patchProject(
    _dispatch: AppDispatch,
    req: PatchProjectRequest
  ): Promise<PatchProjectResponse> {
    const res = await axios.patch<PatchProjectResponse>(
      `${this.baseUrl}/v1/bo/projects/${req.id}`,
      req
    );
    return res.data;
  }

  async uploadProjectFile(
    _dispatch: AppDispatch,
    req: UploadProjectRequest
  ): Promise<UploadProjectResponse> {
    const form = new FormData();
    form.append('files', req.file);

    const res = await axios.post<UploadProjectResponse>(
      `${this.baseUrl}/v1/bo-file/projects/upload`,
      form,
      { headers: { 'content-type': 'multipart/form-data' } }
    );
    return res.data;
  }
}

export default new BOAPIClient(process.env.REACT_APP_API_BASEURL!);
