import React from 'react';
import { AppUser } from '../../common/models';
import SimpleTable from '../../../components/SimpleTable';
import { formatJSONDate } from '../../../util';

export interface AppUsersTableProps {
  appUsers: AppUser[];
}

function showGender(str?: string) {
  if (!str) {
    return '';
  }

  if (str === 'male') {
    return '男性';
  }
  if (str === 'female') {
    return '女性';
  }
  return 'その他';
}

export default function AppUsersTable({ appUsers }: AppUsersTableProps) {
  const titles = {
    id: 'ID',
    name: '名前',
    email: 'メールアドレス',
    fullname: '氏名',
    fullname_kana: '氏名(かな)',
    address: '住所',
    phone_number: '電話番号',
    age: '年齢',
    gender: '性別',
    created_at: '登録日',
  };

  const values = appUsers.map((user) => ({
    ...user.pii,
    gender: showGender(user.pii?.gender),
    ...user,
    id: user.id.toString(),
    created_at: formatJSONDate(user.created_at),
  }));

  return <SimpleTable titles={titles} values={values} />;
}
