/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import PageBody from '../common/PageBody';
import * as util from '../../util';
import {
  deleteItem,
  getSelectedItem,
  selectPackages,
  selectSelectedItem,
  selectSelectedItemCode,
  selectSelectedItemNotifications,
  selectSelectedItemStats,
} from './itemsSlice';
import { ItemNotification } from '../common/models';
import SimpleTable, { SimpleTablePropTypes } from '../../components/SimpleTable';
import DeleteModal from '../../components/DeleteModal';

interface NotificationDetailModalProps {
  onCloseClick: () => any;
  notification?: ItemNotification | undefined;
}

function NotificationDetailModal({ notification, onCloseClick }: NotificationDetailModalProps) {
  if (!notification) {
    return <Modal show={false} />;
  }

  // callbacks

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>お知らせ {notification.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-3">
            <Col md={3} xs={3}>
              お知らせID
            </Col>
            <Col>{notification.id}</Col>
          </Row>

          <Row className="mb-3">
            <Col md={3} xs={3}>
              作成日
            </Col>
            <Col>{util.formatJSONDate(notification.createdAt)}</Col>
          </Row>

          <Row>
            <Row>
              <Col md={3} xs={3}>
                本文
              </Col>
            </Row>
            <Row className="ms-2">{notification.contents.text}</Row>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseClick}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

NotificationDetailModal.defaultProps = {
  notification: undefined,
};

interface ParamTypes {
  id: string;
}

export default function ItemPage() {
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  const item = useSelector(selectSelectedItem);
  const itemCode = useSelector(selectSelectedItemCode);
  const itemStats = useSelector(selectSelectedItemStats);
  const itemNotifications = useSelector(selectSelectedItemNotifications);
  const packages = useSelector(selectPackages);

  const dispatch = useDispatch();

  const [selectedNotification, setSelectedNotification] = useState<ItemNotification | undefined>(undefined);
  const [deleteModalShowing, setDeleteModalShowing] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getSelectedItem(Number.parseInt(id, 10)));
  }, []);

  const onEditClicked = () => {
    history.push(`/items/${id}/edit`);
  };

  const onAddDataPackageClicked = () => {
    history.push(`/items/${id}/datapackages/add`);
  };

  const onAddArPackageClicked = () => {
    history.push(`/items/${id}/arpackages/add`);
  };

  const onViewersClicked = () => {
    history.push(`/items/${id}/viewers`);
  };

  const onSendNotificationClicked = () => {
    history.push(`/items/${id}/notification`);
  };

  const onDeleteClicked = () => {
    setDeleteModalShowing(true);
  };

  const onDeleteConfirmed = async () => {
    setDeleteModalShowing(false);
    if (item) {
      await dispatch(deleteItem(item.id));
      history.push('/items');
    }
  };

  const onDeleteCancelled = () => {
    setDeleteModalShowing(false);
  };

  const packagesView = packages.map((pkg) => (
    <tr key={pkg.id}>
      <td>{pkg.package_type === 'data' ? 'データ' : 'AR'}</td>
      <td>{pkg.title}</td>
      <td>{util.formatJSONDate(pkg.updatedAt)}</td>
      {(() => {
        if (pkg.package_type === 'data') {
          return (
            <td colSpan={2}>
              <Button size="sm" onClick={() => history.push(`/items/${id}/datapackages/${pkg.id}/edit`)}>
                開く
              </Button>
            </td>
          );
        }

        return [
          <td key="open">
            <Button size="sm" onClick={() => history.push(`/items/${id}/arpackages/${pkg.id}`)}>
              開く
            </Button>
          </td>,
          <td key="answer">
            <Button size="sm" onClick={() => history.push(`/items/${id}/arpackages/${pkg.id}/answers`)}>
              回答確認
            </Button>
          </td>,
        ];
      })()}
    </tr>
  ));

  if (!item) {
    return (
      <Page>
        <PageTitle value="読み込み中" />
        <PageBody loading> </PageBody>
      </Page>
    );
  }

  if (!itemCode) {
    throw new Error();
  }
  if (!itemStats) {
    throw new Error();
  }

  // notification table
  const notificationTableProps: SimpleTablePropTypes = {
    titles: {
      id: 'ID',
      text: 'コンテンツ',
      createdAt: '作成日',
      operation: '操作',
    },
    values: itemNotifications.map((n) => ({
      id: n.id.toString(),
      text: util.abbreviateString(n.contents.text, 50),
      createdAt: util.formatJSONDate(n.createdAt),
      operation: (
        <Button size="sm" variant="secondary" onClick={() => setSelectedNotification(n)}>
          開く
        </Button>
      ),
    })),
    styles: {
      operation: {
        width: '80px',
      },
    },
  };

  return (
    <Page>
      <NotificationDetailModal
        notification={selectedNotification}
        onCloseClick={() => setSelectedNotification(undefined)}
      />
      <DeleteModal
        show={deleteModalShowing}
        onDeleteClicked={onDeleteConfirmed}
        onCloseClicked={onDeleteCancelled}
        objectName="アイテム"
      />

      <PageTitle value={item.title} />
      <PageBody loading={!item}>
        <Card className="mb-4">
          <Card.Header>アイテム情報</Card.Header>
          <Card.Body>
            <Row className="mb-3" xs="auto">
              <Col>
                <Button onClick={onEditClicked}>アイテム編集</Button>
              </Col>
              <Col>
                <Button variant="danger" onClick={onDeleteClicked}>
                  アイテム削除
                </Button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                アイテム名
              </Col>
              <Col>{item.title}</Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                アイテムコード
              </Col>
              <Col>{itemCode.item_code}</Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                全体に公開
              </Col>
              <Col>{item.visible ? '公開' : '登録者のみ'}</Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                検索を許可
              </Col>
              <Col>{item.allow_name_search ? '許可' : '不許可'}</Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                有効期限
              </Col>
              <Col>{item.expires_at ? util.formatJSONDate(item.expires_at) : 'なし'}</Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Header>アイテム統計</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col md="3" lg="2">
                アイテム獲得人数
              </Col>
              <Col>{itemStats.subscribers.length}名</Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" lg="2">
                アイテム起動回数
              </Col>
              <Col>{itemStats.viewed_count}回</Col>
            </Row>
            <Form.Group className="mb-2">
              <Row>
                <Form.Label column md="3" lg="2">
                  閲覧者一覧
                </Form.Label>
                <Col>
                  <Button onClick={onViewersClicked}>表示</Button>
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Header>お知らせ一覧</Card.Header>
          <Card.Body>
            <Row className="mb-3" xs="auto">
              <Col>
                <Button onClick={onSendNotificationClicked}>お知らせ送信</Button>
              </Col>
            </Row>
            <SimpleTable {...notificationTableProps} />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>パッケージ一覧</Card.Header>
          <Card.Body>
            <Row className="mb-3" xs="auto">
              <Col>
                <Button onClick={onAddDataPackageClicked}>データパッケージ追加</Button>
              </Col>
              <Col>
                <Button onClick={onAddArPackageClicked}>ARパッケージ追加</Button>
              </Col>
            </Row>
            {packagesView.length === 0 ? (
              <p>パッケージが登録されていません</p>
            ) : (
              <Table responsive>
                <thead>
                  <tr>
                    <th>種別</th>
                    <th>名前</th>
                    <th>更新日時</th>
                    <th colSpan={2}>操作</th>
                  </tr>
                </thead>
                <tbody>{packagesView}</tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </PageBody>
    </Page>
  );
}
