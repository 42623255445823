import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { isValidStringId } from '../../../util';
import validationErrors from '../../../util/validationErrors';
import BOPage from '../../components/BOPage';
import BOPageBody from '../../components/BOPageBody';
import BOPageTitle from '../../components/BOPageTitle';
import { PostCompanyRequest } from '../bocommon/boApiClient';
import { loadProjects, selectProjects } from '../bocommon/bocommonSlice';
import { createCompany } from './bocompaniesSlice';

export default function BOCompanyAddPage() {
  // boilerplate
  const history = useHistory();
  const dispatch = useDispatch();

  // init
  useEffect(() => {
    dispatch(loadProjects());
  }, []);

  // callbacks
  const onBackClick = () => {
    history.push('/bo/companies');
  };

  // state
  const projects = useSelector(selectProjects);

  // formik
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      projectId: Yup.string().required(validationErrors.required()),
      groupType: Yup.string().required(validationErrors.required()),
      loginId: Yup.string()
        .required(validationErrors.required())
        .matches(isValidStringId, validationErrors.id()),
      groupName: Yup.string().required(validationErrors.required()),
    }),
    initialValues: {
      projectId: '',
      groupType: 'NORMAL',
      loginId: '',
      groupName: '',
    },
    onSubmit: async (value) => {
      const req: PostCompanyRequest = {
        projectId: value.projectId,
        groupType: value.groupType,
        loginId: value.loginId,
        groupName: value.groupName,
      };
      await dispatch(createCompany(req));

      history.push('/bo/companies/addcomplete');
    },
  });

  return (
    <BOPage>
      <BOPageTitle>企業グループ追加</BOPageTitle>
      <BOPageBody>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                プロジェクト名
              </Form.Label>
              <Col md={5}>
                <Form.Select
                  name="projectId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.projectId}
                  isValid={formik.touched.projectId && !formik.errors.projectId}
                  isInvalid={
                    formik.touched.projectId && !!formik.errors.projectId
                  }
                >
                  <option value="">-</option>
                  {projects.map((p) => (
                    <option id={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.projectId}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                企業タイプ
              </Form.Label>
              <Col md={5}>
                <Form.Select
                  name="groupType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.groupType}
                  isValid={formik.touched.groupType && !formik.errors.groupType}
                  isInvalid={
                    formik.touched.groupType && !!formik.errors.groupType
                  }
                >
                  <option value="NORMAL">通常</option>
                  <option value="CELEMONY">セレモニー</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.groupType}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                企業グループID
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="loginId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.loginId}
                  isValid={formik.touched.loginId && !formik.errors.loginId}
                  isInvalid={formik.touched.loginId && !!formik.errors.loginId}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.loginId}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row>
              <Form.Label column md={3} lg={2}>
                企業グループ名
              </Form.Label>
              <Col md={5}>
                <Form.Control
                  type="text"
                  name="groupName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.groupName}
                  isValid={formik.touched.groupName && !formik.errors.groupName}
                  isInvalid={
                    formik.touched.groupName && !!formik.errors.groupName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.groupName}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <Button type="submit" variant="primary" className="me-2">
            追加
          </Button>
          <Button type="button" variant="secondary" onClick={onBackClick}>
            キャンセル
          </Button>
        </Form>
      </BOPageBody>
    </BOPage>
  );
}
